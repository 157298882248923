import React, { useEffect } from "react";
// import logo from "../../assets/images/logo/wheelsmart_logo_color.svg";
import logo from "../../assets/images/logo/wheelsmart_logo_color-2.svg";

import { Card, Row, Col } from "antd";
import { useHistory } from "react-router";

export default function Spalsh(props) {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("access_token")) {
        history.push("/dashboard");
      } else {
        history.push("/login");
      }
    }, 1000);
    // console.log("localStorage.getItem", localStorage.getItem("access_token"));
    // if(localStorage.getItem("access_token")){
    //     history.push("/dashboard");
    //   }
    //   else{
    //       history.push("/login");
    //   }
  }, []);

  return (
    <Row className=" hp-text-center">
      <Col className="hp-error-content hp-py-32" span={24}>
        <Row className="hp-h-100" align="middle" justify="center">
          <Col>
            <img
              className="hp-position-relative "
              src={logo}
              style={{
                height: 100,
                width: 500,
              }}
              alt="logo"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
