import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  apiError,
  getMechanics,
  mechanicsListSuccessful,
  mechanicByIDSuccessful,
  isModelVisible,
} from "./mechanicsSlice";
import { Modal } from "antd";
// AUTH related methods
import {
  postAdd,
  getParamsList,
  getList,
  postEdit,
  postDelete,
} from "../../../helpers/Helper";
import Swal from "sweetalert2";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* MechanicsGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/mechanic/", params);
    yield put(mechanicsListSuccessful({response}));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MechanicByID({ payload }) {
  console.log("MechanicByID", payload);
  try {
    const response = yield call(
      getParamsList,
      "/masters/mechanic/" + payload.mechanic.id,
      {}
    );
    yield put(mechanicByIDSuccessful({ response, type: payload.type }));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* AddMechanics({ payload }) {
  try {
    if (payload.obj.id == 0) {
      const response = yield call(postAdd, "/masters/mechanic/", payload.obj);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Guarage Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getMechanics({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Guarage. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        "/masters/mechanic/" + payload.obj.id,
        payload.obj
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Guarage Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getMechanics({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Guarage. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* DeleteMechanic({ payload }) {
  var mechanicId = payload;
  try {
    const response = yield call(postDelete, "/masters/mechanic/" + mechanicId);
    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Guarage has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getMechanics({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Guarage has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

export function* watchgetMechanics() {
  yield takeEvery("mechanics/getMechanics", MechanicsGet);
}
export function* watchMechanicsAdd() {
  yield takeEvery("mechanics/mechanicAdd", AddMechanics);
}
export function* watchMechanicsDelete() {
  yield takeEvery("mechanics/mechanicDelete", DeleteMechanic);
}
export function* watchGetMechanicByID() {
  yield takeEvery("mechanics/getMechanicByID", MechanicByID);
}
function* MechanicsSaga() {
  yield all([
    fork(watchgetMechanics),
    fork(watchMechanicsAdd),
    fork(watchMechanicsDelete),
    fork(watchGetMechanicByID),
  ]);
}

export default MechanicsSaga;
