import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const stockSlice = createSlice({
  name: "stock",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    model: false,
    drawer: false,
    apiError: {},
    stockData: {},
    listCount: 0,
    saleListCount: 0,
    activeTab: "1",
    screen: { Type: "none", id: 0 },
    stockList: [],
    stockImages: [],
    stockParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    stockRequestData: {},
    counterCluster: {},
  },
  reducers: {
    clearData: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    getStockList: (state, action) => {
      if (state.stockParams?.page == 1) {
        return {
          ...state,
          loading: true,
          stockList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    stockListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.stockParams.page_size
      );
      console.log(action.payload);
      return {
        ...state,
        listCount: action.payload.response.count,
        // stockList: [...state.stockList, ...action.payload.response.results],
        stockList: action.payload.response.results,
        loading: false,
        stockParams: {
          ...state.stockParams,
          no_of_pages: noofpages,
        },
      };
    },
    clearStockList: (state, action) => {
      return {
        ...state,
        loading: true,
        stockList: [],
      };
    },
    setStockParams: (state, action) => {
      return {
        ...state,
        stockParams: action.payload,
      };
    },

    getStockById: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    stockByIDSuccessful: (state, action) => {
      var oldImgs = [
        {
          ...action.payload.vehicleback_image,
          imageType: "Vehicle Back Image",
        },
        {
          ...action.payload.vehiclefront_image,
          imageType: "Vehicle Front Image",
        },
        {
          ...action.payload.vehicleleft_image,
          imageType: "Vehicle Left Image",
        },
        {
          ...action.payload.vehicleright_image,
          imageType: "Vehicle Right Image",
        },
        { ...action.payload.rcfront_image, imageType: "RC Front Image" },
        { ...action.payload.rcback_image, imageType: "RC Back Image" },
      ];

      var NewImg = [
        {
          ...action.payload.vehicleback_image_latest,
          imageType: "Latest Vehicle Back Image",
        },
        {
          ...action.payload.vehiclefront_image_latest,
          imageType: "Latest Vehicle Front Image",
        },
        {
          ...action.payload.vehicleleft_image_latest,
          imageType: "Latest Vehicle Left Image",
        },
        {
          ...action.payload.vehicleright_image_latest,
          imageType: "Latest Vehicle Right Image",
        },
        { ...action.payload.rcfront_image, imageType: "RC Front Image" },
        { ...action.payload.rcback_image, imageType: "RC Back Image" },
      ]
      return {
        ...state,
        stockData: action.payload,
        stockImages:  action.payload.vehiclefront_image_latest ? NewImg  : oldImgs,
      };
    },
    InputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "counter__zone":
          return {
            ...state,
            stockParams: {
              ...state.stockParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter__division: null,
              counter: null,
            },
          };

        case "counter__division":
          return {
            ...state,
            stockParams: {
              ...state.stockParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter: null,
            },
          };

        case "counter__cluster":
          return {
            ...state,
            stockParams: {
              ...state.stockParams,
              [action.payload.key]: action.payload.value,
              counter: null,
            },
          };
        case "vehicle__version__brand":
          return {
            ...state,
            stockParams: {
              ...state.stockParams,
              [action.payload.key]: action.payload.value,
              vehicle__version__brandfamily: null,
              vehicle__version__model__name: null,
              vehicle__version: null,
            },
          };
        case "vehicle__version__brandfamily":
          return {
            ...state,
            stockParams: {
              ...state.stockParams,
              [action.payload.key]: action.payload.value,
              vehicle__version__model__name: null,
              vehicle__version: null,
            },
          };
        case "vehicle__version__model__name":
          return {
            ...state,
            stockParams: {
              ...state.stockParams,
              [action.payload.key]: action.payload.value,
              vehicle__version: null,
            },
          };
        case "stock_value_min":
          return {
            ...state,
            stockParams: {
              ...state.stockParams,
              [action.payload.key]: action.payload.value,
              stock_value_max: null,
            },
          };
        default:
          return {
            ...state,
            stockParams: {
              ...state.stockParams,
              [action.payload.key]: action.payload.value,
            },
          };
      }
      // return {
      //   ...state,
      //   stockParams: {
      //     ...state.stockParams,
      //     [action.payload.key]: action.payload.value,
      //   },
      // };
    },
    RequestInputChangeValue: (state, action) => {
      return {
        ...state,
        stockRequestData: {
          ...state.stockRequestData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          stockRequestData: {},
          counterCluster: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    stockRequestAdd: (state, action) => {
      return{
        ...state,
        loading: true,
      }
    },
    getCounterCluster: (state, action) => {
      return {
        ...state,
        loading: true,
        counterCluster: action.payload,
      }
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getStockList,
  stockListSuccessful,
  setStockParams,
  InputChangeValue,
  clearData,
  getStockById,
  stockByIDSuccessful,
  clearStockList,
  isModelVisible,
  RequestInputChangeValue,
  stockRequestAdd,
  getCounterCluster,
  apiError,
} = stockSlice.actions;

export default stockSlice.reducer;
