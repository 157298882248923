import { createSlice, current } from "@reduxjs/toolkit";
import moment from "moment";

export const manageuserSlice = createSlice({
  name: "manageUser",
  initialState: {
    loginListCount: 0,
    deviceListCount: 0,
    activityListCount: 0,
    listCount: 0,
    Userslist: [],
    drawer: false,
    employeeUserdata: {
      attendencetype: 1,
    },
    model: false,
    showPassword: true,
    activeTab: "1",
    usersParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    UserDeviceParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    profileData: {},
    userMiniZonesList: [],
    userSelectedZonesList: [],
    userSelectedDivisionList: [],
    userSelectedClusterList: [],
    userSelectedCounterList: [],
    userMiniDivisionsList: [],
    selectedZoneID: 0,
    selectedDivisionID: 0,
    selectedClusterID: 0,
    passwordModel: false,
    userId: 0,
    adminUserLoginList: [],
    adminUserDeviceList: [],
    adminUserActivityList: [],
    // usersFilterParams: {
    //   no_of_pages: 0,
    //   page_size: 10,
    //   page: 1,
    //   search: "",
    //   currentSort: "code",
    //   sortOrder: "-",
    // },
    imageLooading: false,
    previewFile: {},
  },

  reducers: {
    getUsers: (state, action) => {
      if (state.usersParams?.page == 1) {
        return {
          ...state,
          loading: true,
          Userslist: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    usersListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.usersParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.usersParams?.page_size
      );
      return {
        ...state,
        Userslist: list,
        listCount: action.payload.count,
        loading: false,
        usersParams: {
          ...state.usersParams,
          no_of_pages: noofpages,
        },
      };
    },
    setUsersParams: (state, action) => {
      return {
        ...state,
        usersParams: action.payload,
      };
    },
    getUserMiniZonesList: (state) => {
      return {
        ...state,
        userMiniZonesList: [],
        loading: true,
      };
    },
    userMiniZonesListSuccessful: (state, action) => {
      return {
        ...state,
        userMiniZonesList: action.payload,
        loading: false,
      };
    },
    showDivisions: (state, action) => {
      return {
        ...state,
        selectedZoneID: action.payload,
      };
    },
    getUserMiniDivisionsList: (state, action) => {
      return {
        ...state,
        userMiniDivisionsList: [],
        loading: true,
      };
    },
    userMiniDivisionsListSuccessful: (state, action) => {
      state.userMiniZonesList.map((zone) => {
        if (zone.id == action.payload.zoneID) {
          zone.divisions = action.payload.divisionsList;
        }
      });
    },
    showClusters: (state, action) => {
      return {
        ...state,
        selectedDivisionID: action.payload,
      };
    },
    getUserMiniClustersList: (state, action) => {
      return {
        ...state,
        userMiniClustersList: [],
        loading: true,
      };
    },
    userMiniClustersListSuccessful: (state, action) => {
      state.userMiniZonesList.map((zone) => {
        if (zone.id == action.payload.zoneID) {
          zone.divisions.map((division) => {
            if (division.id == action.payload.divisionID) {
              division.clusters = action.payload.clusterList;
            }
          });
        }
      });
    },
    showCounters: (state, action) => {
      return {
        ...state,
        selectedClusterID: action.payload,
      };
    },
    getUserMiniCountersList: (state, action) => {
      return {
        ...state,
        userMiniCountersList: [],
        loading: true,
      };
    },
    userMiniCountersListSuccessful: (state, action) => {
      state.userMiniZonesList.map((zone) => {
        if (zone.id == action.payload.zoneID) {
          zone.divisions.map((division) => {
            if (division.id == action.payload.divisionID) {
              division.clusters.map((cluster) => {
                if (cluster.id == action.payload.clusterID) {
                  cluster.counters = action.payload.counterList;
                }
              });
            }
          });
        }
      });
    },
    checkZonePermission: (state, action) => {
      state.userMiniZonesList.map((zone) => {
        if (zone.id == action.payload.zonePermission_id) {
          zone.zone_checked = !zone.zone_checked;
          if (zone.zone_checked == true) {
            // zone.divisions.map((division) => {
            //   division.clusters?.map((cluster) => {
            //     cluster.counters?.map((counter) => {
            //       counter.counter_checked = true;
            //     });
            //     cluster.cluster_checked = true;
            //   });
            //   division.division_checked = true;
            // });
          } else {
            zone.divisions.map((division) => {
              division.clusters?.map((cluster) => {
                cluster.counters?.map((counter) => {
                  counter.counter_checked = false;
                });
                cluster.cluster_checked = false;
              });
              division.division_checked = false;
            });
          }
        }
      });
    },
    checkDivisionPermission: (state, action) => {
      state.userMiniZonesList.map((zone) => {
        if (zone.id == action.payload.zoneId) {
          zone.divisions.map((division) => {
            if (division.id == action.payload.divisionPermission_id) {
              division.division_checked = !division.division_checked;
              if (division.division_checked != true) {
                division.clusters?.map((cluster) => {
                  cluster.counters?.map((counter) => {
                    counter.counter_checked = false;
                  });

                  cluster.cluster_checked = false;
                });
              }
            }
          });
        }
      });
    },
    checkClusterPermission: (state, action) => {
      state.userMiniZonesList.map((zone) => {
        if (zone.id == action.payload.zoneId) {
          zone.divisions.map((division) => {
            if (
              division.id == action.payload.divisionId &&
              division.clusters !== null
            ) {
              division.clusters.map((cluster) => {
                if (cluster.id == action.payload.clusterPermission_id) {
                  cluster.cluster_checked = !cluster.cluster_checked;
                  if (cluster.cluster_checked != true) {
                    cluster.counters.map((counter) => {
                      counter.counter_checked = false;
                    });
                  }
                }
              });
            }
          });
        }
      });
    },
    checkCounterPermission: (state, action) => {
      state.userMiniZonesList.map((zone) => {
        if (zone.id == action.payload.zoneId) {
          zone.divisions.map((division) => {
            if (
              division.id == action.payload.divisionId &&
              division.clusters !== null
            ) {
              division.clusters.map((cluster) => {
                if (
                  cluster.id == action.payload.clusterId &&
                  cluster.counters !== null
                ) {
                  cluster.counters.map((counter) => {
                    if (counter.id == action.payload.counterPermission_id) {
                      counter.counter_checked = !counter.counter_checked;
                    }
                  });
                }
              });
            }
          });
        }
      });
    },
    userAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    clearProfileData: (state, action) => {
      return {
        ...state,
        loading: false,
        employeeUserdata: {
          attendencetype: 1,
        },
        profileData: {},
        userMiniZonesList: [],
        userSelectedZonesList: [],
        userSelectedDivisionList: [],
        userSelectedClusterList: [],
        userSelectedCounterList: [],
        selectedZoneID: 0,
        selectedDivisionID: 0,
        selectedClusterID: 0,
        userMiniDivisionsList: [],
        userMiniClustersList: [],
        // usersParams: {
        //   no_of_pages: 0,
        //   page_size: 10,
        //   page: 1,
        //   search: "",
        //   currentSort: "code",
        //   sortOrder: "-",
        // },
      };
    },
    profileDataById: (state, action) => {
      return {
        ...state,
        loading: true,
        profileData: action.payload,
      };
    },
    getEmployeeUserbyID: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    employeeUserbyIDSuccessful: (state, action) => {
      var DepartmentIds = [];
      DepartmentIds = action.payload.departments.map((dept) => {
        return dept.id;
      });
      var zonesIds = [];
      zonesIds = action.payload?.user.zones.map((zone) => {
        return zone.id;
      });
      var divisionsIds = [];
      divisionsIds = action.payload?.user.divisions.map((division) => {
        return division.id;
      });
      var clustersIds = [];
      clustersIds = action.payload?.user.clusters.map((cluster) => {
        return cluster.id;
      });
      var countersIds = [];
      countersIds = action.payload?.user.counters.map((counter) => {
        return counter.id;
      });

      return {
        ...state,
        loading: true,
        employeeUserdata: {
          ...action.payload,
          id: action.payload?.id,
          username: action.payload?.user.username,
          email: action.payload?.user.email,
          phone: action.payload?.user.phone,
          group_ids:
            action.payload.user.groups.length != 0
              ? action.payload.user.groups[0].id
              : "",
          first_name: action.payload?.user.first_name,
          last_name: action.payload?.user.last_name,
          state_id: action.payload?.user.state?.id,
          district_id: action.payload?.user.district?.id,
          city_id: action.payload?.user.city?.id,
          area_id: action.payload?.user.area?.id,
          address: action.payload?.user.address,
          pincode: action.payload?.user.pincode,
          department_ids: DepartmentIds,
          firm_id: action.payload?.firm.id,
          dob: moment(action.payload?.dob),
          mcapplicable: action.payload?.mcapplicable,
          webaccess: action.payload?.user.deviceaccess == 3 ? true : false,
          attendencetype: action.payload.user.attendencetype,
          reporting_to: {
            id: action.payload.user.reporting_to?.id,
            name:
              action.payload.user.reporting_to?.username +
              " - " +
              action.payload.user.reporting_to?.fullname +
              " - " +
              action.payload.user.reporting_to?.phone,
          },
          profilepicture: action.payload.user?.profilepicture,
          previewImg: action.payload.user?.profilepicture,
        },
        userSelectedZonesList: zonesIds,
        userSelectedDivisionList: divisionsIds,
        userSelectedClusterList: clustersIds,
        userSelectedCounterList: countersIds,
        showPassword:
          action.payload?.id != 0 && action.payload?.user.deviceaccess == 3
            ? false
            : true,
      };
    },

    InputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "state_id":
          return {
            ...state,
            employeeUserdata: {
              ...state.employeeUserdata,
              district_id: "",
              city_id: "",
              area_id: "",
              [action.payload.key]: action.payload.value,
            },
          };
          break;
        case "district_id":
          return {
            ...state,
            employeeUserdata: {
              ...state.employeeUserdata,
              city_id: "",
              area_id: "",
              [action.payload.key]: action.payload.value,
            },
          };
          break;
        case "city_id":
          return {
            ...state,
            employeeUserdata: {
              ...state.employeeUserdata,
              area_id: "",
              [action.payload.key]: action.payload.value,
            },
          };
          break;
        case "webaccess":
          return {
            ...state,
            employeeUserdata: {
              ...state.employeeUserdata,
              [action.payload.key]: action.payload.value,
            },
            showPassword: true,
          };
          break;
        default:
          return {
            ...state,
            employeeUserdata: {
              ...state.employeeUserdata,
              [action.payload.key]: action.payload.value,
            },
          };
          break;
      }
    },
    SelecteChangeValue: (state, action) => {
      return {
        ...state,
        employeeUserdata: {
          ...state.employeeUserdata,
          [action.payload.key]: action.payload.label,
          [action.payload.key2]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          employeeUserdata: {
            attendencetype: 1,
          },
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
   
    employeeUserDelete: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    userMobileValidation: (state) => {
      return {
        ...state,
      };
    },
    userEmailValidation: (state) => {
      return {
        ...state,
      };
    },
    userNameValidation: (state) => {
      return {
        ...state,
      };
    },
    userActive: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    userInActive: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getUserID: (state, action) => {
      return {
        ...state,
        loading: false,
        userId: action.payload,
      };
    },
    isPasswordModel: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          passwordModel: action.payload,
          employeeUserdata: {
            attendencetype: 1,
          },
        };
      } else {
        return {
          ...state,
          passwordModel: action.payload,
        };
      }
    },
    updatePassword: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getAdminUserLogin: (state) => {
      return {
        ...state,
        loading: true,
        adminUserLoginList: [],
      };
    },
    adminUserLoginSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.UserDeviceParams.page_size
      );

      return {
        ...state,
        loginListCount: action.payload.response.count,
        // adminUserLoginList: action.payload.response.results,
        // adminUserActivityLogList: action.payload.response.results,
        adminUserLoginList: [
          ...state.adminUserLoginList,
          ...action.payload.response.results,
        ],
        loading: false,
        UserDeviceParams: {
          ...state.UserDeviceParams,
          no_of_pages: noofpages,
        },
      };
    },

    getAdminUserDeviceList: (state) => {
      return {
        ...state,
        loading: true,
        adminUserDeviceList: [],
      };
    },
    adminUserDeviceListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.UserDeviceParams.page_size
      );

      return {
        ...state,
        deviceListCount: action.payload.response.count,
        // adminUserDeviceList: action.payload.response.results,
        adminUserDeviceList: [
          ...state.adminUserDeviceList,
          ...action.payload.response.results,
        ],
        loading: false,
        UserDeviceParams: {
          ...state.UserDeviceParams,
          no_of_pages: noofpages,
        },
      };
    },

    getAdminUserActivityList: (state) => {
      return {
        ...state,
        loading: true,
        adminUserActivityList: [],
      };
    },
    adminUserActivityListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.UserDeviceParams.page_size
      );

      return {
        ...state,
        activityListCount: action.payload.response.count,
        adminUserActivityList: [
          ...state.adminUserActivityList,
          ...action.payload.response.results,
        ],
        loading: false,
        UserDeviceParams: {
          ...state.UserDeviceParams,
          no_of_pages: noofpages,
        },
      };
    },
    setUsersDeviceParams: (state, action) => {
      return {
        ...state,
        UserDeviceParams: action.payload,
      };
    },
    userDeviceActiveTab: (state, action) => {
      return {
        ...state,
        loading: true,
        activeTab: action.payload,
      };
    },
    clearAdminUserLogin: (state) => {
      return {
        ...state,
        loading: true,
        adminUserLoginList: [],
      };
    },
    clearAdminUserDeviceList: (state) => {
      return {
        ...state,
        loading: true,
        adminUserDeviceList: [],
      };
    },
    clearAdminUserActivityList: (state) => {
      return {
        ...state,
        loading: true,
        adminUserActivityList: [],
      };
    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        usersParams: {
          ...state.usersParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    // setUsersFilterParams: (state, action) => {
    //   return {
    //     ...state,
    //     usersFilterParams: action.payload,
    //   };
    // },
    SelectChangeValue: (state, action) => {
      return {
        ...state,
        employeeUserdata: {
          ...state.employeeUserdata,
          [action.payload.key]: {
            id: action.payload.value,
            name: action.payload.name,
          },
        },
      };
    },

    userImgUpload: (state, action) => {
      return {
        ...state,
        imageLooading: false,
        employeeUserdata: {
          ...state.employeeUserdata,
          [action.payload.imageType]: action.payload.file,
        },
      };
    },
    setPreviewImg: (state, action) => {
        return{
          ...state,
          loading: false,
          employeeUserdata: {
            ...state.employeeUserdata,
            previewFile: action.payload,
          }
        }
    },

    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getUsers,
  usersListSuccessful,
  getUserMiniZonesList,
  userMiniZonesListSuccessful,
  getUserMiniDivisionsList,
  userMiniDivisionsListSuccessful,
  getUserMiniClustersList,
  userMiniClustersListSuccessful,
  getUserMiniCountersList,
  userMiniCountersListSuccessful,
  showDivisions,
  showClusters,
  showCounters,
  checkZonePermission,
  checkDivisionPermission,
  checkClusterPermission,
  checkCounterPermission,
  userAdd,
  clearProfileData,
  profileDataById,
  getEmployeeUserbyID,
  employeeUserbyIDSuccessful,
  InputChangeValue,
  SelecteChangeValue,
  isModelVisible,
  setUsersParams,
  employeeUserDelete,
  userMobileValidation,
  userEmailValidation,
  userNameValidation,
  userActive,
  userInActive,
  isPasswordModel,
  getUserID,
  updatePassword,
  getAdminUserLogin,
  adminUserLoginSuccessful,
  getAdminUserDeviceList,
  adminUserDeviceListSuccessful,
  setUsersDeviceParams,
  // clearExpanddata,
  userDeviceActiveTab,
  getAdminUserActivityList,
  adminUserActivityListSuccessful,
  clearAdminUserLogin,
  clearAdminUserDeviceList,
  clearAdminUserActivityList,
  isDrawerVisible,
  FilterInputChangeValue,
  SelectChangeValue,
  // setUsersFilterParams,
  setPreviewImg,
  userImgUpload,
  apiError,
} = manageuserSlice.actions;

export default manageuserSlice.reducer;
