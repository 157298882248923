import { createSlice } from "@reduxjs/toolkit";

export const areaSlice = createSlice({
  name: "area",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    areaslist: [],
    model: false,
    drawer: false,
    error_msg: null,
    rowdata: {},
    areaParams: {
      no_of_pages: 0,
      listCount: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getAreas: (state) => {
      if (state.areaParams.page == 1) {
        return {
          ...state,
          loading: true,
          areaslist: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    areaListSuccessful: (state, action) => {
      var noofpages = Math.ceil(action.payload.count / state.areaParams.page_size);
      return {
        ...state,
        listCount: action.payload.count,
        areaslist: action.payload.results,
        loading: false,
        areaParams: {
          ...state.areaParams,
          no_of_pages: noofpages,
        },
      };
    },
    areaAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    areaAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    areaEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: {
          id: action.payload.id,
          name: action.payload.name,
          pincode: action.payload.pincode,
          country_id: action.payload.country.id,
          state_id: action.payload.state.id,
          district_id: action.payload.district.id,
          city_id: action.payload.city.id,
        },
      };
    },
    areaEditSuccessful: (state) => {
      return {
        ...state,
        loading: false,
        rowdata: {},
      };
    },
    areaDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetAreaParams: (state, action) => {
      return {
        ...state,
        areaParams: action.payload,
      };
    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
          // enquiryData: {},
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      if (action.payload.key == "country") {
        return {
          ...state,
          areaParams: {
            ...state.areaParams,
            [action.payload.key]: action.payload.value,
            state: "",
            district: "",
            city: "",
          },
        };
      } else if (action.payload.key == "state") {
        return {
          ...state,
          areaParams: {
            ...state.areaParams,
            [action.payload.key]: action.payload.value,
            district: "",
            city: "",
          },
        };
      } else if (action.payload.key == "district") {
        return {
          ...state,
          areaParams: {
            ...state.areaParams,
            [action.payload.key]: action.payload.value,
            city: "",
          },
        };
      } else {
        return {
          ...state,
          areaParams: {
            ...state.areaParams,
            [action.payload.key]: action.payload.value,
          },
        };
      }
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getAreas,
  areaListSuccessful,
  areaAdd,
  areaAddSuccessful,
  areaEdit,
  areaEditSuccessful,
  areaDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetAreaParams,
  FilterInputChangeValue,
  isDrawerVisible
} = areaSlice.actions;

export default areaSlice.reducer;
