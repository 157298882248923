import { createSlice } from "@reduxjs/toolkit";

export const emidatesSlice = createSlice({
  name: "emidates",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    emidateslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    emidatesParams: {
      no_of_pages: 0,
      listCount: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getEmidates: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    emidateListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.emidatesParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        emidateslist: action.payload.response.results,
        loading: false,
        emidatesParams: {
          ...state.emidatesParams,
          no_of_pages: noofpages,
        },
      };
    },
    emidateAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    emidateAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    emidateEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: action.payload,
      };
    },
    emidateEditSuccessful: (state) => {
      return { ...state, loading: false, rowdata: {} };
    },
    emidateDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetEmidatesParams: (state, action) => {
      return {
        ...state,
        emidatesParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getEmidates,
  emidateListSuccessful,
  emidateAdd,
  emidateAddSuccessful,
  emidateEdit,
  emidateEditSuccessful,
  emidateDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetEmidatesParams
} = emidatesSlice.actions;

export default emidatesSlice.reducer;
