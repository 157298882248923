import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { Menu } from "antd";
import * as Icons from "@ant-design/icons";
import { getSideMenuItems } from "../../../../store/system/systemSlice";

const { SubMenu } = Menu;

function MenuItem(props) {
  const { onClose } = props;
  useEffect(() => {
    props.getSideMenuItems();
  }, []);

  console.log("sidemenuprops :", props.sideMenuItemsList);

  // Redux
  const customise = useSelector((state) => state.customise);

  // Location
  const location = useLocation();
  const { pathname } = location;

  const splitLocation = pathname.split("/");

  // Menu
  const splitLocationUrl =
    splitLocation[splitLocation.length - 2] +
    "/" +
    splitLocation[splitLocation.length - 1];

  const subMenuItem = props.sideMenuItemsList?.map((item, index) => {
    if (item.type == "SUBMENU") {
      if (item.header) {
        return <Menu.ItemGroup key={index} title={item.title}></Menu.ItemGroup>;
      }

      if (item.menuitems.length != 0) {
        let Icon = Icons[item.icon];
        return (
          <SubMenu key={item.id} icon={<Icon />} title={item.name}>
            {item.menuitems.map((childrens, index) => {
              if (!childrens.children) {
                const childrenNavLink = childrens.link.split("/");
                let Icon = Icons[childrens.icon];
                return (
                  // Level 2
                  <Menu.Item
                    key={childrens.id}
                    icon={<Icon />}
                    className={
                      splitLocationUrl ===
                      childrenNavLink[childrenNavLink.length - 2] +
                        "/" +
                        childrenNavLink[childrenNavLink.length - 1]
                        ? "ant-menu-item-selected"
                        : "ant-menu-item-selected-in-active"
                    }
                    onClick={onClose}>
                    <Link to={childrens.link}>{childrens.title}</Link>
                  </Menu.Item>
                );
              } else {
                return (
                  // Level 3
                  <SubMenu key={childrens.id} title={childrens.title}>
                    {childrens.children.map((childItem, index) => {
                      const childrenItemLink = childItem.navLink.split("/");
                      return (
                        <Menu.Item
                          key={childItem.id}
                          className={
                            splitLocationUrl ===
                            childrenItemLink[childrenItemLink.length - 2] +
                              "/" +
                              childrenItemLink[childrenItemLink.length - 1]
                              ? "ant-menu-item-selected"
                              : "ant-menu-item-selected-in-active"
                          }
                          onClick={onClose}>
                          <Link to={childItem.link}>{childItem.title}</Link>
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                );
              }
            })}
          </SubMenu>
        );
      }
    } else {
      const itemNavLink = item.link.split("/");
      let Icon = Icons[item.icon];
      return (
        // Level 1
        <Menu.Item
          key={item.id}
          icon={<Icon />}
          onClick={onClose}
          className={
            splitLocation[splitLocation.length - 2] +
              "/" +
              splitLocation[splitLocation.length - 1] ===
            itemNavLink[itemNavLink.length - 2] +
              "/" +
              itemNavLink[itemNavLink.length - 1]
              ? "ant-menu-item-selected"
              : "ant-menu-item-selected-in-active"
          }>
          <Link to={item.link}>{item.title}</Link>
        </Menu.Item>
      );
    }
  });
  // const menuItem = props.sideMenuItemsList.menuitems?.map((item, index) => {

  // });

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={[
        splitLocation.length === 5
          ? splitLocation[splitLocation.length - 3]
          : null,
        splitLocation[splitLocation.length - 2],
      ]}
      theme={customise.theme == "light" ? "light" : "dark"}>
      {subMenuItem}
      {/* {menuItem} */}
    </Menu>
  );
}
function mapStateToProps({ system }) {
  return {
    sideMenuItemsList: system.sideMenuItemsList,
  };
}

export default connect(mapStateToProps, {
  getSideMenuItems,
})(MenuItem);
