import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const vehicleLogSlice = createSlice({
  name: "vehicleLog",
  initialState: {
    loginError: "aaa",
    drawer: false,
    loading: false,
    model: false,
    apiError: {},
    listCount: 0,
    vehicleLogData: {},
    vehicleLogList: [],
    vehicleLogParams:{
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    }
  },
  reducers: {
    clearData: (state) => {
      return {
        ...state,
        loading: false,
        vehicleLogData: {},
        vehicleLogList: [],
      };
    },
    getVehicleLog: (state, action) => {
      if (state.vehicleLogParams?.page == 1) {
        return {
          ...state,
          loading: true,
          vehicleLogList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    vehicleLogSuccessful: (state, action) => {
      console.log("vehicleLogSuccessful", action.payload);
      var response = action.payload;
      var list = [];
      if (state.vehicleLogParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.vehicleLogParams?.page_size
      );
      return {
        ...state,
        vehicleLogList: list,
        listCount: action.payload.count,
        loading: false,
        vehicleLogParams: {
          ...state.vehicleLogParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetVehicleLogParams: (state, action) => {
      return {
        ...state,
        vehicleLogParams: action.payload,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        vehicleLogData: {
          ...state.vehicleLogData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getVehicleLog,
  vehicleLogSuccessful,
  SetVehicleLogParams,
  InputChangeValue,
  clearData,
  apiError,
} = vehicleLogSlice.actions;

export default vehicleLogSlice.reducer;
