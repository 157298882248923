import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  expensesListSuccessful,
  paymentImageUploadSuccessful,
  dailyPaymentListSuccessful,
  getDailyPaymentList,
  dailyPaymentByIdSuccessful,
  getDailyPaymentById,
  isRejectModelVisible
} from "./dailyPaymentSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";


// get Expenses List
function* ExpensesList({ payload }) {
  var params = {};
  var data = payload.params;
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "type") {
        params.type = data.type.join(",");
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/dailypaymenthead/", params);
    yield put(expensesListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* DailyPaymentAdd({ payload }) {
  try {
    if(payload.obj.id == 0){
      const response = yield call(postAdd,"/accounts/dailypayment/",payload.obj);
      if (response.status === 200 || response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Daily Payment Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getDailyPaymentList({page: 1, search: "", page_size: 10}));
        payload.history.push("/pages/dailyPayments");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to add Daily Payment. Please try again!
              </p>
            </div>
          ),
        });
      }
    }else{
      const response = yield call(
        postEdit,
        "/accounts/dailypayment/" + payload.obj.id,
        payload.obj
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Daily Payment Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getDailyPaymentList({page: 1, search: "", page_size: 10}));
        payload.history.push("/pages/dailyPayments");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Daily Payment. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
    
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}


function* PaymentImageUpload({ payload }) {
  try {
    const response = yield call(postFormData, "/system/attachment/", {
      file: payload.value,
    });
    yield put(
      paymentImageUploadSuccessful({
        response,
        uid: payload.value.uid,
        item: payload.item,
        ImageType: payload.value.imageType,
        name: payload.value.name,
        file: response.data.file,
        id: response.data.id
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}


function* DailyPaymentList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/accounts/dailypayment/", params);
    yield put(dailyPaymentListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* DailyPaymentById({ payload }) {
  try {
    const response = yield call(
      getParamsList,
      "/accounts/dailypayment/" + payload,
      {}
    );
    yield put(dailyPaymentByIdSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* PaymentApprove({ payload }) {
  try {
    const response = yield call(postEdit, "/accounts/dailypaymentapprove/" + payload.id, payload);
    if (response.status === 200) {
      if (payload.dailypaymentapprovalstatus == 2 && payload.dailypaymentapprovalstatus == 4) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your daily payment Level one has been approved successfully.
              </p>
            </div>
          ),
        });
        yield put(getDailyPaymentById(payload.id));
      } else if (payload.dailypaymentapprovalstatus == 3 && payload.dailypaymentapprovalstatus == 5) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your daily payment Level Two has been Rejected successfully.
              </p>
            </div>
          ),
        });
      }
      yield put(isRejectModelVisible(false))
      yield put(getDailyPaymentById(payload.id));
    } else {
      if (payload.dailypaymentapprovalstatus == 2 && payload.dailypaymentapprovalstatus == 4) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to Approve level daily payment . Please try again!
              </p>
            </div>
          ),
        });
      } else if (payload.dailypaymentapprovalstatus == 3 && payload.dailypaymentapprovalstatus == 5) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to Reject daily payment . Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

export function* watchGetExpensesList() {
  yield takeEvery("dailyPayment/getExpensesList", ExpensesList);
}
export function* watchAddDailyPayment() {
  yield takeEvery("dailyPayment/addDailyPayment", DailyPaymentAdd);
}
export function* watchGetPaymentImageUpload() {
  yield takeEvery("dailyPayment/getPaymentImageUpload", PaymentImageUpload);
}
export function* watchGetDailyPaymentList() {
  yield takeEvery("dailyPayment/getDailyPaymentList", DailyPaymentList);
}
export function* watchGetDailyPaymentById() {
  yield takeEvery("dailyPayment/getDailyPaymentById", DailyPaymentById);
}
export function* watchApprovePayment() {
  yield takeEvery("dailyPayment/approvePayment", PaymentApprove);
}
function* dailyPaymentSaga() {
  yield all([
    fork(watchGetExpensesList),
    fork(watchAddDailyPayment),
    fork(watchGetPaymentImageUpload),
    fork(watchGetDailyPaymentList),
    fork(watchGetDailyPaymentById),
    fork(watchApprovePayment),
  ]);
}

export default dailyPaymentSaga;
