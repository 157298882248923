import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const stockRequestSlice = createSlice({
  name: "stockRequest",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    model: false,
    stockRepairDrawer: false,
    stockTempraroryDrawer: false,
    stockApprovalDrawer: false,
    stockRequestDrawer: false,
    apiError: {},
    listCount: 0,
    stockRequestlistCount: 0,
    stockRepairlistCount: 0,
    stockTemprarorylistCount: 0,
    stockRequestList: [],
    stockAcceptList: [],
    stockApprovalList: [],
    stockRepairList: [],
    stockTempraroryList: [],
    stockOutData: {},
    purchaseDetails: {},
    stockTransferDetails: {},
    stockType: 1,
    // stockTransferApprovalDetails: {},
    stockTransferRepairDetails: {},
    stockTransferTempraroryDetails: {},
    repairData: {},
    tempraroryData: {},
    RejectMode: false,
    stockApprovalModel: false,
    stockRepairViewModel: false,
    addRepairModel: false,
    addTempraroryModel: false,
    stockTempraroryViewModel: false,
    activeTab: 1,
    minSaleAmount: 0,
    maxSaleAmount: 0,
    stockRequestparams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },

    stockApprovalparams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    stockRepairparams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    stockTempraroryparams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
  },
  reducers: {
    getStockRequestList: (state) => {
      if (state.stockRequestparams.page == 1) {
        return {
          ...state,
          loading: true,
          stockRequestList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    stockRequestListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.stockRequestparams.page_size
      );
      return {
        ...state,
        stockRequestlistCount: action.payload.response.count,
        stockRequestList: action.payload.response.results,
        loading: false,
        stockRequestparams: {
          ...state.stockRequestparams,
          no_of_pages: noofpages,
        },
      };
    },

    getStockAcceptList: (state, action) => {
      if (state.stockRequestparams.page == 1) {
        return {
          ...state,
          loading: true,
          stockAcceptList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    stockAcceptListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.stockRequestparams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        stockAcceptList: action.payload.response.results,
        loading: false,
        stockRequestparams: {
          ...state.stockRequestparams,
          no_of_pages: noofpages,
        },
      };
    },
    setStockRequestParams: (state, action) => {
      return {
        ...state,
        stockRequestparams: action.payload,
      };
    },
    isRequestDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          stockRequestDrawer: action.payload,
        };
      } else {
        return {
          ...state,
          stockRequestDrawer: action.payload,
        };
      }
    },
    RequestFilterInputChangeValue: (state, action) => {
      return {
        ...state,
        stockRequestparams: {
          ...state.stockRequestparams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    getFormulaExecuter: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    FormulaExecuterSuccessful: (state, action, code) => {
      switch (action.payload.formulaCode) {
        case "MINSALEAMOUNT":
          return {
            ...state,
            loading: false,
            minSaleAmount: parseFloat(action.payload.response).toFixed(2),
          };
          break;
        case "MAXSALEAMOUNT":
          return {
            ...state,
            loading: false,
            maxSaleAmount: parseFloat(action.payload.response).toFixed(2),
          };
          break;
        case "MAXSALEAMOUNT_MINISALEAMOUNT":
          return {
            ...state,
            loading: false,
            maxSaleAmount: parseFloat(action.payload.response).toFixed(2),
          };
          break;
        case "SALEPROFIT":
          return {
            ...state,
            loading: false,
            stockOutData: {
              ...state.stockOutData,
              profit: parseFloat(action.payload.response).toFixed(2),
            },
          };
          break;

        default:
          break;
      }
    },

    // Stock Transfer Approvals
    getStockApprovaltList: (state, action) => {
      if (state.stockApprovalparams.page == 1) {
        return {
          ...state,
          loading: true,
          listCount: 0,
          stockApprovalList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },

    stockApprovalListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.stockApprovalparams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        stockApprovalList: action.payload.response.results,
        loading: false,
        stockApprovalparams: {
          ...state.stockApprovalparams,
          no_of_pages: noofpages,
        },
      };
    },
    setStockApprovalParams: (state, action) => {
      return {
        ...state,
        stockApprovalparams: action.payload,
      };
    },

   

    stockOutAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    stockOutEdit: (state, action) => {
      return {
        ...state,
        model: true,
        stockOutData: {
          id: action.payload.id,
          counter_id: action.payload.counter.id,
          tocounter_id: action.payload.tocounter.id,
          purchase_id: action.payload.purchase.id,
          stock_purchase_id: action.payload.purchase.id,
          re_estimation_amount: action.payload.re_estimation_amount,
          repair_amount: action.payload.repair_amount,
          saleamount: action.payload.saleamount,
          profit: action.payload.profit,
        },
      };
    },
    stockOutDelete: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    stockTransferAccept: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    stockTransferApprove: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    getStockTransferViewById: (state, action) => {
      return {
        ...state,
        loading: true,
        stockType: action.payload.stockType,
      };
    },

    getStockTransferViewByIdSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        stockTransferDetails: action.payload,
      };
    },


    InputChangeValue: (state, action) => {
      if (action.payload.key == "counter_id") {
        return {
          ...state,
          stockOutData: {
            [action.payload.key]: action.payload.value,
          },
          purchaseDetails: {},
        };
      } else if (action.payload.key == "vehicle_id") {
        return {
          ...state,
          stockOutData: {
            ...state.stockOutData,
            [action.payload.key]: action.payload.value,
            saleamount: null,
            profit: null,
          },
        };
      } else {
        return {
          ...state,
          stockOutData: {
            ...state.stockOutData,
            [action.payload.key]: action.payload.value,
          },
        };
      }
    },

    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
          model: action.payload,
          stockOutData: {},
          purchaseDetails: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    isStockTransferModelVisible: (state, action) => {
      return {
        ...state,
        loading: false,
        RejectMode: action.payload,
      };
    },
    isStockApprovalModelVisible: (state, action) => {
      return {
        ...state,
        loading: false,
        stockApprovalModel: action.payload,
      };
    },
    getPurchaseById: (state, action) => {
      return {
        ...state,
        loading: false,
        purchaseDetails: action.payload,
        stockOutData: {
          ...state.stockOutData,
          purchase_id: action.payload.id,
        },
        minSaleAmount: parseInt(action.payload?.minimumsaleamount),
      };
    },

    isApprovalDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          stockApprovalDrawer: action.payload,
        };
      } else {
        return {
          ...state,
          stockApprovalDrawer: action.payload,
        };
      }
    },
    ApprovalFilterInputChangeValue: (state, action) => {
      return {
        ...state,
        stockApprovalparams: {
          ...state.stockApprovalparams,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    // Stock Transfer Repair

    isRepairModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          addRepairModel: action.payload,
          repairData: {},
          purchaseDetails: {},
        };
      } else {
        return {
          ...state,
          addRepairModel: action.payload,
        };
      }
    },

    RepairInputChangeValue: (state, action) => {
      return {
        ...state,
        repairData: {
          ...state.repairData,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    getStockRepairList: (state, action) => {
      if (state.stockRepairparams?.page == 1) {
        return {
          ...state,
          loading: true,
          stockRepairList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },

    stockRepairListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.stockRepairparams.page_size
      );
      return {
        ...state,
        stockRepairlistCount: action.payload.response.count,
        stockRepairList: action.payload.response.results,
        loading: false,
        stockRepairparams: {
          ...state.stockRepairparams,
          no_of_pages: noofpages,
        },
      };
    },
    setStockRepairParams: (state, action) => {
      return {
        ...state,
        stockRepairparams: action.payload,
      };
    },
    isRepairViewModelVisible: (state, action) => {
      return {
        ...state,
        stockRepairViewModel: action.payload,
      };
    },

    stockRepairAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    stockRepairAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        repairData: {},
      };
    },

    getStockTransferRepairById: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    getStockTransferRepairByIdSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        stockTransferRepairDetails: action.payload,
      };
    },

    stockRepairEdit: (state, action) => {
      return {
        ...state,
        model: true,
        repairData: {
          id: action.payload.id,
          mechanicname: action.payload.mechanicname,
          mobile: action.payload.mobile,
          purchase_id: action.payload.purchase,
          description: action.payload.description,
          guarage_id: action.payload.guarage?.id,
          isguarage:
          action.payload.guarage_id == action.payload.mechanicname
            ? "true"
            : "false",
        },
        purchaseDetails: action.payload.purchase,
      };
    },
    stockTransferRepair: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    stockRepairDelete: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    isRepairDrawerVisible: (state, action) => {
      return {
        ...state,
        stockRepairDrawer: action.payload,
      };
    },
    RepairFilterInputChangeValue: (state, action) => {
      return {
        ...state,
        stockRepairparams: {
          ...state.stockRepairparams,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    // Stock Transfer Temprarory

    isTempraroryModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          addTempraroryModel: action.payload,
          tempraroryData: {},
          purchaseDetails: {},
        };
      } else {
        return {
          ...state,
          addTempraroryModel: action.payload,
        };
      }
    },
    getStockTempraroryList: (state, action) => {
      if (state.stockTempraroryparams?.page == 1) {
        return {
          ...state,
          loading: true,
          stockTempraroryList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    stockTempraroryListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.stockTempraroryparams.page_size
      );
      return {
        ...state,
        stockTemprarorylistCount: action.payload.response.count,
        stockTempraroryList: action.payload.response.results,
        loading: false,
        stockTempraroryparams: {
          ...state.stockTempraroryparams,
          no_of_pages: noofpages,
        },
      };
    },
    setStockTempraroryParams: (state, action) => {
      return {
        ...state,
        stockTempraroryparams: action.payload,
      };
    },

    TempraroryInputChangeValue: (state, action) => {
      return {
        ...state,
        tempraroryData: {
          ...state.tempraroryData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    stockTempraroryAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    setStockTempViewData: (state, action) => {
      return {
        ...state,
        stockTempraroryViewModel: true,
        stockTransferTempraroryDetails: action.payload
      }
    },
    isTempraroryViewModelVisible: (state, action) => {
      return {
        ...state,
        stockTempraroryViewModel: action.payload,
      };
    },
    stockTransferTemprarory: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    stockTempraroryEdit: (state, action) => {
      return {
        ...state,
        addTempraroryModel: true,
        tempraroryData: {
          id: action.payload.id,
          requestername: action.payload.requestername,
          counter_id: action.payload.counter?.id,
          tocounter_id: action.payload.tocounter?.id,
          purchase_id: action.payload.purchase,
          description: action.payload.description,
        },
        purchaseDetails: action.payload,
      };
    },

    stockTempraroryDelete: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    isTempraroryDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          stockTempraroryDrawer: action.payload,
        };
      } else {
        return {
          ...state,
          stockTempraroryDrawer: action.payload,
        };
      }
    },
    TempraroryFilterInputChangeValue: (state, action) => {
      return {
        ...state,
        stockTempraroryparams: {
          ...state.stockTempraroryparams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    stockTransferActiveTab: (state, action) => {
      return {
        ...state,
        loading: true,
        activeTab: action.payload,
      };
    },

    // Repair & RE Amount

    getTotalReestimationAmountByPurchase: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    TotalReestimationAmountByPurchaseSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        stockOutData: {
          ...state.stockOutData,
          re_estimation_amount: action.payload.amount,
        },
      };
    },
    getTotalRepairAmountByPurchase: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    TotalRepairAmountByPurchaseSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        stockOutData: {
          ...state.stockOutData,
          // repair_amount: action.payload.amount,
          repair_amount:
            action.payload.pendingcount !== 0 ? null : action.payload.amount,
        },
      };
    },
    fsyncStatusUpdate: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getStockRequestList,
  stockRequestListSuccessful,
  getFormulaExecuter,
  FormulaExecuterSuccessful,
  getStockAcceptList,
  stockAcceptListSuccessful,
  getStockApprovaltList,
  stockApprovalListSuccessful,
  stockOutAdd,
  stockOutAddSuccessful,
  stockOutEdit,
  stockOutDelete,
  getPurchaseById,
  InputChangeValue,
  isModelVisible,
  apiError,
  isStockTransferModelVisible,
  isStockApprovalModelVisible,
  getStockTransferViewById,
  getStockTransferViewByIdSuccessful,
  stockTransferAccept,
  stockTransferApprove,
  setStockRequestParams,
  setStockApprovalParams,
  isApprovalDrawerVisible,
  ApprovalFilterInputChangeValue,
  isRequestDrawerVisible,
  RequestFilterInputChangeValue,

  isRepairModelVisible,
  RepairInputChangeValue,
  getStockRepairList,
  stockRepairListSuccessful,
  setStockRepairParams,
  isRepairViewModelVisible,
  stockRepairAdd,
  stockRepairAddSuccessful,
  getStockTransferRepairById,
  getStockTransferRepairByIdSuccessful,
  stockRepairEdit,
  stockTransferRepair,
  stockRepairDelete,
  RepairFilterInputChangeValue,
  isRepairDrawerVisible,

  getStockTempraroryList,
  stockTempraroryListSuccessful,
  setStockTempraroryParams,
  isTempraroryModelVisible,
  TempraroryInputChangeValue,
  stockTempraroryAdd,
  isTempraroryViewModelVisible,
  stockTransferTemprarory,
  stockTempraroryEdit,
  stockTempraroryDelete,
  isTempraroryDrawerVisible,
  TempraroryFilterInputChangeValue,
  stockTransferActiveTab,

  getTotalReestimationAmountByPurchase,
  TotalReestimationAmountByPurchaseSuccessful,
  getTotalRepairAmountByPurchase,
  TotalRepairAmountByPurchaseSuccessful,
  fsyncStatusUpdate,


  setStockTempViewData,
} = stockRequestSlice.actions;

export default stockRequestSlice.reducer;
