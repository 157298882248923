import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const dailyPaymentSlice = createSlice({
  name: "dailyPayment",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    model: false,
    drawer: false,
    apiError: {},
    listCount: 0,
    expensesList: [],
    expensesParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    selectExpenseItem: [],
    accountsData: {},
    dailyPaymentList: [],
    dailyPaymentParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    numberOfItems: 0,
    imgLoading: false,
    dailyPaymentView: {},
    paymentRejectData: {},
  },
  reducers: {
    clearData: (state) => {
      return {
        ...state,
        loading: false,
        expensesList: [],
        accountsData: {
        },
        selectExpenseItem: [],
        idealStockList: [],
        dailyPaymentList: [],
        dailyPaymentParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        RejectMode: false,
      };
    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        idealStockParams: {
          ...state.idealStockParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    
    
    InputChangeValue: (state, action) => {
      return {
        ...state,
        accountsData: {
          ...state.accountsData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
   

    // Get expenses list
    getExpensesList: (state, action) => {
      if (state.expensesParams?.page == 1) {
        return {
          ...state,
          loading: true,
          expensesList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    expensesListSuccessful: (state, action) => {
      // var response = action.payload;
      // var versionList = response.results;
      // var list = [];
      // if (state.expensesParams?.page == 1) {
      //   list = versionList;
      // } else {
      //   list = [...state.expensesList, ...versionList];
      // }
      // var noofpages = Math.ceil(
      //   action.payload.count / state.expensesParams?.page_size
      // );
      // return {
      //   ...state,
      //   expensesList: list,
      //   loading: false,
      //   model: true,
      //   expensesParams: {
      //     ...state.expensesParams,
      //     no_of_pages: noofpages,
      //   },
      // };
      var response = action.payload;
      var newList = [];
      var selectedExpenseItemsList = state.selectExpenseItem.filter(
        (item) => !item.dodelete
      );
      var selecteExpens_ids = selectedExpenseItemsList.map((i) => {
        return i._id;
      });
      newList = response.results.filter((item) => {
        return selecteExpens_ids.indexOf(item.id) == -1;
      });
      var list = [];
      if (state.expensesParams?.page == 1) {
        list = newList;
      } else {
        list = [...state.expensesList, ...newList];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.expensesParams?.page_size
      );
      return {
        ...state,
        expensesList: list,
        loading: false,
        model: true,
        expensesParams: {
          ...state.expensesParams,
          no_of_pages: noofpages,
        },
      };
    },
    setExpensesParams: (state, action) => {
      return {
        ...state,
        expensesParams: action.payload,
      };
    },

    addExpenseItem: (state, action) => {
      return {
        ...state,
        numberOfItems: state.numberOfItems + 1,
        selectExpenseItem: [
          ...state.selectExpenseItem,
          {
            _id: action.payload.id,
            name: action.payload.name,
            amount: "",
            remarks: "",
            attachment: "",
            dodelete: false,
          },
        ],
        model: false,
        expensesParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 20,
          page: 1,
          search: "",
        },
      };
    },
    isModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          model: action.payload,
        };
      } else {
        return {
          ...state,
          model: action.payload,
          expensesList: [],
          expensesParams: {
            start_date: "",
            end_date: "",
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
          },
        };
      }
    },
    removeItem: (state, action) => {
      var selectedRow = [];
      if (
        action.payload.id == 0 ||
        action.payload.id == null ||
        action.payload.id == undefined
      ) {
        selectedRow = state.selectExpenseItem.filter((item) => {
          return item._id != action.payload._id;
        });
      } else {
        selectedRow = state.selectExpenseItem.map((item) => {
          if (item.id == action.payload.id) {
            return { ...item, dodelete: true };
          }
          return item;
        });
      }
      return {
        ...state,
        selectExpenseItem: selectedRow,
      };
    },
    stockInputChangeValue: (state, action) => {
      var selectedItem = state.selectExpenseItem.map((item) => {
        if (item._id == action.payload.item._id) {
          return {
            ...item,
            [action.payload.key]: action.payload.value,
          };
        }
        return item;
      });
      return {
        ...state,
        accountsData:{
          ...state.accountsData
        },
        selectExpenseItem: selectedItem,
      };
    },
    addDailyPayment: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getPaymentImageUpload: (state, action) => {
      return {
        ...state,
        imgLoading: true,
      }
    },
    paymentImageUploadSuccessful: (state, action) => {
      const { id, name, uid, file, item} = action.payload;
      const updatedSelectExpenseItem = state.selectExpenseItem.map((e) => {
        if (e._id === item) {
          return {
            ...e,
            attachment: {
              file: file,
              name: name,
              id: id,
              item: item
            },
          };
        } else {
          return e;
        }
      });
      return {
        ...state,
        selectExpenseItem: updatedSelectExpenseItem,
        imgLoading: false,
      };
    },

    // get payment list
    getDailyPaymentList: (state, action) => {
      if (state.dailyPaymentParams?.page == 1) {
        return {
          ...state,
          loading: true,
          dailyPaymentList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    dailyPaymentListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.dailyPaymentParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.dailyPaymentParams?.page_size
      );
      return {
        ...state,
        dailyPaymentList: list,
        listCount: action.payload.count,
        loading: false,
        dailyPaymentParams: {
          ...state.dailyPaymentParams,
          no_of_pages: noofpages,
        },
      };
    },
    setDailyPaymentParams: (state, action) => {
      return {
        ...state,
        dailyPaymentParams: action.payload,
      };
    },
    getDailyPaymentById: (state) => {
      return {
        ...state,
        loading: true,
        accountsData: {},
      };
    },
    dailyPaymentByIdSuccessful: (state, action) => {
      var numberOfItems = state.numberOfItems
      var paymentItems = action.payload.response.dailypaymentitems?.map((item, i) => {
        numberOfItems += 1;
        return {
          _id: numberOfItems,
          id: item.id,
          dailypaymenthead_id: item?.id,
          amount: item.amount,
          remarks: item.remarks,
          dodelete: false,
          name: item.dailypaymenthead?.name,
          attachment: item.attachment,
        };
      });
      return {
        ...state,
        loading: false,
        // numberOfItems: numberOfItems,
        accountsData: {
          id: action.payload.response.id,
          counter_id: action.payload.response.counter?.id,
          date:
          action.payload.response.date == null
            ? null
            : moment(action.payload.response.date),
          dailypaymentitems: paymentItems,
        },
        selectExpenseItem: paymentItems,
        dailyPaymentView: action.payload.response,
      };
    },
    approvePayment:(state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    isRejectModelVisible: (state, action) => {
      return {
        ...state,
        RejectMode: action.payload,
        paymentRejectData: {},
        loading: false,
      };
    },
    rejectModelInputChangeValue: (state, action) => {
      return {
        ...state,
        paymentRejectData: {
          ...state.paymentRejectData,
          [action.payload.key]: action.payload.value,
        },
        loading: false,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  clearData,
  isDrawerVisible,
  FilterInputChangeValue,
  stockInputChangeValue,
  getDailyPaymentList,
  dailyPaymentListSuccessful,
  setDailyPaymentParams,
  getExpensesList,
  expensesListSuccessful,
  setExpensesParams,
  addExpenseItem,
  isModelVisible,
  removeItem,
  addDailyPayment,
  InputChangeValue,
  getPaymentImageUpload,
  paymentImageUploadSuccessful,
  getDailyPaymentById,
  dailyPaymentByIdSuccessful,
  approvePayment,
  isRejectModelVisible,
  rejectModelInputChangeValue,
  apiError,
} = dailyPaymentSlice.actions;

export default dailyPaymentSlice.reducer;
