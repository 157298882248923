import { createSlice } from "@reduxjs/toolkit";

export const refurbishedSlice = createSlice({
  name: "refurbished",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    model: false,
    repairViewModel: false,
    drawer: false,
    error_msg: null,
    repairTypeImgLooading: false,
    rowdata: {
      refurbishedamountitems: [
        {
          index: 0,
          repair: {},
          amount: "",
          description: "",
          dodelete: false,
        },
      ],
      majorOrMinorRepair: "is_major_refurbished",
      amount: "",
      attachment_ids: [],
      attachment: [],
     
    },
    activeRefurbishedAmountItemsCount: 1,
    listCount: 0,
    rowDataView: {},
    refurbishedList: [],
    refurbishedParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    // TotalRepirAmount: 0,
    repairTypeList: [],
    originalRepairTypeList: [],
    attachment: [],
    DocImgList: [],
    attachmentImgeLoading: false,
    DocImgeListLoading: false,


    pendingRepairAmountList: [],
    pendingReapirAmountParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    pendingListCount: 0,
    estimationrepairamount: 0,
    purchaseData:{},
  },
  reducers: {
    clearReapirImges: (state) => {
      return {
        ...state,
        loading: false,
        attachment: [],
        DocImgList: [],
        estimationrepairamount: 0
      };
    },
    clearReapirAmountList: (state) => {
      return {
        ...state,
        loading: false,
        refurbishedList: [],
      };
    },
    clearRepairAmountApprovals: (state, action) => {
      return {
        ...state,
        loading: false,
        pendingRepairAmountList: [],
        pendingReapirAmountParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
          currentSort: "code",
          sortOrder: "-",
        },
        purchaseData:{},
      };
    },
    getRefurbishedPayment: (state, action) => {
      if (state.refurbishedParams.page == 1) {
        return {
          ...state,
          loading: true,
          refurbishedList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    refurbishedPaymentListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.count / state.refurbishedParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.count,
        refurbishedList: action.payload.results,
        loading: false,
        refurbishedParams: {
          ...state.refurbishedParams,
          no_of_pages: noofpages,
        },
      };

      // var response = action.payload;
      // var list = [];
      // if (state.reapirAmountParams?.page == 1) {
      //   list = response.results;
      // } else {
      //   list = [...state.repairAmountList, ...response.results];
      // }
      // var noofpages = Math.ceil(
      //   action.payload.count / state.reapirAmountParams?.page_size
      // );
      // return {
      //   ...state,
      //   repairAmountList: list,
      //   listCount: response.count,
      //   loading: false,
      //   reapirAmountParams: {
      //     ...state.reapirAmountParams,
      //     no_of_pages: noofpages,
      //   },
      // };
    },
    SetRefurbishedParams: (state, action) => {
      return {
        ...state,
        refurbishedParams: action.payload,
      };
    },

    refurbishedAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    refurbishedAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {
          ...state.rowdata,
          refurbishedamountitems: [
            {
              index: 0,
              repair: {},
              amount: "",
              description: "",
              dodelete: false,
            },
          ],
        },
      };
    },
    getRefurbishedByID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    refurbishedByIDSuccessful: (state, action) => {

      let batteryName =
        action.payload.response?.battery_warranty_file?.file_thumbnail.split(
          "/"
        );
      let batteryData = {
        ...action.payload.response.battery_warranty_file,
        thumbUrl: action.payload.response.battery_warranty_file?.file,
        name:
          action.payload.response.battery_warranty_file != null
            ? batteryName[7]
            : "null",
      };

      let attachmentData = action.payload.response?.attachment.map((item) => {
        let itemName = item?.file_thumbnail.split("/");
        return {
          ...item,
          thumbUrl: item.file,
          name: itemName[7],
        };
      });

      var repairItems = action.payload.response.refurbishedamountitems?.map(
        (item, index) => {
          return {
            index: index + 1,
            id: item.id,
            repair: item.repair,
            amount: item.amount,
            description: item.description,
            dodelete: false,
          };
        }
      );
      return {
        ...state,
        loading: false,
        rowdata: {
          id: action.payload.response.id,
          amount: action.payload.response.amount,
          description: action.payload.response.description,
          purchase_id: action.payload.response.purchase?.id,
          counter_id: action.payload.response.counter?.id,
          // repair: action.payload.repair?.id
          paymentmode: action.payload.response.paymentmode,
          bank_id: action.payload.response.bank?.id,
          acholdername: action.payload.response.acholdername,
          bankacno: action.payload.response.bankacno,
          ifsccode: action.payload.response.ifsccode,
          refurbishedamountitems: repairItems,
          refurbishedtype: action.payload.response.refurbishedtype,
          refurbishedtype_name: action.payload.response.refurbishedtype_name,
          guarage_id: action.payload.response?.guarage?.id,
          guarage_name: action.payload.response?.guarage?.name,
          estimationrepairamount: action.payload.response.estimationrepairamount,
          majorOrMinorRepair: action.payload.response.is_major_refurbished
            ? "is_major_refurbished"
            : action.payload.response.is_minor_refurbished
            ? "is_minor_refurbished"
            : null,
        },
        activeRefurbishedAmountItemsCount: repairItems.length,
        rowDataView: action.payload.response,
        model: action.payload.type == "update" ? true : false,
        repairViewModel: action.payload.type == "view" ? true : false,
        attachment: attachmentData,
        attachment_ids: action.payload.response.attachment?.map((i) => i.id),
        battery_warranty_file: batteryData,
        battery_warranty_file_id:
          action.payload.response?.battery_warranty_file?.id,
        DocImgList:
          action.payload.response?.battery_warranty_file == null
            ? []
            : [batteryData],
        purchaseData: action.payload.response.purchase
      };
    },
    isRepairAmountViewModelVisible: (state, action) => {
      if (action.payload == true) {
        return {
          ...state,
          repairViewModel: action.payload,
        };
      } else {
        return {
          ...state,
          repairViewModel: action.payload,
          rowdata: {
            refurbishedamountitems: [
              {
                index: 0,
                repair: {},
                amount: "",
                description: "",
                dodelete: false,
              },
            ],
          },
        };
      }
    },
    refurbishedDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    checkTotalAmount: (state) => {
      var repaitItems = state.rowdata?.refurbishedamountitems.filter(
        (item) => item.dodelete == false
      );
      const sum = repaitItems.reduce(
        (partialSum, a) =>
          partialSum + parseInt(a.amount == "" ? "0" : a.amount),
        0
      );

      return {
        ...state,
        loading: false,
        rowdata: {
          ...state.rowdata,
          amount: sum,
        },
      };
    },
    InputChangeValue: (state, action) => {
      if (action.payload.key == "paymentmode") {
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
            bank_id: null,
            acholdername: null,
            bankacno: null,
            ifsccode: null,
          },
        };
      } else {
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            [action.payload.key]: action.payload.value,
          },
        };
      }
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {
            refurbishedamountitems: [
              {
                index: 0,
                repair: {},
                amount: "",
                description: "",
                dodelete: false,
              },
            ],
            majorOrMinorRepair: "is_major_refurbished",
            amount: "",
          },
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },

    AddRefurbishedAmountRow: (state, action) => {
      if (action.payload.repair?.id != 0) {
        return {
          ...state,
          rowdata: {
            ...state.rowdata,
            refurbishedamountitems: [
              ...state.rowdata.refurbishedamountitems,
              {
                index:
                  action.payload.index == null ? 0 : action.payload.index + 1,
                repair: {},
                amount: "",
                description: "",
                dodelete: false,
              },
            ],
          },
          activeRefurbishedAmountItemsCount:
            parseInt(state.activeRefurbishedAmountItemsCount) + 1,
        };
      }
    },
    RemoveRefurbishedRow: (state, action) => {
      var RepairItemList = [];
      if (action.payload.id != null) {
        RepairItemList = state.rowdata.refurbishedamountitems.map((reItem) => {
          if (reItem.index == action.payload.index) {
            return { ...reItem, dodelete: true };
          }
          return reItem;
        });
      } else {
        RepairItemList = state.rowdata.refurbishedamountitems.filter((reItem) => {
          return reItem.index != action.payload.index;
        });
      }

      var activeList = RepairItemList.filter((item) => {
        return !item.dodelete;
      });
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          refurbishedamountitems: RepairItemList,
        },
        activeRefurbishedAmountItemsCount: activeList.length,
      };
    },

   

    RefurbishedInputChangeValue: (state, action) => {
      var RAItems = [];
      RAItems = state.rowdata.refurbishedamountitems.map((RAItem) => {
        if (RAItem.index == action.payload.index) {
          if (action.payload.key == "repair") {
            return {
              ...RAItem,
              repair: { id: action.payload.value, name: action.payload.repair },
            };
          } else {
            return {
              ...RAItem,
              [action.payload.key]: action.payload.value,
            };
          }
        }
        return RAItem;
      });
      return {
        ...state,
        loading: false,
        // repairTypeList: action.payload,
        rowdata: {
          ...state.rowdata,
          refurbishedamountitems: RAItems,
        },
      };
    },

    // Get daily expenses
    getRepairTypes: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    repairTypesSuccessful: (state, action) => {
      var repairTypeIds = [];
      if (state.rowdata?.refurbishedamountitems.length != 0) {
        repairTypeIds = state.rowdata?.refurbishedamountitems
          ?.filter((item) => !item.dodelete)
          ?.map((repairType) => {
            return repairType.repair?.id;
          });
      }
      var repairTypeListData = action.payload.filter((item) => {
        return repairTypeIds.indexOf(item.id) == -1;
      });
      return {
        ...state,
        loading: false,
        repairTypeList: repairTypeListData,
        originalRepairTypeList: action.payload,
      };
    },

    repairTypeImageDocUploadSuccessful: (state, action) => {
      let data = {
        ...action.payload.response.data,
        thumbUrl: action.payload.response.data.file,
        uid: action.payload.uid,
        name: action.payload.name,
      };
      return {
        ...state,
        DocImgeListLoading: false,
        DocImgList: [data],
      };
    },

    repairTypeImageUploadSuccessful: (state, action) => {
      let data = {
        ...action.payload.response.data,
        thumbUrl: action.payload.response.data.file,
        uid: action.payload.uid,
        name: action.payload.name,
      };

      return {
        ...state,
        attachmentImgeLoading: false,
        attachment: [...state.attachment, data],
      };
    },

    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        refurbishedParams: {
          ...state.refurbishedParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    
    getRepairTypeImageDocUpload: (state, action) => {
      return {
        ...state,
        imageLooading: true,
        DocImgeListLoading: true,
      };
    },

    getRepairTypeImageUpload: (state, action) => {
      return {
        ...state,
        attachmentImgeLoading: true,
      };
    },
    removeImg: (state, action) => {
      var receiptRemoveImg = [];
      receiptRemoveImg = state.attachment?.filter(
        (i) => i.id != action.payload.id
      );
      return {
        ...state,
        attachment: receiptRemoveImg,
      };
    },

    // Document Upload
    removeDocImg: (state, action) => {
      var documentRemoveImg = [];
      documentRemoveImg = state.DocImgList.filter(
        (i) => i.file.id != action.payload.file.id
      );
      return {
        ...state,
        DocImgList: documentRemoveImg,
      };
    },

    // get estimation amount
    getEstimationAmount: (state, action) => {
      return {
        ...state,
      }
    },
    estimationAmountSuccessful : (state, action) => {
      return {
        ...state,
        estimationrepairamount: action.payload
      };
    },

    // Repair Amount Approvals

    getPendingRepairAmountList: (state, action) => {
      if (state.pendingReapirAmountParams.page == 1) {
        return {
          ...state,
          loading: true,
          pendingRepairAmountList: [],
          pendingReapirAmountParams: {
            ...state.pendingReapirAmountParams,
            page_size: 10,
            page: 1,
            ...action.payload,
          },
          rowDataView: {},
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    pendingRepairAmountListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count /
          state.pendingReapirAmountParams.page_size
      );
      return {
        ...state,
        pendingListCount: action.payload.response.count,
        // pendingRepairAmountList: action.payload.response.results,
        pendingRepairAmountList: [
          ...state.pendingRepairAmountList,
          ...action.payload.response.results,
        ],
        loading: false,
        pendingReapirAmountParams: {
          ...state.pendingReapirAmountParams,
          no_of_pages: noofpages,
        },
      };
    },
    setPendingRepairAmountParams: (state, action) => {
      return {
        ...state,
        pendingReapirAmountParams: action.payload,
      };
    },
    fsyncStatusUpdate: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    repairAmountApproveStatus: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
    getPurchaseData:(state, action) => {
      return {
        ...state,
        purchaseData: action.payload,
      };
    }
  },
});
export const {
  getRefurbishedPayment,
  refurbishedPaymentListSuccessful,
  SetRefurbishedParams,
  refurbishedAdd,
  refurbishedAddSuccessful,
  refurbishedDelete,
  getRefurbishedByID,
  refurbishedByIDSuccessful,
  getRepairTypes,
  repairTypesSuccessful,
  checkTotalAmount,
  InputChangeValue,
  isModelVisible,
  AddRefurbishedAmountRow,
  RemoveRefurbishedRow,
  RefurbishedInputChangeValue,
  isDrawerVisible,
  FilterInputChangeValue,
  
  
  
  getRepairTypeImageUpload,
  repairTypeImageUploadSuccessful,
  removeImg,
  
  getRepairTypeImageDocUpload,
  repairTypeImageDocUploadSuccessful,
  removeDocImg,
  
  
  isRepairAmountViewModelVisible,
  
  
  clearReapirAmountList,
  clearRepairAmountApprovals,
  clearReapirImges,
  getEstimationAmount,
  estimationAmountSuccessful,


  getPendingRepairAmountList,
  pendingRepairAmountListSuccessful,
  setPendingRepairAmountParams,
  repairAmountApproveStatus,
  fsyncStatusUpdate,
  getPurchaseData,
  apiError,
} = refurbishedSlice.actions;

export default refurbishedSlice.reducer;
