import { createSlice } from "@reduxjs/toolkit";

export const assetSlice = createSlice({
  name: "asset",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    assetsList: [],
    model: false,
    drawer: false,
    error_msg: null,
    rowdata: {},
    rowDataView: {},
    listCount: 0,
    assetsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getAssetsList: (state, action) => {
      if (state.assetsParams?.page == 1) {
        return {
          ...state,
          loading: true,
          assetsList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    assetsListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.assetsParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.assetsParams?.page_size
      );
      return {
        ...state,
        assetsList: list,
        listCount: action.payload.count,
        loading: false,
        assetsParams: {
          ...state.assetsParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetAssetsParams: (state, action) => {
      return {
        ...state,
        assetsParams: action.payload,
      };
    },

    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    assetAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    getAssetByID: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    assetByIDSuccessful: (state, action) => {
      var DepartmentIds = [];
      DepartmentIds = action.payload.department.map((dept) => {
        return dept.id;
      });
      return {
        ...state,
        model: true,
        rowdata: {
          id: action.payload.id,
          name: action.payload.name,
          department_id: DepartmentIds,
        },
        // rowDataView: action.payload,
      };
    },

    clearAssetList: (state) => {
      return {
        ...state,
        loading: false,
        assetsList: [],
      };
    },

    assetDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getAssetsList,
  assetsListSuccessful,
  SetAssetsParams,
  assetAdd,
  getAssetByID,
  assetByIDSuccessful,
  assetDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  clearAssetList,
} = assetSlice.actions;

export default assetSlice.reducer;
