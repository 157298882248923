import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const myStockSlice = createSlice({
  name: "mystock",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    model: false,
    drawer: false,
    hotStockloading: false,
    apiError: {},
    myStockData: {},
    listCount: 0,
    saleListCount: 0,

    activeTab: "1",
    screen: { Type: "none", id: 0 },
    myStockList: [],
    stockImages: [],
    myStockParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    vehicleAttendanceslistCount: 0,
    vehicleAttendancesList: [],
    vehicleAttendanceParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    qrCodeactivitylistCount: 0,
    qrCodeactivityList: [],
    qrCodeactivityParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    QRCodeData: {},
    QRCodeModel: false,
    QRCodeStatus: true,
    commentsData: {},
    ratingModel: false,
    ratingData: {},
    imageModel: false,
    stockImgData: {},
    imageLooading: false,
    incentiveLoading: false,
  },

  reducers: {
    clearData: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    getMyStockList: (state, action) => {
      return {
        ...state,
        loading: true,
        myStockList: [],
      };
    },
    myStockListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.myStockParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        // myStockList: [...state.myStockList, ...action.payload.response.results],
        myStockList: action.payload.response.results,
        loading: false,
        myStockParams: {
          ...state.myStockParams,
          no_of_pages: noofpages,
        },
      };
    },
    clearStockList: (state, action) => {
      return {
        ...state,
        loading: true,
        stockList: [],
      };
    },
    setMyStockParams: (state, action) => {
      return {
        ...state,
        myStockParams: action.payload,
      };
    },

    getMyStockById: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    myStockByIDSuccessful: (state, action) => {
      console.log("myStockByIDSuccessful", action.payload);
      var oldImgs = [
        {
          ...action.payload.vehicleback_image,
          imageType: "Vehicle Back Image",
        },
        {
          ...action.payload.vehiclefront_image,
          imageType: "Vehicle Front Image",
        },
        {
          ...action.payload.vehicleleft_image,
          imageType: "Vehicle Left Image",
        },
        {
          ...action.payload.vehicleright_image,
          imageType: "Vehicle Right Image",
        },

        {
          ...action.payload.vehicle_odomerter_image,
          imageType: "Vehicle Odomerter Image",
        },

        { ...action.payload.rcfront_image, imageType: "RC Front Image" },
        { ...action.payload.rcback_image, imageType: "RC Back Image" },
      ];
      var NewImg = [
        {
          ...action.payload.vehicleback_image_latest,
          imageType: "Latest Vehicle Back Image",
        },
        {
          ...action.payload.vehiclefront_image_latest,
          imageType: "Latest Vehicle Front Image",
        },
        {
          ...action.payload.vehicleleft_image_latest,
          imageType: "Latest Vehicle Left Image",
        },
        {
          ...action.payload.vehicleright_image_latest,
          imageType: "Latest Vehicle Right Image",
        },

        {
          ...action.payload.vehicle_odomerter_image_latest,
          imageType: "Latest Vehicle Odomerter Image",
        },
        { ...action.payload.rcfront_image, imageType: "RC Front Image" },
        { ...action.payload.rcback_image, imageType: "RC Back Image" },
      ]
    
      return {
        ...state,
        loading: false,
        incentiveLoading: false,
        myStockData: action.payload,
        stockImages: action.payload.vehiclefront_image_latest ? NewImg  : oldImgs,
      };
    },
    InputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "counter__zone":
          return {
            ...state,
            myStockParams: {
              ...state.myStockParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter__division: null,
              counter: null,
            },
          };

        case "counter__division":
          return {
            ...state,
            myStockParams: {
              ...state.myStockParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter: null,
            },
          };

        case "counter__cluster":
          return {
            ...state,
            myStockParams: {
              ...state.myStockParams,
              [action.payload.key]: action.payload.value,
              counter: null,
            },
          };
        case "vehicle__version__brand":
          return {
            ...state,
            myStockParams: {
              ...state.myStockParams,
              [action.payload.key]: action.payload.value,
              vehicle__version__brandfamily: null,
              vehicle__version__model__name: null,
              vehicle__version: null,
            },
          };
        case "vehicle__version__brandfamily":
          return {
            ...state,
            myStockParams: {
              ...state.myStockParams,
              [action.payload.key]: action.payload.value,
              vehicle__version__model__name: null,
              vehicle__version: null,
            },
          };
        case "vehicle__version__model__name":
          return {
            ...state,
            myStockParams: {
              ...state.myStockParams,
              [action.payload.key]: action.payload.value,
              vehicle__version: null,
            },
          };
        case "stock_value_min":
          return {
            ...state,
            myStockParams: {
              ...state.myStockParams,
              [action.payload.key]: action.payload.value,
              stock_value_max: null,
            },
          };

        default:
          return {
            ...state,
            myStockParams: {
              ...state.myStockParams,
              [action.payload.key]: action.payload.value,
            },
          };
      }
      // return {
      //   ...state,
      //   myStockParams: {
      //     ...state.myStockParams,
      //     [action.payload.key]: action.payload.value,
      //   },
      // };
    },
    getVehicleAttendances: (state, action) => {
      return {
        ...state,
        loading: true,
        vehicleAttendanceslistCount: 0,
        vehicleAttendancesList: [],
      };
    },
    vehicleAttendancesSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.count / state.vehicleAttendanceParams.page_size
      );

      return {
        ...state,
        vehicleAttendanceslistCount: action.payload.count,
        vehicleAttendancesList: [
          ...state.vehicleAttendancesList,
          ...action.payload.results,
        ],
        loading: false,
        vehicleAttendanceParams: {
          ...state.vehicleAttendanceParams,
          no_of_pages: noofpages,
        },
      };
    },

    setVehicleAttendanceParams: (state, action) => {
      return {
        ...state,
        vehicleAttendanceParams: action.payload,
      };
    },

    getQRcodeactivity: (state, action) => {
      return {
        ...state,
        loading: true,
        qrCodeactivitylistCount: 0,
        qrCodeactivityList: [],
      };
    },

    qrCodeactivitySuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.count / state.qrCodeactivityParams.page_size
      );
      return {
        ...state,
        qrCodeactivitylistCount: action.payload.count,
        qrCodeactivityList: [
          ...state.qrCodeactivityList,
          ...action.payload.results,
        ],
        loading: false,
        qrCodeactivityParams: {
          ...state.qrCodeactivityParams,
          no_of_pages: noofpages,
        },
      };
    },

    setQRCodeactivityParams: (state, action) => {
      return {
        ...state,
        qrCodeactivityParams: action.payload,
      };
    },

    qrCodeGenerate: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getQRCodeData: (state, action) => {
      return {
        ...state,
        loading: true,
        QRCodeData: {},
      };
    },
    getQRCodeDataSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        QRCodeData: action.payload,
        QRCodeModel: true,
      };
    },
    getQRCodeStatus: (state, action) => {
      return {
        ...state,
        loading: true,
        QRCodeStatus: action.payload,
      };
    },
    updateSpecialIncentive: (state, action) => {
      return {
        ...state,
        // loading: true,
        incentiveLoading: true
      };
    },
    updateMinimumSale: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          commentsData: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
          commentsData: {},
        };
      }
    },
    CommentInputChangeValue: (state, action) => {
      return {
        ...state,
        commentsData: {
          ...state.commentsData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    commentsAdd: (state, action) => {
      return{
        ...state,
        loading: true,
      }
    },
    commentAddSuccessful: (state, action) => {
      return{
        ...state,
        loading: false,
      }
    },
    isRatingModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          ratingModel: action.payload,
          ratingData: {},
        };
      } else {
        return {
          ...state,
          ratingModel: action.payload,
          ratingData: {},
        };
      }
    },
    RatingInputChangeValue: (state, action) => {
      return {
        ...state,
        ratingData: {
          ...state.ratingData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    ratingAdd: (state, action) => {
      return{
        ...state,
        loading: true,
      }
    },
    ratingAddSuccessful: (state, action) => {
      return{
        ...state,
        loading: false,
      }
    },

    isStockImgModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          imageModel: action.payload,
          stockImgData: {},
        };
      } else {
        return {
          ...state,
          imageModel: action.payload,
          stockImgData: {},
        };
      }
    },
    // StockImgInputChangeValue: (state, action) => {
    //   return {
    //     ...state,
    //     stockImgData: {
    //       ...state.stockImgData,
    //       [action.payload.key]: action.payload.value,
    //     },
    //   };
    // },
    getStockImageUpload: (state, action) => {
      return {
        ...state,
        loading: true,
        imageLooading: true,
        stockImgData: {
          ...state.stockImgData,
          [action.payload.imageType]: null,
        },
      };
    },
    stockImageUploadSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        imageLooading: false,
        stockImgData: {
          ...state.stockImgData,
          [action.payload.ImageType]: action.payload.response.data,
          [action.payload.ImageType + "_id"]: action.payload.response.data.id,
        },
      };
    },
    stockImgAdd: (state, action) => {
      return{
        ...state,
        loading: true,
      }
    },  
    checkHotStock: (state, action) => {
      return{
        ...state,
        loading: true,
      }
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getMyStockList,
  myStockListSuccessful,
  setMyStockParams,
  InputChangeValue,
  clearData,
  getMyStockById,
  myStockByIDSuccessful,
  getVehicleAttendances,
  vehicleAttendancesSuccessful,
  setVehicleAttendanceParams,
  getQRcodeactivity,
  qrCodeactivitySuccessful,
  qrCodeGenerate,
  getQRCodeData,
  getQRCodeDataSuccessful,
  setQRCodeactivityParams,
  getQRCodeStatus,
  clearStockList,
  updateSpecialIncentive,
  updateMinimumSale,
  isModelVisible,
  CommentInputChangeValue,
  commentsAdd,
  commentAddSuccessful,
  isRatingModelVisible,
  RatingInputChangeValue,
  ratingAdd,
  ratingAddSuccessful,
  isStockImgModelVisible,
  // StockImgInputChangeValue,
  getStockImageUpload,
  stockImageUploadSuccessful,
  stockImgAdd,
  getStockImgById,
  stockImgByIdSuccessful,
  checkHotStock,
  apiError,
} = myStockSlice.actions;

export default myStockSlice.reducer;
