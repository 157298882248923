import { createSlice } from "@reduxjs/toolkit";

export const categorySlice = createSlice({
  name: "category",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    CategoryList: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    categoryParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
  },
  reducers: {
    getCategoryList: (state) => {
      if (state.categoryParams?.page == 1) {
        return {
          ...state,
          loading: true,
          CategoryList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    categoryListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.categoryParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.categoryParams?.page_size
      );
        return {
          ...state,
          CategoryList: list,
          listCount: action.payload.count,
          loading: false,
          categoryParams: {
            ...state.categoryParams,
            no_of_pages: noofpages,
          },
        };
    },
    categoryAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    categoryAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    categoryDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    setCategoryParams: (state, action) => {
      return {
        ...state,
        categoryParams: action.payload,
      };
    },
    setSelectData: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: {
          ...action.payload,
          id: action.payload.id,
          name: action.payload.name,
        },
       
      }
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getCategoryList,
  categoryListSuccessful,
  categoryAdd,
  categoryAddSuccessful,
  categoryDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  setCategoryParams,
  setSelectData
} = categorySlice.actions;

export default categorySlice.reducer;
