import { createSlice } from "@reduxjs/toolkit";

export const districtSlice = createSlice({
  name: "district",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    districtslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    districtParams: {
      no_of_pages: 0,
      listCount: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getDistricts: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    districtListSuccessful: (state, action) => {
      var noofpages = Math.ceil(action.payload.count / state.districtParams.page_size);

      return {
        ...state,
        listCount: action.payload.count,
        districtslist: action.payload.results,
        loading: false,
        districtParams: {
          ...state.districtParams,
          no_of_pages: noofpages,
        },
      };
    },
    districtAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    districtAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    districtEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: {
          id: action.payload.id,
          name: action.payload.name,
          state_id: action.payload.state?.id,
        },
      };
    },
    districtEditSuccessful: (state) => {
      return {
        ...state,
        loading: false,
        rowdata: {},
      };
    },
    districtDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetDistrictsParams: (state, action) => {
      return {
        ...state,
        districtParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getDistricts,
  districtListSuccessful,
  districtAdd,
  districtAddSuccessful,
  districtEdit,
  districtEditSuccessful,
  districtDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetDistrictsParams
} = districtSlice.actions;

export default districtSlice.reducer;
