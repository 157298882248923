import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const leaveApproveSlice = createSlice({
  name: "leaveApprove",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    model: false,
    drawer: false,
    RejectMode: false,
    apiError: {},
    listCount: 0,
    leaveApproveList: [],
    leaveApproveParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    leaveData: {},
    leaveRejectData: {},
  },
  reducers: {
    getLeaveApproveList: (state) => {
      if (state.leaveApproveParams?.page == 1) {
        return {
          ...state,
          loading: true,
          leaveApproveList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    leaveApproveListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.leaveApproveParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.leaveApproveParams?.page_size
      );
      return {
        ...state,
        leaveApproveList: list,
        listCount: action.payload.count,
        loading: false,
        leaveApproveParams: {
          ...state.leaveApproveParams,
          no_of_pages: noofpages,
        },
      };
    },
    setLeaveApproveParams: (state, action) => {
      return {
        ...state,
        leaveApproveParams: action.payload,
      };
    },
    getLeaveByID: (state, action) => {
      return{
        ...state,
        loading: true,
      }
    },
    leaveByIdSuccessful: (state, action) => {
      var countersIds = [];
      countersIds = action.payload?.user.counters.map((counter) => {
        return counter.id;
      });
      return{
        ...state,
        model: true,
        loading: false,
        leaveData: {
          id: action.payload.id,
          no_of_days: action.payload.no_of_days,
          date: action.payload.date == null ? "" : moment(action.payload.date),
          description: action.payload.description,
          leavestatus: action.payload.leavestatus,
          leavestatus_name: action.payload.leavestatus_name,
          leavefrom: action.payload.leavefrom == null ? "" : moment(action.payload.leavefrom),
          leaveto: action.payload.leaveto == null ? "" : moment(action.payload.leaveto),
          reason: action.payload.reason,
          approved_description: action.payload.approved_description,
          approvedby: action.payload.approvedby,
          approvedon: action.payload.approvedon == null ? "" : moment(action.payload.approvedon),
          createdon: action.payload.createdon == null ? "" : moment(action.payload.createdon),
          createdby: action.payload.createdby,
          user: action.payload.user,
          // countersIds: countersIds,
        }
      }
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          leaveData: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    leaveApprove: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    leaveApproveSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        model: false,
        RejectMode: false,
      };
    },
    isRejectModelVisible: (state, action) => {
      return {
        ...state,
        RejectMode: action.payload,
        leaveRejectData: {},
      };
    },


    InputChangeValue: (state, action) => {
      return {
        ...state,
        leaveRejectData: {
          ...state.leaveRejectData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        leaveApproveParams: {
          ...state.leaveApproveParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getLeaveApproveList,
  leaveApproveListSuccessful,
  setLeaveApproveParams,
  getLeaveByID,
  leaveByIdSuccessful,
  isModelVisible,
  leaveApprove,
  leaveApproveSuccessful,
  isRejectModelVisible,
  InputChangeValue,
  isDrawerVisible,
  FilterInputChangeValue,
  apiError,
} = leaveApproveSlice.actions;

export default leaveApproveSlice.reducer;
