import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Divider,
  Form,
  Input,
  Button,
  Drawer,
  Space,
  Select,
  DatePicker,
} from "antd";
import moment, { min } from "moment";

import {
  isDrawerVisible,
  FilterInputChangeValue,
  getPurchaseCount,
  getSaleCount,
  getRepairCount,
  getStockTransferCount,
  getEnquiryCount,
  getQuotationCount,
  clearFilterData,
  changeDatePicker,
  getTotalStockTransferCount,
  getVisitCount,
  getFinanceAmount,
} from "../../../store/dashboard/dashboardSlice";
import {
  getMiniCounters,
  getMiniClusters,
  getMiniDivisions,
  getMiniZones,
  getMiniZonesClearData,
  getMiniClustersClearData,
  getMiniDivisionsClearData,
  getMiniCountersClearData,
} from "../../../store/mini/miniSlice";
import SwitchablePicker from "../../components/data-entry/datepicker/switchable";
var date = new Date(),
  y = date.getFullYear(),
  m = date.getMonth();
function filter(props) {
  const [form] = Form.useForm();
  useEffect(() => {
    props.getMiniZones();
    props.getMiniCounters();
    props.getMiniClusters();
    props.getMiniDivisions();
    // props.getPurchaseCount();
    // props.getSaleCount();
    // props.getRepairCount();
    // props.getStockTransferCount();
    // props.getEnquiryCount();
    // props.getQuotationCount();
    // props.getTotalStockTransferCount();
    // props.getVisitCount();
  }, []);

  // console.log(
  //   "week....",
  //   moment(props.dashboardParams.start_date).startOf("week")
  // );

  function onFinish() {
    props.getPurchaseCount({
      ...props.dashboardParams,
      start_date: moment(props.dashboardParams.start_date).startOf(
        props.dashboardParams.dateType
      ),
      end_date: moment(props.dashboardParams.start_date).endOf(
        props.dashboardParams.dateType
      ),
      // props.dashboardParams.dateType == "date"
      //   ? props.dashboardParams.start_date
      // : props.dashboardParams.dateType == "week"
      // ? moment(props.dashboardParams.start_date).startOf("week")
      // : props.dashboardParams.dateType == "month"
      // ? moment(props.dashboardParams.start_date).startOf("month")
      // : props.dashboardParams.dateType == "year"
      // ? moment(props.dashboardParams.start_date).startOf("year")
      // : "",

      // props.dashboardParams.dateType == "date"
      //   ? props.dashboardParams.start_date
      //  props.dashboardParams.dateType == "week"
      // ? moment(props.dashboardParams.start_date).endOf("week")
      // : props.dashboardParams.dateType == "month"
      // ? moment(props.dashboardParams.start_date).endOf("month")
      // : props.dashboardParams.dateType == "year"
      // ? moment(props.dashboardParams.start_date).endOf("year")
      // : "",
    });

    props.getSaleCount({
      ...props.dashboardParams,
      start_date: moment(props.dashboardParams.start_date).startOf(
        props.dashboardParams.dateType
      ),
      end_date: moment(props.dashboardParams.start_date).endOf(
        props.dashboardParams.dateType
      ),
    });
    props.getRepairCount({
      ...props.dashboardParams,
      start_date: moment(props.dashboardParams.start_date).startOf(
        props.dashboardParams.dateType
      ),
      end_date: moment(props.dashboardParams.start_date).endOf(
        props.dashboardParams.dateType
      ),
    });
    props.getStockTransferCount({
      ...props.dashboardParams,
      start_date: moment(props.dashboardParams.start_date).startOf(
        props.dashboardParams.dateType
      ),
      end_date: moment(props.dashboardParams.start_date).endOf(
        props.dashboardParams.dateType
      ),
    });
    props.getEnquiryCount({
      ...props.dashboardParams,
      start_date: moment(props.dashboardParams.start_date).startOf(
        props.dashboardParams.dateType
      ),
      end_date: moment(props.dashboardParams.start_date).endOf(
        props.dashboardParams.dateType
      ),
    });
    props.getQuotationCount({
      ...props.dashboardParams,
      start_date: moment(props.dashboardParams.start_date).startOf(
        props.dashboardParams.dateType
      ),
      end_date: moment(props.dashboardParams.start_date).endOf(
        props.dashboardParams.dateType
      ),
    });
    props.getTotalStockTransferCount({
      ...props.dashboardParams,
      start_date: moment(props.dashboardParams.start_date).startOf(
        props.dashboardParams.dateType
      ),
      end_date: moment(props.dashboardParams.start_date).endOf(
        props.dashboardParams.dateType
      ),
    });
    props.getVisitCount({
      ...props.dashboardParams,
      start_date: moment(props.dashboardParams.start_date).startOf(
        props.dashboardParams.dateType
      ),
      end_date: moment(props.dashboardParams.start_date).endOf(
        props.dashboardParams.dateType
      ),
    });
    props.getFinanceAmount({
      ...props.dashboardParams,
      start_date: moment(props.dashboardParams.start_date).startOf(
        props.dashboardParams.dateType
      ),
      end_date: moment(props.dashboardParams.start_date).endOf(
        props.dashboardParams.dateType
      ),
    })
    props.isDrawerVisible(false);
  }

  function clearFilter() {
    props.clearFilterData();
    props.getMiniZones();
    props.getMiniCounters();
    props.getMiniClusters();
    props.getMiniDivisions();

    props.getPurchaseCount({
      start_date: moment(),
      end_date: moment(),
    });
    props.getSaleCount({
      start_date: moment(),
      end_date: moment(),
    });
    props.getRepairCount({
      start_date: moment(),
      end_date: moment(),
    });
    props.getStockTransferCount({
      start_date: moment(),
      end_date: moment(),
    });
    props.getEnquiryCount({
      start_date: moment(),
      end_date: moment(),
    });
    props.getQuotationCount({
      start_date: moment(),
      end_date: moment(),
    });
    props.getVisitCount({
      start_date: moment(),
      end_date: moment(),
    });
    props.getFinanceAmount({
      start_date: moment(),
      end_date: moment(),
    })
    props.getTotalStockTransferCount();
  }

  useEffect(() => {
    form.setFieldsValue({
      start_date: props.dashboardParams?.start_date,
      end_date: props.dashboardParams?.end_date,
      counter__zone: props.dashboardParams?.counter__zone,
      counter__division: props.dashboardParams?.counter__division,
      counter__cluster: props.dashboardParams?.counter__cluster,
      counter: props.dashboardParams?.counter,
      month: props.dashboardParams.month,
    });
  }, [props.dashboardParams]);

  function disableDateRanges(range = { startDate: false, endDate: false }) {
    const { startDate, endDate } = range;
    return function disabledDate(current) {
      let startCheck = true;
      let endCheck = true;
      if (startDate) {
        startCheck =
          current &&
          current < moment(startDate + " 00:00:01", "YYYY-MM-DD HH:mm:ss");
      }
      if (endDate) {
        endCheck =
          current &&
          current >
          moment(
            moment(endDate).format("YYYY-MM-DD") + " 23:59:59",
            "YYYY-MM-DD HH:mm:ss"
          );
      }

      return (startDate && startCheck) || (endDate && endCheck);
    };
  }
  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Drawer
          title="Filters"
          loading={props.loading}
          width={420}
          className="hp-drawer-mobile"
          onClose={() => {
            props.isDrawerVisible(false);
          }}
          placement="right"
          open={props.drawer}
          bodyStyle={{
            paddingBottom: 10,
          }}>
          <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Form.Item
                  label="Select Date"
                  name="start_date"
                  className="hp-mb-0">
                  <SwitchablePicker
                    id="start_date"
                    name="start_date"
                    style={{ width: "100%" }}
                    placeholder="Select Date"
                    PickerType={(e) => {
                      props.FilterInputChangeValue({
                        key: "dateType",
                        value: e,
                      });
                    }}
                    PickerTypeValue={props.dashboardParams.dateType}
                    disabledDate={disableDateRanges({
                      endDate: new Date(),
                    })}
                    value={props.dashboardParams.start_date}
                    onChange={(date) => {
                      props.FilterInputChangeValue({
                        key: "start_date",
                        value: date == null ? "" : date,
                      });
                      if (date == null) {
                        props.FilterInputChangeValue({
                          key: "end_date",
                          value: "",
                        });
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Zone Name :"
                  name="counter__zone"
                  className="hp-mb-0">
                  <Select
                    showSearch
                    allowClear
                    placeholder="Select  Zone"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(value) => {
                      props.getMiniDivisions({ zone: value });
                      // props.getMiniDivisionsClearData();
                      props.getMiniClustersClearData();
                      props.getMiniCountersClearData();
                      props.FilterInputChangeValue({
                        key: "counter__zone",
                        value: value,
                      });
                    }}
                    value={
                      props.dashboardParams.zone_id == null
                        ? ""
                        : props.dashboardParams?.zone_id
                    }>
                    {props.miniZonesList.map((zone, index) => {
                      return (
                        <Option key={index} value={zone.id}>
                          {zone.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Division Name:"
                  name="counter__division"
                  className="hp-mb-0">
                  <Select
                    showSearch
                    allowClear
                    placeholder="Select  Division"
                    optionFilterProp="division"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(value) => {
                      props.getMiniClusters({ division: value });
                      props.getMiniClustersClearData();
                      props.getMiniCountersClearData();
                      props.FilterInputChangeValue({
                        key: "counter__division",
                        value: value,
                      });
                    }}
                    value={
                      props.dashboardParams.division_id == null
                        ? ""
                        : props.dashboardParams?.division_id
                    }>
                    {props.miniDivisionsList.map((division, index) => {
                      return (
                        <Option key={index} value={division.id}>
                          {division.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Cluster Name:"
                  name="counter__cluster"
                  className="hp-mb-0">
                  <Select
                    showSearch
                    allowClear
                    placeholder="Select Cluster"
                    optionFilterProp="cluster"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(value) => {
                      props.getMiniCounters({ cluster: value });
                      props.getMiniCountersClearData({});
                      props.FilterInputChangeValue({
                        key: "counter__cluster",
                        value: value,
                      });
                    }}
                    value={
                      props.dashboardParams.cluster_id == null
                        ? ""
                        : props.dashboardParams?.cluster_id
                    }>
                    {props.miniClustersList.map((cluster, index) => {
                      return (
                        <Option key={index} value={cluster.id}>
                          {cluster.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Counter" name="counter" className="hp-mb-0">
                  <Select
                    showSearch
                    allowClear
                    placeholder="Select a Counter"
                    optionFilterProp="counter"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(value) => {
                      // props.GetCounterByID(value);
                      props.FilterInputChangeValue({
                        key: "counter",
                        value: value,
                      });
                    }}
                    value={
                      props.dashboardParams.counter_id == null
                        ? ""
                        : props.dashboardParams?.counter_id
                    }>
                    {props.miniCountersList.map((counter, index) => {
                      return (
                        <Option key={index} value={counter.id}>
                          {counter.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              {/* <Col span={12}>
                <Form.Item label="Month" name="month"  className="hp-mb-0">
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder=" Month"
                    format="YYYY-MM"
                    picker="month"
                    onChange={(month) => {
                      console.log("moneht", month.format("YYYY-MM"));
                      // props.getPurchaseCount({
                      //   ...props.dashboardParams,
                      //   month: month,
                      // });
                      props.FilterInputChangeValue({
                        key: "month",
                        value: month
                        // value: props.dashboardParams.month
                        // value: month == null ? "" : month,
                      });
                    }}
                  />
                </Form.Item>
              </Col> */}
            </Row>
            <Row gutter={[12, 12]}>
              <Col md={12}>
                <Button
                  block
                  type="secondary"
                  className="hp-submit-btn hp-mt-10"
                  onClick={() => {
                    clearFilter();
                  }}
                  htmlType="button">
                  Clear
                </Button>
              </Col>
              <Col md={12}>
                <Button
                  block
                  type="primary"
                  className="hp-submit-btn hp-mt-10"
                  onClick={() => {
                    form.submit();
                  }}>
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </Col>
    </Row>
  );
}

function mapStateToProps({ dashboard, mini }) {
  return {
    drawer: dashboard.drawer,
    dashboardParams: dashboard.dashboardParams,
    miniCountersList: mini.miniCountersList,
    miniClustersList: mini.miniClustersList,
    miniDivisionsList: mini.miniDivisionsList,
    miniZonesList: mini.miniZonesList,
    loading: dashboard.loading,
    typeDatePicker: dashboard.typeDatePicker,
  };
}

export default connect(mapStateToProps, {
  isDrawerVisible,
  getMiniCounters,
  getMiniClusters,
  getMiniDivisions,
  getMiniZones,
  FilterInputChangeValue,
  getMiniZonesClearData,
  getMiniClustersClearData,
  getMiniDivisionsClearData,
  getMiniCountersClearData,
  getPurchaseCount,
  getSaleCount,
  getRepairCount,
  getStockTransferCount,
  getEnquiryCount,
  getQuotationCount,
  clearFilterData,
  changeDatePicker,
  getTotalStockTransferCount,
  getVisitCount,
  getFinanceAmount
})(filter);
