import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const visitSlice = createSlice({
  name: "visit",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    imageLooading: false,
    model: false,
    drawer: false,
    error_msg: null,
    listCount: 0,
    visitList: [],
    visitParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    rowdata: {},
    visitViewData: {},
    vehicleDetails: {},
    viewModel: false,
  },
  reducers: {
    clearVisitData: (state, action) => {
      return{
        ...state,
        loading: false,
        visitList: [],
        rowdata: {},
      }
    },
    getVisitList: (state, action) => {
      if (state.visitParams?.page == 1) {
        return {
          ...state,
          loading: true,
          visitList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    visitListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.visitParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.visitParams?.page_size
      );
      return {
        ...state,
        visitList: list,
        listCount: action.payload.count,
        loading: false,
        visitParams: {
          ...state.visitParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetVisitParams: (state, action) => {
      return {
        ...state,
        visitParams: action.payload,
      };
    },

    getVisitByID: (state) => {
      return {
        ...state,
        loading: true,
        rowdata: {},
      };
    },
    visitByIDSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        rowdata: {
          id: action.payload.response.id,
          sale_id: action.payload.response.sale?.id,
          visitpurpose: action.payload.response.visitpurpose,
          workstatus: action.payload.response.workstatus,
          description: action.payload.response.description,
          description: action.payload.response.description,
          name: action.payload.response.name,
          phone: action.payload.response.phone,
          hpnumber: action.payload.response.hpnumber,
          attachment: action.payload.response.attachment,
          attachment_id: action.payload.response.attachment?.id,
        },
        model: action.payload.type == "update" ? true : false,
        viewModel: action.payload.type == "view" ? true : false,
        visitViewData: action.payload.response,
        vehicleDetails: action.payload.response.sale
      };
    },
    getVehicleByID: (state, action) => {
      return {
        ...state,
        loading: false,
        vehicleDetails: action.payload,
      };
    },
    getVisitImageUpload: (state, action) => {
      return {
        ...state,
        loading: true,
        imageLooading: true,
        rowdata: {
          ...state.rowdata,
          [action.payload.imageType]: null,
        },
      };
    },
    visitImageUploadSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        imageLooading: false,
        rowdata: {
          ...state.rowdata,
          [action.payload.ImageType]: action.payload.response.data,
          [action.payload.ImageType + "_id"]: action.payload.response.data.id,
        },
      };
    },
    visitAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
          visitViewData: {},
          vehicleDetails:{},
        };
      } else {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
          visitViewData: {},
          vehicleDetails:{},
        };
      }
    },
    isViewModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          viewModel: action.payload,
          visitViewData: {},
        };
      } else {
        return {
          ...state,
          viewModel: action.payload,
        };
      }
    },
    visitDelete: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    

    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        visitParams: {
          ...state.visitParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getVisitList,
  visitListSuccessful,
  SetVisitParams,
  getVisitByID,
  visitByIDSuccessful,
  getVehicleByID,
  getVisitImageUpload,
  visitImageUploadSuccessful,
  visitAdd,
  visitDelete,
  InputChangeValue,
  isModelVisible,
  isDrawerVisible,
  FilterInputChangeValue,
  isViewModelVisible,
  clearVisitData,
  apiError,
} = visitSlice.actions;

export default visitSlice.reducer;
