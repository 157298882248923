import { createSlice } from "@reduxjs/toolkit";

export const itemSlice = createSlice({
  name: "items",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    itemsList: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
   itemsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    viewModel: false,
    itemViewData: {},
    itemsModelList:[],
    modelCount: 0,
    itemsModelParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    itemModelData: {},
    selectedModel: {},
    addModel: false,
    previewFile:{},
    priceLoading: false,
  },
  reducers: {
    clearIteModelData: (state, action) => {
      return {
        ...state,
        itemModelData:{},
      }
    },
    getItemsList: (state, action) => {
      return {
        ...state,
        loading: true,
        listCount: 0,
      };
    },
    itemsListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.itemsParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        itemsList: action.payload.response.results,
        loading: false,
        itemsParams: {
          ...state.itemsParams,
          no_of_pages: noofpages,
        },
      };
    },
    itemsAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    itemsAddSuccessful: (state) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        // itemAddModel: true,
        rowdata: {},
        itemViewData: action.payload.response
      };
    },
    itemsDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetItemsParams: (state, action) => {
      return {
        ...state,
        itemsParams: action.payload,
      };
    },
    setSelectData: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: {
          ...action.payload,
          id: action.payload.id,
          name: action.payload.name,
          price: action.payload.price,
          category_id: action.payload.category?.id,
          subcategory_id: action.payload.subcategory?.id,
          pricetype: action.payload.pricetype,
          image: action.payload.image,
          is_active: action.payload.is_active,
          itemtype: action.payload.itemtype
          // previewFile: action.payload.image
        },
        itemViewData: action.payload
      }
    },
    setSelectViewData: (state, action) => {
      return {
        ...state,
        viewModel: true,
        itemViewData: action.payload
      }
    },
    setSelectItem: (state, action) => {
      return {
        ...state,
        addModel: true,
        itemViewData: action.payload
      }
    },
   itemViewModel: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          viewModel: action.payload,
        };
      } else {
        return {
          ...state,
          viewModel: action.payload,
          itemViewData: {},
        };
      }
    },
    getItemsModelList: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    itemsModelListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.itemsModelParams.page_size
      );

      return {
        ...state,
        modelCount: action.payload.response.count,
        itemsModelList: action.payload.response.results,
        loading: false,
        itemsModelParams: {
          ...state.itemsModelParams,
          no_of_pages: noofpages,
        },
      };
    },
    setItemsModelParams: (state, action) => {
      return {
        ...state,
        itemsModelParams: action.payload,
      };
    },
    ItemInputChangeValue: (state, action) => {
      // return {
      //   ...state,
      //   itemModelData: {
      //     ...state.itemModelData,
      //     [action.payload.key]: {
      //       id: action.payload.value,
      //       name: action.payload.name,
      //     },
      //   },
      // };
      return {
        ...state,
        itemModelData: {
          ...state.itemModelData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    itemModelAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    itemModelAddSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        itemModelData: {},
      }
    },
    // getItemModelData: (state, action) => {
    //   console.log("getItemModelData", state.selectedModel)
    //   return {
    //     ...state,
    //     itemsModelList: state.itemsModelList.map((item) => {
    //      return item.id == selectedModel.id?item:action.payload.value
    //     }),
    //     // selectedModel: action.payload.value,
    //   };
    // },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
    itemModelDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    itemAddModel: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          addModel: action.payload,
        };
      } else {
        return {
          ...state,
          addModel: action.payload,
        };
      }
    },
    addImageUpload: (state, action) => {
      return {
        ...state,
        imageLooading: true,
        rowdata: {
          ...state.rowdata,
          [action.payload.imageType]: action.payload.file,
        }
      };
    },
    setPreviewImg:(state, action) => {
      return{
        ...state,
        loading: false,
        rowdata: {
          ...state.rowdata,
          previewFile: action.payload,
        }
        // rowdata: {
        //   ...state.rowdata, ...action.payload
        // }
      }
    },
    updateItemModelPrice:(state, action) => {
      return {
        ...state,
        priceLoading: true,
      }
    },
    updateIteModelPriceSuccessful: (state, action) => {
      return{
        ...state,
        priceLoading: false,
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getItemsList,
  itemsListSuccessful,
  itemsAdd,
  itemsAddSuccessful,
  modelsEdit,
  itemsDelete,
  InputChangeValue,
  apiError,
  SetItemsParams,
  isModelVisible,
  setSelectData,
  itemViewModel,
  setSelectViewData,
  getItemsModelList,
  itemsModelListSuccessful,
  setItemsModelParams,
  ItemInputChangeValue,
  getItemModelData,
  itemModelAdd,
  itemModelAddSuccessful,
  itemModelDelete,
  itemAddModel,
  setSelectItem,
  addImageUpload,
  setPreviewImg,
  updateItemModelPrice,
  updateIteModelPriceSuccessful,
  clearIteModelData,
} = itemSlice.actions;

export default itemSlice.reducer;
