import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Select,
  Card,
  Space,
  Table,
  Typography,
  Divider,
  Tabs,
} from "antd";
import { People } from "react-iconly";
import { saleActiveTab } from "../../store/dashboard/dashboardSlice";
import { Link } from "react-router-dom";
import moment from "moment";

import { getPurchaseList } from "../../store/sideMenu/purchases/purchasesSlice";

import { getVehiclesList } from "../../store/sideMenu/vehicleCounterSales/vehicleCounterSalesSlice";

function ListCard(props) {
  const { Text } = Typography;
  //   const {data} = props;
  const { TabPane } = Tabs;

  const columnClass =
    "hp-p1-body hp-text-color-black-100 hp-text-color-dark-0 hp-font-weight-600 ";

  useEffect(() => {
    if (props.userAccessList?.indexOf("Purchase.view_purchase")) {
      props.getPurchaseList({
        page: 1,
        page_size: 10,
      });
    }
    if (props.userAccessList?.indexOf("Sale.view_sale")) {
      props.getVehiclesList({
        page: 1,
        page_size: 10,
      });
    }
  }, []);

  const salesTypeList = [
    { id: "1", name: "Direct Sale" },
    { id: "2", name: "Finance Sale" },
    { id: "3", name: "OVF Sale" },
  ];

  function callback(key) {
    props.saleActiveTab(key);
    console.log(key);
    if (key == 0) {
      if (props.userAccessList?.indexOf("Purchase.view_purchase")) {
        props.getPurchaseList({
          page: 1,
          page_size: 10,
        });
      }
    } else {
      if (props.userAccessList?.indexOf("Sale.view_sale")) {
        props.getVehiclesList({
          saletype: key,
          page: 1,
          page_size: 10,
        });
      }
    }
  }

  const purchaseColumns = [
    {
      title: "S.No",
      dataIndex: "index",
    },
    {
      title: "Date",
      render: (row) => (
        <span className={columnClass}>
          {moment(row.date).format("DD-MM-YYYY")}
        </span>
      ),
    },
    {
      title: "Purchase Type",
      render: (row) => (
        <span className={columnClass}>{row.partytype_name} Purchase</span>
      ),
    },

    {
      title: "Customer",
      render: (row) => (
        <span className={columnClass}>{row.customer?.firstname}</span>
      ),
    },
    {
      title: "Vehicle No",
      render: (row) => (
        <span className={columnClass}>{row.vehicle?.vehicleno}</span>
      ),
    },
    {
      title: "Counter Name",
      render: (row) => <span className={columnClass}>{row.counter?.name}</span>,
    },

    {
      title: "Status",
      width: "15%",
      render: (row) => (
        <span className={columnClass}>
          {
            <Text
              type={
                row.approvalstatus == 2
                  ? "success"
                  : row.approvalstatus == 3
                  ? "danger"
                  : "warning"
              }
            >
              {row.approvalstatus_name == null
                ? "Pending"
                : row.approvalstatus_name}
            </Text>
          }
        </span>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (row, record) => (
        <Space size="middle">
          <Link to="/pages/purchases">
            <i
              className="ri-eye-line hp-mr-2 "
              style={{ cursor: "pointer", color: "#fc6f03" }}
            ></i>
          </Link>
        </Space>
      ),
    },
  ];

  const purchaseData = props.purchaseList.map((row, key) => ({
    ...row,
    index: key + 1,
  }));

  const directSaleColumns = [
    {
      title: "S.No",
      dataIndex: "index",
      width: 70,
    },
    {
      title: "Date",
      width: "10%",
      render: (row) => (
        <span className={columnClass}>
          {moment(row.date).format("DD-MM-YYYY")}
        </span>
      ),
    },
    {
      title: "Vehicle No",
      width: "15%",
      render: (row) => (
        <span className={columnClass}>{row.vehicle?.vehicleno}</span>
      ),
    },
    // {
    //   title: "Sales Agent",
    //   width: "18%",
    //   render: (row) => <span className={columnClass}>{row.code}</span>,
    // },
    {
      title: "Counter Name",
      width: "15%",
      render: (row) => <span className={columnClass}>{row.counter?.name}</span>,
    },
    {
      title: "Cluster",
      width: "18%",
      render: (row) => (
        <span className={columnClass}>{row.counter.cluster?.name}</span>
      ),
    },

    {
      title: "Vehicle Type",
      width: "15%",
      render: (row) => (
        <span className={columnClass}>{row.vehicle?.version?.name}</span>
      ),
    },
    {
      title: "Employee Name",
      width: "15%",
      render: (row) => (
        <span className={columnClass}>{row.customer?.firstname}</span>
      ),
    },

    {
      title: "Status",
      width: "15%",
      render: (row) => (
        <span className={columnClass}>
          {
            <Text
              type={
                row.approvalstatus == 2
                  ? "success"
                  : row.approvalstatus == 3
                  ? "danger"
                  : "warning"
              }
            >
              {row.approvalstatus_name == null
                ? "Pending"
                : row.approvalstatus_name}
            </Text>
          }
        </span>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (row, record) => (
        <Space size="middle">
          <Link to="/pages/Vechicle-Sales">
            <i
              className="ri-eye-line hp-mr-2 "
              style={{ cursor: "pointer", color: "#fc6f03" }}
            ></i>
          </Link>
        </Space>
      ),
    },
  ];

  const directSaleData = props.vehiclesList.map((row, key) => ({
    ...row,
    index: key + 1,
  }));

  return (
    <>
      <Card className="hp-border-color-black-40 hp-card-6">
        <Tabs defaultActiveKey="0" type="card" onChange={callback}>
          <TabPane tab="Purchase" key="0">
            <Col span={24}>
              <Table
                columns={purchaseColumns}
                dataSource={purchaseData}
                pagination={false}
                scroll={{ x: 1300 }}
                size="middle"
              />
            </Col>
          </TabPane>

          {salesTypeList.map((salesType) => {
            return (
              <TabPane tab={salesType.name} key={salesType.id}>
                <Table
                  columns={directSaleColumns}
                  dataSource={directSaleData}
                  pagination={false}
                  scroll={{ x: 1300 }}
                  size="middle"
                />
              </TabPane>
            );
          })}
        </Tabs>
      </Card>
    </>
  );
}
function mapStateToProps({ dashboard, purchase, counterSales, system }) {
  return {
    activeTab: dashboard.activeTab,
    purchaseList: purchase.purchaseList,
    vehiclesList: counterSales.vehiclesList,
    userAccessList: system.userAccessList,
  };
}

export default connect(mapStateToProps, {
  saleActiveTab,
  getPurchaseList,
  getVehiclesList,
})(ListCard);
