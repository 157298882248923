import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  leaveApproveListSuccessful,
  leaveByIdSuccessful,
  leaveApproveSuccessful,
  getLeaveApproveList
} from "./leaveApproveSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

//If user is login then dispatch redux action's are directly from here.

function* LeaveApproveList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/attendance/leave/approvel/",
      params
    );
    yield put(leaveApproveListSuccessful( response ));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* LeaveByID({ payload }) {
  try {
    const response = yield call(
      getList,
      "/attendance/leave/" + payload.id, {});
    yield put(leaveByIdSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* ApproveLeave({ payload }) {
  try {
    const response = yield call(
      postEdit,
      "/attendance/leave/approvel/" + payload.id,
      payload.approveObj
    );
    if (response.status === 200) {
      if (payload.approveObj.leavestatus == 2) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
              Leave Approved Successfully.!
              </p>
            </div>
          ),
        });
      }else if(payload.approveObj.leavestatus == 3) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                 Leave has been Rejected Successfully!
              </p>
            </div>
          ),
        });
      }
      yield put(leaveApproveSuccessful(response));
      yield put(
        getLeaveApproveList({
          page: 1,
          search: "",
          page_size: 10,
        })
      );
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Approve Leave. Please try again!
            </p>
          </div>
        ),
      });
    }
   
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetLeaveApproveList() {
  yield takeEvery("leaveApprove/getLeaveApproveList", LeaveApproveList);
}
export function* watchGetLeaveByID() {
  yield takeEvery("leaveApprove/getLeaveByID", LeaveByID);
}
export function* watchLeaveApprove() {
  yield takeEvery("leaveApprove/leaveApprove", ApproveLeave);
}
function* leaveApproveSaga() {
  yield all([
    fork(watchGetLeaveApproveList),
    fork(watchGetLeaveByID),
    fork(watchLeaveApprove),
  ]);
}

export default leaveApproveSaga;
