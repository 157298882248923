import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  stockRequestListSuccessful,
} from "./stockRequestSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

//If user is login then dispatch redux action's are directly from here.

function* StockRequestList({ payload }) {
  var data = payload;
  var params = {};
  // for (const k in data) {
  //   if (Object.hasOwnProperty.call(data, k)) {
  //     if (k === "currentSort" || k === "sortOrder") {
  //       params.ordering = data.sortOrder + data.currentSort;
  //     } else {
  //       if (data[k] != "" && k != null && k != "no_of_pages") {
  //         if (k == "start_date") {
  //           params[k] = moment(data[k]).format("YYYY-MM-DD");
  //         } else if (k == "end_date") {
  //           params[k] = moment(data[k]).format("YYYY-MM-DD");
  //         } else {
  //           params[k] = data[k];
  //         }
  //       }
  //     }
  //   }
  // }
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfer/stockrequestout/",
      params
    );
    yield put(stockRequestListSuccessful( response ));
  } catch (error) {
    yield put(apiError(error));
  }
}




export function* watchGetStockRequestList() {
  yield takeEvery("requestStock/getStockRequestList", StockRequestList);
}
function* requestStockSaga() {
  yield all([
    fork(watchGetStockRequestList)
  ]);
}

export default requestStockSaga;
