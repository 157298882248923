import { createSlice } from "@reduxjs/toolkit";

export const reEstimationSlice = createSlice({
  name: "reEstimation",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    reEstimationList: [],
    model: false,
    drawer: false,
    error_msg: null,
    rowdata: {},
    rowDataView: {},
    listCount: 0,
    reEstimationParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getReEstimation: (state, action) => {
      if (action.payload?.page == 1) {
        return {
          ...state,
          loading: true,
          reEstimationList: [],
          reEstimationParams: {
            no_of_pages: 0,
            page_size: action.payload.page_size,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
          },
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    reEstimationListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.count / state.reEstimationParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.count,
        reEstimationList: action.payload.results,
        loading: false,
        reEstimationParams: {
          ...state.reEstimationParams,
          no_of_pages: noofpages,
        },
      };
    },
    clearREAmountList: (state) => {
      return {
        ...state,
        loading: false,
        reEstimationList: [],
      };
    },
    reEstimationAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    reEstimationAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    getReEstimationByID: (state) => {
      return {
        ...state,
        loading: false,
        // rowdata: {},
      };
    },
    reEstimationByIDSuccessful: (state, action) => {
      console.log("reEstimationByIDSuccessful", action.payload);
      return {
        ...state,
        model: true,
        rowdata: {
          id: action.payload.id,
          amount: action.payload.amount,
          description: action.payload.description,
          purchase_id: action.payload.purchase?.id,
          counter_id: action.payload.counter?.id,
        },
        rowDataView: action.payload,
      };
    },
    reEstimationDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetreEstimationParams: (state, action) => {
      return {
        ...state,
        reEstimationParams: action.payload,
      };
    },

    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getReEstimation,
  reEstimationListSuccessful,
  reEstimationAdd,
  reEstimationAddSuccessful,
  getReEstimationByID,
  reEstimationByIDSuccessful,
  reEstimationDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetreEstimationParams,
  clearREAmountList,
} = reEstimationSlice.actions;

export default reEstimationSlice.reducer;
