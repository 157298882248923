import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  getSalequotation,
  getOVFSalequotation,
  saleQuotationListSuccessful,
  OVFSaleQuotationListSuccessful,
  saleQuotationByIDSuccessful,
  isModelVisible,
  FormulaExecuterSuccessful,
  enquiryByIdSuccessful,
  getEnquiryByID,
  saleQuotationViewModel,
  TotalReestimationAmountByPurchaseSuccessful,
  TotalRepairAmountByPurchaseSuccessful,
  miniEMIdatesSuccessful,
  getFormulaExecuter,
  getSaleCustomerPayAmount,
  getDocumentCharges,
  getMinDownPaymentAmount,
  getEMIAmount,
  getMaxSaleAmount,
  getMinSaleAmount,
  getMaxSaleAmountInMinSale,
  getPurchaseByID,
  getTotalReestimationAmountByPurchase,
  getTotalRepairAmountByPurchase,
} from "./salequotationlistSlice";

import { select } from "redux-saga/effects";
import { evaluate } from "mathjs";

export const getSaleQuotationData = (state) => state.salequotationlist;

// AUTH related methods
import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList,
} from "../../../helpers/Helper";
import { Modal } from "antd";

import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* SaleQuotationGet({ payload }) {
  var data = payload;
  var params = {};
  // for (const k in data) {
  //   if (Object.hasOwnProperty.call(data, k)) {
  //     if (k === "currentSort" || k === "sortOrder") {
  //       params.ordering = data.sortOrder + data.currentSort;
  //     } else {
  //       if (data[k] != "" && k != null && k != "no_of_pages") {
  //         params[k] = data[k];
  //       }
  //     }
  //   }
  // }
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/sale/quotation/list/", params);
    yield put(saleQuotationListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* OVFSalequotation({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/sale/ovf/quotation/list/",
      params
    );
    yield put(OVFSaleQuotationListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* saleQuotationByID({ payload }) {
  try {
    var response;
    if (payload.row.saletype == 1 || payload.row.saletype == 2) {
      response = yield call(
        getParamsList,
        "/sale/quotation/" + payload.row.id,
        {}
      );
    } else {
      response = yield call(
        getParamsList,
        "/sale/ovf/quotation/" + payload.row.id,
        {}
      );
    }
    yield put(saleQuotationByIDSuccessful({ response, type: payload.type }));
    if (payload.type == "update") {
      yield put(getEnquiryByID(response.enquiry?.id));
      yield put(getPurchaseByID(response.purchase));
      yield put(getTotalReestimationAmountByPurchase(response.purchase.id));
      yield put(getTotalRepairAmountByPurchase(response.purchase.id));

      if (parseInt(response.purchase.minimumsaleamount) == 0) {
        yield put(
          getMaxSaleAmount({
            key: "purchaserate",
            value: response.purchase.purchaserate,
          })
        );
        yield put(
          getMinSaleAmount({
            key: "purchaserate",
            value: response.purchase.purchaserate,
          })
        );
      } else {
        yield put(
          getMaxSaleAmountInMinSale({
            key: "minSaleAmount",
            value: response.purchase.minimumsaleamount,
          })
        );
      }
    }
    // yield put(isModelVisible(true));
    // yield put(saleQuotationViewModel(true));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddSaleQuotation({ payload }) {
  var data = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k != "" && payload[k] != null) {
        data[k] = payload[k];
      }
    }
  }

  try {
    if (data.id == 0) {
      var response;
      if (data.saletype == 1 || data.saletype == 2) {
        response = yield call(postAdd, "/sale/quotation/create/", data);
      } else if (data.saletype == 3) {
        response = yield call(postAdd, "/sale/ovf/quotation/create/", data);
      }
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Sale Quotation Added Successfully.!
              </p>
            </div>
          ),
        });
        if (data.saletype == 1 || data.saletype == 2) {
          yield put(
            getSalequotation({
              page: 1,
              search: "",
              page_size: 10,
              saletype: data.saletype,
            })
          );
        } else if (data.saletype == 3) {
          yield put(
            getOVFSalequotation({
              page: 1,
              search: "",
              page_size: 10,
            })
          );
        }
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Sale Quotation. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      var response;
      if (data.saletype == 1 || data.saletype == 2) {
        response = yield call(postEdit, "/sale/quotation/" + data.id, data);
      } else if (data.saletype == 3) {
        response = yield call(postEdit, "/sale/ovf/quotation/" + data.id, data);
      }
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Sale Quotation Edited Successfully.!
              </p>
            </div>
          ),
        });
        // yield put(
        //   getSalequotation({
        //     page: 1,
        //     search: "",
        //     page_size: 10,
        //     saletype: payload.saletype,
        //   })
        // );
        if (data.saletype == 1 || data.saletype == 2) {
          yield put(
            getSalequotation({
              page: 1,
              search: "",
              page_size: 10,
              saletype: data.saletype,
            })
          );
        } else if (data.saletype == 3) {
          yield put(
            getOVFSalequotation({
              page: 1,
              search: "",
              page_size: 10,
            })
          );
        }
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Sale Quotation. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* DeleteSaleQuotation({ payload }) {
  try {
    var response;
    if (payload.saletype == 1 || payload.saletype == 2) {
      response = yield call(postDelete, "/sale/quotation/" + payload.id);
    } else if (!payload.saletype) {
      response = yield call(postDelete, "/sale/ovf/quotation/" + payload.id);
    }
    // const response = yield call(postDelete, "/sale/quotation/" + personID);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Sale Quotation List has been deleted!
            </p>
          </div>
        ),
      });
      yield put(
        getSalequotation({
          page: 1,
          search: "",
          page_size: 10,
          saletype: payload.saletype,
        })
      );
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Sale Quotation List has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* FormulaExecuter({ payload }) {
  let systemFormulasList = yield select(
    (state) => state.system.systemFormulasList
  );

  try {
    var formulaObj = systemFormulasList?.find((formula) => {
      return formula?.code === payload.code;
    });

    if (formulaObj) {
      var result = evaluate(formulaObj.formula, payload.variables);

      console.log("FormulaExecuter formula:::::::", formulaObj.formula);
      console.log("FormulaExecuter variables:::::::", payload.variables);
      console.log("FormulaExecuter Code:::::::", payload.code);
      console.log("FormulaExecuter result:::::::", result);

      if (result !== "NaN") {
        yield put(
          FormulaExecuterSuccessful({
            response: result,
            formulaCode: payload.code,
          })
        );
        if (payload.code == "FINANCEAMOUNT") {
          yield put(
            getSaleCustomerPayAmount({
              key: "financeamount",
              value: result,
            })
          );
          yield put(
            getDocumentCharges({
              key: "financeamount",
              value: result,
            })
          );
        } else if (payload.code == "CUSTOMERPAYAMOUNT") {
          yield put(getMinDownPaymentAmount());
          // yield put(getEMIAmount({ key: "financeamount", value: result }));
        } else if (payload.code == "OVFFINANCEAMOUNT") {
          yield put(
            getDocumentCharges({
              key: "financeamount",
              value: result,
            })
          );
          yield put(
            getSaleCustomerPayAmount({ key: "financeamount", value: result })
          );
        }
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to calculator. Please try again!
              </p>
            </div>
          ),
        });
      }
    }

    // const response = yield call(postAdd, "/system/formulaexecuter/", payload);
    // if (response.status === 200) {
    //   yield put(
    //     FormulaExecuterSuccessful({
    //       response: response.data,
    //       formulaCode: payload.code,
    //     })
    //   );
    // } else {
    //   Modal.error({
    //     icon: (
    //       <span className="remix-icon">
    //         <RiAlertLine />
    //       </span>
    //     ),
    //     title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
    //     content: (
    //       <div>
    //         <p className="hp-p1-body hp-text-color-black-80">
    //           Sorry! Unable to calculator. Please try again!
    //         </p>
    //       </div>
    //     ),
    //   });
    // }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* EnquiryById({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/purchase/enquiry/" + payload,
      {}
    );
    yield put(enquiryByIdSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* TotalReestimationAmountByPurchase({ payload }) {
  try {
    const response = yield call(
      getList,
      "/stocktransfer/reestimationamount/total/bypurchase/" + payload,
      {}
    );
    yield put(TotalReestimationAmountByPurchaseSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* TotalRepairAmountByPurchase({ payload }) {
  try {
    const response = yield call(
      getList,
      "/stocktransfer/repairamount/total/bypurchase/" + payload,
      {}
    );
    if (response.pendingcount !== 0) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Some Repair amounts are in still Pending
            </p>
          </div>
        ),
      });
      yield put(TotalRepairAmountByPurchaseSuccessful(response));
    } else {
      yield put(TotalRepairAmountByPurchaseSuccessful(response));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

// function* getEMIdates() {
//   let saleQuotaionData = yield select((state) => state.salequotationlist.rowdata);
//   try {
//     const response = yield call(getParamsList, "/masters/mini/emidates/", {});
//     yield put(miniEMIdatesSuccessful(response));
//     var emiDates = response.map((data) => data.name);
//       // var currentDate = moment();
//       var next25Day = moment(saleQuotaionData?.date?.format('YYYY-MM-DD')).add(25, 'days');
//       var next40Day = moment(saleQuotaionData?.date?.format('YYYY-MM-DD')).add(40, "days");
//       var next40Month  = next40Day.format('MM');
//       var next25Month  = next25Day.format('MM');
//       // var datesList = []
//       if(parseInt(next25Month) == parseInt(next40Month)){
//        var datesList =  emiDates.map((date, i) => {
//           var d = moment(date + "-" +  next25Day.format('MM-YYYY'), "DD-MM-YYYY")
//           return d
//         });
//         datesList = datesList.filter(d => {
//           return d >= next25Day && d <= next40Day
//         });
//         datesList = datesList.map(d => d.format('DD-MM-YYYY'));
//         yield put(miniEMIdatesSuccessful(datesList));

//       }else{
//         var datesList =  emiDates.map((date, i )=> {
//           var d = moment(date + "-" +  next25Day.format('MM-YYYY'), "DD-MM-YYYY")
//           return d
//         });

//         var datesList2 =  emiDates.map((date, i) => {
//           var d = moment(date + "-" +  next40Day.format('MM-YYYY'), "DD-MM-YYYY")
//           return d
//         });
//         datesList = [...datesList, ...datesList2]
//         datesList = datesList.filter(d => {
//           return d >= next25Day && d <= next40Day
//         });
//         datesList = datesList.map(d => d.format('DD-MM-YYYY'));
//         yield put(miniEMIdatesSuccessful(datesList));
//       }

//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

function* getEMIdates() {
  let saleQuotaionData = yield select(
    (state) => state.salequotationlist.rowdata
  );
  try {
    const response = yield call(getParamsList, "/masters/mini/emidates/", {});
    yield put(miniEMIdatesSuccessful(response));
    var emiDates = response.map((data) => data.name);
    // var currentDate = moment();
    var next25Day = moment(saleQuotaionData?.date?.format("YYYY-MM-DD")).add(
      19,
      "days"
    );
    var next45Day = moment(saleQuotaionData?.date?.format("YYYY-MM-DD")).add(
      39,
      "days"
    );
    var next45Month = next45Day.format("MM");
    var next25Month = next25Day.format("MM");
    // var datesList = []
    if (parseInt(next25Month) == parseInt(next45Month)) {
      var datesList = emiDates.map((date, i) => {
        var d = moment(date + "-" + next25Day.format("MM-YYYY"), "DD-MM-YYYY");
        return d;
      });
      datesList = datesList.filter((d) => {
        return d >= next25Day && d <= next45Day;
      });
      datesList = datesList.map((d) => d.format("DD-MM-YYYY"));
      yield put(miniEMIdatesSuccessful(datesList));
    } else {
      var datesList = emiDates.map((date, i) => {
        var d = moment(date + "-" + next25Day.format("MM-YYYY"), "DD-MM-YYYY");
        return d;
      });
      var datesList2 = emiDates.map((date, i) => {
        var d = moment(date + "-" + next45Day.format("MM-YYYY"), "DD-MM-YYYY");
        return d;
      });
      datesList = [...datesList, ...datesList2];
      datesList = datesList.filter((d) => {
        return d >= next25Day && d <= next45Day;
      });
      datesList = datesList.map((d) => d.format("DD-MM-YYYY"));
      yield put(miniEMIdatesSuccessful(datesList));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

// Formulas

function* MaxSaleAmount({ payload }) {
  let saleQuotaionData = yield select(
    (state) => state.salequotationlist.rowdata
  );
  try {
    var variablesData = {
      PURCHASEAMOUNT:
        saleQuotaionData.purchaserate == null
          ? 0
          : parseInt(saleQuotaionData.purchaserate),
    };
    if (payload.key == "purchaserate") {
      variablesData.PURCHASEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var maxSaleAmountObj = {
      code: "MAXSALEAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(maxSaleAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MinSaleAmount({ payload }) {
  let saleQuotaionData = yield select(
    (state) => state.salequotationlist.rowdata
  );
  try {
    var variablesData = {
      PURCHASEAMOUNT:
        saleQuotaionData.purchaserate == null
          ? 0
          : parseInt(saleQuotaionData.purchaserate),
    };
    if (payload.key == "purchaserate") {
      variablesData.PURCHASEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var minSaleAmountObj = {
      code: "MINSALEAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(minSaleAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MaxSaleAmountInMinSale({ payload }) {
  let minSaleAmount = yield select((state) => state.counterSales.minSaleAmount);
  try {
    var variablesData = {
      MINSALEAMOUNT: minSaleAmount == 0 ? 0 : parseInt(minSaleAmount),
    };
    if (payload.key == "minSaleAmount") {
      variablesData.MINSALEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var minSaleAmountInMaxSaleObj = {
      code: "MAXSALEAMOUNT_MINISALEAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(minSaleAmountInMaxSaleObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MinDownPaymentAmount() {
  let saleQuotaionData = yield select(
    (state) => state.salequotationlist.rowdata
  );
  try {
    var variablesData = {
      SALEAMOUNT:
        saleQuotaionData.saleamount == undefined ||
        saleQuotaionData.saleamount == null
          ? 0
          : parseInt(saleQuotaionData.saleamount),
    };
    var minSaleAmountObj = {
      code: "MINDOWNPAYMENTAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(minSaleAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* GSTAmount({ payload }) {
  let saleQuotaionData = yield select(
    (state) => state.salequotationlist.rowdata
  );
  try {
    var variablesData = {
      SALEAMOUNT:
        saleQuotaionData.saleamount == null
          ? 0
          : parseInt(saleQuotaionData.saleamount),
      PURCHASEAMOUNT:
        saleQuotaionData.purchaserate == null
          ? 0
          : parseInt(saleQuotaionData.purchaserate),
      REPAIRAMOUNT:
        saleQuotaionData.repair_amount == null
          ? 0
          : parseInt(saleQuotaionData.repair_amount),
      REESTIMATIONAMOUNT:
        saleQuotaionData.re_amount == null
          ? 0
          : parseInt(saleQuotaionData.re_amount),
      DOCUMENTCHARGES:
        saleQuotaionData.saletype == 1
          ? 0
          : saleQuotaionData.customerdocumentationcharges == null
          ? 0
          : parseInt(saleQuotaionData.customerdocumentationcharges),
      CUSTOMERGSTAMOUNT:
        saleQuotaionData.gst_customer_amount == null
          ? 0
          : parseInt(saleQuotaionData.gst_customer_amount),
    };

    if (payload.key == "saleamount") {
      variablesData.SALEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "purchaserate") {
      variablesData.PURCHASEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "repair_amount") {
      variablesData.REPAIRAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "re_amount") {
      variablesData.REESTIMATIONAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "gst_customer_amount") {
      variablesData.CUSTOMERGSTAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "customerdocumentationcharges") {
      variablesData.DOCUMENTCHARGES = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var gstAmountObj = {
      code: "GSTAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(gstAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* SaleProfit({ payload }) {
  let saleQuotaionData = yield select(
    (state) => state.salequotationlist.rowdata
  );
  try {
    var variablesData = {
      SALEAMOUNT:
        saleQuotaionData.saleamount == null
          ? 0
          : parseInt(saleQuotaionData.saleamount),
      PURCHASEAMOUNT:
        saleQuotaionData.purchaserate == null
          ? 0
          : parseInt(saleQuotaionData.purchaserate),
      REPAIRAMOUNT:
        saleQuotaionData.repair_amount == null
          ? 0
          : parseInt(saleQuotaionData.repair_amount),
      REESTIMATIONAMOUNT:
        saleQuotaionData.re_amount == null
          ? 0
          : parseInt(saleQuotaionData.re_amount),
    };

    if (payload.key == "saleamount") {
      variablesData.SALEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "purchaserate") {
      variablesData.PURCHASEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "repair_amount") {
      variablesData.REPAIRAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "re_amount") {
      variablesData.REESTIMATIONAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var saleProfitObj = {
      code: "SALEPROFIT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(saleProfitObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* FinanceAmount({ payload }) {
  let saleQuotaionData = yield select(
    (state) => state.salequotationlist.rowdata
  );
  try {
    var variablesData = {
      SALEAMOUNT:
        saleQuotaionData.saleamount == null
          ? 0
          : parseInt(saleQuotaionData.saleamount),
      DOWNPAYMENTAMOUNT:
        saleQuotaionData.downpaymentamount == null
          ? 0
          : parseInt(saleQuotaionData.downpaymentamount),
    };
    if (payload.key == "saleamount") {
      variablesData.SALEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "downpaymentamount") {
      variablesData.DOWNPAYMENTAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var financeAmountObj = {
      code: "FINANCEAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(financeAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* EMIAmount({ payload }) {
  console.log("EMIAmount", payload);
  let saleQuotaionData = yield select(
    (state) => state.salequotationlist.rowdata
  );

  try {
    var variablesData = {
      CUSTOMERPAYAMOUNT:
        saleQuotaionData.total == null ? 0 : parseInt(saleQuotaionData.total),
      INTERESTRATE:
        saleQuotaionData.interestpercentage == null ||
        saleQuotaionData.interestpercentage == ""
          ? 0
          : parseInt(saleQuotaionData.interestpercentage),
      TENURES:
        saleQuotaionData.tenures == null || saleQuotaionData.tenures == ""
          ? 0
          : parseInt(saleQuotaionData.tenures),
    };
    if (payload.key == "interestpercentage") {
      variablesData.INTERESTRATE = parseInt(
        payload.value == "" || payload.value == null ? 0 : payload.value
      );
    } else if (payload.key == "tenures") {
      variablesData.TENURES = parseInt(
        payload.value == "" || payload.value == null ? 0 : payload.value
      );
    }
    var emiAmountObj = {
      code: "EMIAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(emiAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* SaleCustomerPayAmount({ payload }) {
  let saleQuotaionData = yield select(
    (state) => state.salequotationlist.rowdata
  );
  try {
    var variablesData = {
      SALEAMOUNT:
        saleQuotaionData.saletype == 1
          ? saleQuotaionData.saleamount == null
            ? 0
            : parseInt(saleQuotaionData.saleamount)
          : saleQuotaionData.financeamount == null
          ? 0
          : parseInt(saleQuotaionData.financeamount),
      RTOAMOUNT:
        saleQuotaionData.rtaamount == null
          ? 0
          : parseInt(saleQuotaionData.rtaamount),
      INSURANCECHARGES:
        saleQuotaionData.insuranceamount == null
          ? 0
          : parseInt(saleQuotaionData.insuranceamount),
      CONSULTANCYCHARGES:
        saleQuotaionData.consultancycharges == null
          ? 0
          : parseInt(saleQuotaionData.consultancycharges),
      CUSTOMERGSTAMOUNT:
        saleQuotaionData.gst_customer_amount == null
          ? 0
          : parseInt(saleQuotaionData.gst_customer_amount),
      CUSTOMERDOCUMENTAMOUNT:
        saleQuotaionData.customerdocumentationcharges == null
          ? 0
          : parseInt(saleQuotaionData.customerdocumentationcharges),
      ECHALLANAMOUNT: 0,
      DISCOUNTAMOUNT:
        saleQuotaionData.discountamount == null
          ? 0
          : parseInt(saleQuotaionData.discountamount),
    };

    switch (payload.key) {
      case "saleamount":
        variablesData.SALEAMOUNT = parseInt(
          payload.value == "" ? 0 : payload.value
        );
        break;
      case "financeamount":
        variablesData.SALEAMOUNT = parseInt(
          payload.value == "" ? 0 : payload.value
        );
        break;
      case "rtaamount":
        variablesData.RTOAMOUNT = parseInt(
          payload.value == "" ? 0 : payload.value
        );
        break;
      case "insuranceamount":
        variablesData.INSURANCECHARGES = parseInt(
          payload.value == "" ? 0 : payload.value
        );
        break;
      case "consultancycharges":
        variablesData.CONSULTANCYCHARGES = parseInt(
          payload.value == "" ? 0 : payload.value
        );

        break;
      case "gst_customer_amount":
        variablesData.CUSTOMERGSTAMOUNT = parseInt(
          payload.value == "" ? 0 : payload.value
        );
        break;
      case "customerdocumentationcharges":
        variablesData.CUSTOMERDOCUMENTAMOUNT = parseInt(
          payload.value == "" ? 0 : payload.value
        );
        break;
      case "echallan":
        variablesData.ECHALLANAMOUNT = parseInt(
          payload.value == "" ? 0 : payload.value
        );
        break;
      case "discountamount":
        variablesData.DISCOUNTAMOUNT = parseInt(
          payload.value == "" ? 0 : payload.value
        );
        break;
      default:
        break;
    }

    var directSaleCustomerPayObj = {
      code: "CUSTOMERPAYAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(directSaleCustomerPayObj));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* DocumentCharges({ payload }) {
  let saleQuotaionData = yield select(
    (state) => state.salequotationlist.rowdata
  );
  try {
    var variablesData = {
      FINANCEAMOUNT:
        saleQuotaionData.profit == null ? 0 : parseInt(saleQuotaionData.profit),
    };
    if (payload.key == "financeamount") {
      variablesData.FINANCEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var documentChargesObj = {
      code: "DOCUMENTCHARGES",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(documentChargesObj));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MaxFinanceAmount({ payload }) {
  let saleQuotaionData = yield select(
    (state) => state.salequotationlist.rowdata
  );
  try {
    var variablesData = {
      ESTIMATIONAMOUNT:
        saleQuotaionData.estimationamount == null
          ? 0
          : parseInt(saleQuotaionData.estimationamount),
    };
    if (payload.key == "estimationamount") {
      variablesData.ESTIMATIONAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var maxFinanceAmountObj = {
      code: "MAXFINANCEAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(maxFinanceAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MaxOVFDPDueAMount({ payload }) {
  let saleQuotaionData = yield select(
    (state) => state.salequotationlist.rowdata
  );
  try {
    var variablesData = {
      CUSTOMERAMOUNT:
        saleQuotaionData.customeramount == null
          ? 0
          : parseInt(saleQuotaionData.customeramount),
    };
    if (payload.key == "customeramount") {
      variablesData.CUSTOMERAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var maxOVFDPDueAmountObj = {
      code: "MAXOVFDPDUEAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(maxOVFDPDueAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* OVFFinanceAmount({ payload }) {
  let saleQuotaionData = yield select(
    (state) => state.salequotationlist.rowdata
  );
  try {
    var variablesData = {
      CUSTOMERAMOUNT:
        saleQuotaionData.customeramount == null
          ? 0
          : parseInt(saleQuotaionData.customeramount),
      OVFDPDUEAMOUNT:
        saleQuotaionData.downpayment_due_amount == null
          ? 0
          : parseInt(saleQuotaionData.downpayment_due_amount),
    };
    if (payload.key == "customeramount") {
      variablesData.CUSTOMERAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    } else if (payload.key == "downpayment_due_amount") {
      variablesData.OVFDPDUEAMOUNT = parseInt(
        payload.value == "" ? 0 : payload.value
      );
    }
    var OVFFinanceAmountObj = {
      code: "OVFFINANCEAMOUNT",
      variables: variablesData,
    };
    yield put(getFormulaExecuter(OVFFinanceAmountObj));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchGetSalequotation() {
  yield takeEvery("salequotation/getSalequotation", SaleQuotationGet);
}
export function* watchGetOVFSalequotation() {
  yield takeEvery("salequotation/getOVFSalequotation", OVFSalequotation);
}
export function* watchGetSaleQuotationByID() {
  yield takeEvery("salequotation/getSaleQuotationByID", saleQuotationByID);
}
export function* watchSaleQuotationAdd() {
  yield takeEvery("salequotation/salequotationAdd", AddSaleQuotation);
}
export function* watchSaleQuotationDelete() {
  yield takeEvery("salequotation/saleQuotationDelete", DeleteSaleQuotation);
}
export function* watchGetFormulaExecuter() {
  yield takeEvery("salequotation/getFormulaExecuter", FormulaExecuter);
}
export function* watchGetEnquiryByID() {
  yield takeEvery("salequotation/getEnquiryByID", EnquiryById);
}
export function* watchGetTotalReestimationAmountByPurchase() {
  yield takeEvery(
    "salequotation/getTotalReestimationAmountByPurchase",
    TotalReestimationAmountByPurchase
  );
}
export function* watchGetTotalRepairAmountByPurchase() {
  yield takeEvery(
    "salequotation/getTotalRepairAmountByPurchase",
    TotalRepairAmountByPurchase
  );
}
export function* watchGetMiniEMIdates() {
  yield takeEvery("salequotation/getMiniEMIdates", getEMIdates);
}
export function* watchGetMaxSaleAmount() {
  yield takeEvery("salequotation/getMaxSaleAmount", MaxSaleAmount);
}
export function* watchGetMinSaleAmount() {
  yield takeEvery("salequotation/getMinSaleAmount", MinSaleAmount);
}
export function* watchGetMaxSaleAmountInMinSale() {
  yield takeEvery(
    "salequotation/getMaxSaleAmountInMinSale",
    MaxSaleAmountInMinSale
  );
}
export function* watchGetMinDownPaymentAmount() {
  yield takeEvery(
    "salequotation/getMinDownPaymentAmount",
    MinDownPaymentAmount
  );
}
export function* watchGetGSTAmount() {
  yield takeEvery("salequotation/getGSTAmount", GSTAmount);
}
export function* watchGetSaleProfit() {
  yield takeEvery("salequotation/getSaleProfit", SaleProfit);
}
export function* watchGetFinanceAmount() {
  yield takeEvery("salequotation/getFinanceAmount", FinanceAmount);
}
export function* watchGetEMIAmount() {
  yield takeEvery("salequotation/getEMIAmount", EMIAmount);
}
export function* watchGetSaleCustomerPayAmount() {
  yield takeEvery(
    "salequotation/getSaleCustomerPayAmount",
    SaleCustomerPayAmount
  );
}
export function* watchGetDocumentCharges() {
  yield takeEvery("salequotation/getDocumentCharges", DocumentCharges);
}
export function* watchGetMaxFinanceAmount() {
  yield takeEvery("salequotation/getMaxFinanceAmount", MaxFinanceAmount);
}
export function* watchGetMaxOVFDPDueAMount() {
  yield takeEvery("salequotation/getMaxOVFDPDueAMount", MaxOVFDPDueAMount);
}
export function* watchGetOVFFinanceAmount() {
  yield takeEvery("salequotation/getOVFFinanceAmount", OVFFinanceAmount);
}
function* SalequotationlistSaga() {
  yield all([
    fork(watchGetSalequotation),
    fork(watchGetOVFSalequotation),
    fork(watchGetSaleQuotationByID),
    fork(watchSaleQuotationAdd),
    fork(watchSaleQuotationDelete),
    fork(watchGetFormulaExecuter),
    fork(watchGetEnquiryByID),
    fork(watchGetTotalReestimationAmountByPurchase),
    fork(watchGetTotalRepairAmountByPurchase),
    fork(watchGetMiniEMIdates),
    fork(watchGetMaxSaleAmount),
    fork(watchGetMinSaleAmount),
    fork(watchGetMaxSaleAmountInMinSale),
    fork(watchGetMinDownPaymentAmount),
    fork(watchGetGSTAmount),
    fork(watchGetSaleProfit),
    fork(watchGetFinanceAmount),
    fork(watchGetEMIAmount),
    fork(watchGetSaleCustomerPayAmount),
    fork(watchGetDocumentCharges),
    fork(watchGetMaxFinanceAmount),
    fork(watchGetMaxOVFDPDueAMount),
    fork(watchGetOVFFinanceAmount),
  ]);
}

export default SalequotationlistSaga;
