import { createSlice } from "@reduxjs/toolkit";

export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    userData: {},
    adminData: {},
    activityLogList: [],
    userDeviceList: [],
    passwordData: {},
    apiError: {},
    listCount: 0,
    activityLogParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    UserDeviceParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getUser: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getUserSuccessful: (state, action) => {
      return {
        ...state,
        userData: action.payload,
        loading: false,
      };
    },
    changePasswordModalShow: (state, action) => {
      return {
        ...state,
        loading: false,
        model: action.payload,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        passwordData: {
          ...state.passwordData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    ChangePasswordSubmit: (state, action) => {
      return {
        ...state,
        loading: true,
        apiError: {},
      };
    },
    getActivityLog: (state) => {
      return {
        ...state,
        loading: true,
        activityLogList: [],
      };
    },
    getActivityLogSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.activityLogParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        // activityLogList: action.payload.response.results,
        activityLogList: [
          ...state.activityLogList,
          ...action.payload.response.results,
        ],
        loading: false,
        activityLogParams: {
          ...state.activityLogParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetActivityLogParams: (state, action) => {
      return {
        ...state,
        activityLogParams: action.payload,
      };
    },

    getUserDeviceList: (state) => {
      return {
        ...state,
        loading: true,
        userDeviceList: [],
      };
    },
    getUserDeviceListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.UserDeviceParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        // userDeviceList: action.payload.response.results,
        userDeviceList: [
          ...state.userDeviceList,
          ...action.payload.response.results,
        ],
        loading: false,
        UserDeviceParams: {
          ...state.UserDeviceParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetDeviceParams: (state, action) => {
      return {
        ...state,
        UserDeviceParams: action.payload,
      };
    },

    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        apiError: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getUser,
  getUserSuccessful,
  changePasswordModalShow,
  InputChangeValue,
  ChangePasswordSubmit,
  getActivityLog,
  getActivityLogSuccessful,
  SetActivityLogParams,
  getUserDeviceList,
  getUserDeviceListSuccessful,
  SetDeviceParams,
  apiError,
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
