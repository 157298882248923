import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const enquirySlice = createSlice({
  name: "enquiry",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    enquirylist: [],
    model: false,
    viewModel: false,
    drawer: false,
    listCount: 0,
    error_msg: null,
    enquiryData: {},
    enquiryViewData: {},
    enquiryParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    isFilterActive: false,
  },
  reducers: {
    getEnquirys: (state, action) => {
      if (state.enquiryParams?.page == 1) {
        return {
          ...state,
          loading: true,
          enquirylist: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    enquiryListSuccessful: (state, action) => {
      var response = action.payload.response;
      var list = [];
      if (state.enquiryParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }

      var noofpages = Math.ceil(
        action.payload.count / state.enquiryParams?.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        enquirylist: list,
        loading: false,
        enquiryParams: {
          ...state.enquiryParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetEnquiryParams: (state, action) => {
      return {
        ...state,
        enquiryParams: action.payload,
      };
    },
    clearEnquiryList: (state) => {
      return {
        ...state,
        loading: false,
        enquirylist: [],
      };
    },
    enquiryAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    enquiryAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        enquiryData: {},
      };
    },

    enquiryDelete: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    InputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "enquirytype":
          return {
            ...state,
            enquiryData: {
              [action.payload.key]: action.payload.value,
            },
          };
        case "brand_id":
          return {
            ...state,
            enquiryData: {
              ...state.enquiryData,
              [action.payload.key]: action.payload.value,
              brandfamily_id: null,
              model_id: null,
              version_id: null,
            },
          };

        case "brandfamily_id":
          return {
            ...state,
            enquiryData: {
              ...state.enquiryData,
              [action.payload.key]: action.payload.value,
              model_id: null,
              version_id: null,
            },
          };
        case "model_id":
          return {
            ...state,
            enquiryData: {
              ...state.enquiryData,
              [action.payload.key]: action.payload.value,
              version_id: null,
            },
          };
        case "counter_id":
          return {
            ...state,
            enquiryData: {
              ...state.enquiryData,
              [action.payload.key]: action.payload.value,
              vehicle_id: null,
              brand_id: null,
              brandfamily_id: null,
              model_id: null,
              version_id: null,
            },
          };

        default:
          return {
            ...state,
            enquiryData: {
              ...state.enquiryData,
              [action.payload.key]: action.payload.value,
            },
          };
      }
    },
    isModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          model: action.payload,
        };
      } else {
        return {
          ...state,
          model: action.payload,
          enquiryData: {},
          enquiryViewData: {},
        };
      }
    },
    
    FilterInputChangeValue: (state, action) => {
      // if (action.payload.key == "state") {
      //   return {
      //     ...state,
      //     enquiryParams: {

      //       ...state.enquiryParams,
      //       [action.payload.key]: action.payload.value,
      //       district: "",
      //       city: "",
      //       area: "",
      //     },
      //   };
      // } else if (action.payload.key == "district") {
      //   return {
      //     ...state,
      //     enquiryParams: {
      //       ...state.enquiryParams,
      //       [action.payload.key]: action.payload.value,
      //       city: "",
      //       area: "",
      //     },
      //   };
      // } else if (action.payload.key == "city") {
      //   return {
      //     ...state,
      //     enquiryParams: {
      //       ...state.enquiryParams,
      //       [action.payload.key]: action.payload.value,
      //       area: "",
      //     },
      //   };
      // } else {
      // }
      return {
        ...state,
        enquiryParams: {
          ...state.enquiryParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    getEnquiryByID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getEnquiryByIDSuccessful: (state, action) => {
      return {
        ...state,
        enquiryData: {
          id: action.payload.response.id,
          enquirytype: action.payload.response.enquirytype,
          customername: action.payload.response.customername,
          customermobile: action.payload.response.customermobile,
          counter_id: action.payload.response.counter?.id,
          occupation_id: action.payload.response.occupation?.id,
          vehiclenumber: action.payload.response.vehiclenumber,
          vehiclerelated: action.payload.response.vehiclerelated,
          vehicle_id: action.payload.response.vehicle?.id,
          brand_id: action.payload.response.version?.brand?.id,
          brandfamily_id: action.payload.response.version?.brandfamily?.id,
          model_id: action.payload.response.version?.model?.id,
          version_id: action.payload.response.version?.id,
          color: action.payload.response.color,
          vehiclereading: action.payload.response.vehiclereading,
          planneddays: action.payload.response.planneddays,
          year: moment(action.payload?.response.year, "YYYY"),
          requiredamount: action.payload.response.requiredamount,
          budget: action.payload.response.budget,
          description: action.payload.response.description,
        },
        model: action.payload.type == "update" ? true : false,
        viewModel: action.payload.type == "view" ? true : false,
        enquiryViewData: action.payload.response,
        loading: false,
      };
    },
    enquiryViewModel: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          viewModel: action.payload,
        };
      } else {
        return {
          ...state,
          enquiryViewData: {},
          enquiryData: {},
          viewModel: action.payload,
        };
      }
    },
    getVehicleByID: (state, action) => {
      return {
        ...state,

        enquiryData: {
          ...state.enquiryData,
          vehicle_id: action.payload?.vehicle?.id,
          vehiclenumber: action.payload?.vehicle?.name,
          brand_id: action.payload.vehicle?.version?.brand?.id,
          brandfamily_id: action.payload.vehicle?.version?.brandfamily?.id,
          model_id: action.payload.vehicle?.version?.model?.id,
          version_id: action.payload.vehicle?.version?.id,
        },
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getEnquirys,
  enquiryListSuccessful,
  enquiryAdd,
  enquiryAddSuccessful,
  enquiryDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetEnquiryParams,
  isDrawerVisible,
  FilterInputChangeValue,
  getEnquiryByID,
  getEnquiryByIDSuccessful,
  enquiryViewModel,
  getVehicleByID,
  clearEnquiryList,
} = enquirySlice.actions;

export default enquirySlice.reducer;
