import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const assetRequestSlice = createSlice({
  name: "assetRequest",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    imageLooading: false,
    model: false,
    drawer: false,
    error_msg: null,
    listCount: 0,
    assetRequestList: [],
    rowdata: {},
    assetViewData: {},
    assetRequestParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    viewModel: false,
    feedBackModel: false,
    feedBackData: {},

    approveCount: 0,
    approveModel: false,
    approveDrawer: false,
    assetApproveList: [],
    approveData: {},
    assetApproveParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    assingModel: false,
    assetAssignData: {},
    rejectModel: false,
  },
  reducers: {
    getAssetRequestList: (state, action) => {
      if (state.assetRequestParams?.page == 1) {
        return {
          ...state,
          loading: true,
          assetRequestList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    assetRequestListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.assetRequestParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.assetRequestParams?.page_size
      );
      return {
        ...state,
        assetRequestList: list,
        listCount: action.payload.count,
        loading: false,
        assetRequestParams: {
          ...state.assetRequestParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetAssetRequestParams: (state, action) => {
      return {
        ...state,
        assetRequestParams: action.payload,
      };
    },
    assetRequestAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
          assetViewData: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
          assetViewData: {},
        };
      }
    },
    isViewModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          viewModel: action.payload,
          assetViewData: {},
        };
      } else {
        return {
          ...state,
          viewModel: action.payload,
        };
      }
    },
    assetRequestDelete: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getAssetRequestByID: (state) => {
      return {
        ...state,
        loading: true,
        rowdata: {}
      };
    },
    assetRequestByIDSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        rowdata: {
          id: action.payload.response.id,
          typeofrequest: action.payload.response.typeofrequest,
          priority: action.payload.response.priority,
          asset_id: action.payload.response.asset?.id,
          counter_id: action.payload.response.counter?.id,
          description: action.payload.response.description,
          asset_image: action.payload.response.asset_image,
          asset_image_id: action.payload.response.asset_image?.id,
        },
        model: action.payload.type == "update" ? true : false,
        viewModel: action.payload.type == "view" ? true : false,
        assetViewData: action.payload.response
      };
    },
    getAssetImageUpload: (state, action) => {
      return {
        ...state,
        loading: true,
        imageLooading: true,
        rowdata: {
          ...state.rowdata,
          [action.payload.imageType]: null,
        },
      };
    },
    assetImageUploadSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        imageLooading: false,
        rowdata: {
          ...state.rowdata,
          [action.payload.ImageType]: action.payload.response.data,
          [action.payload.ImageType + "_id"]: action.payload.response.data.id,
        },
      };
    },

    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
          assetViewData: {},
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        assetRequestParams: {
          ...state.assetRequestParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    feedBackModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          feedBackModel: action.payload,
          feedBackData: {},
        };
      } else {
        return {
          ...state,
          feedBackModel: action.payload,
        };
      }
    },
    feedBackInputChangeValue: (state, action) => {
      return {
        ...state,
        feedBackData: {
          ...state.feedBackData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    addFeedBack: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    feedBackSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        viewModel: false,
        feedBackModel: false,
      };
    },
    clearAssetList: (state, action) => {
      return{
        ...state,
        loading: false,
        rowdata: {},
      }
    },



    // Asset Approval API's
    getAssetApproveList: (state, action) => {
      if (state.assetApproveParams?.page == 1) {
        return {
          ...state,
          loading: true,
          assetApproveList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    assetApproveListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.assetApproveParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.assetApproveParams?.page_size
      );
      return {
        ...state,
        assetApproveList: list,
        approveCount: action.payload.count,
        loading: false,
        assetApproveParams: {
          ...state.assetApproveParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetAssetApproveParams: (state, action) => {
      return {
        ...state,
        assetApproveParams: action.payload,
      };
    },
    isApproveModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          approveModel: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          approveModel: action.payload,
        };
      }
    },
    getAssetApproveByID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    assetApproveByIDSuccessful: (state, action) => {
      return {
        ...state,
        approveModel: true,
        loading: false,
        approveData: {
          ...action.payload,
          typeofrequest: action.payload.typeofrequest,
          priority: action.payload.priority,
          asset_id: action.payload.asset?.id,
          counter_id: action.payload.counter?.id,
          description: action.payload.description,
        },
      };
    },
    assetApprove: (state, action) => {
      return{
        ...state,
        loading: true,
      }
    },
    assetApproveSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        approveModel: false,
        assingModel: false,
        rejectModel: false,
      };
    },
    isApproveDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          approveDrawer: action.payload,
          approveData: {},
        };
      } else {
        return {
          ...state,
          approveDrawer: action.payload,
        };
      }
    },
    ApproveFilterInputChangeValue: (state, action) => {
      return {
        ...state,
        assetApproveParams: {
          ...state.assetApproveParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    AssignModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          assingModel: action.payload,
          assetAssignData: {},
        };
      } else {
        return {
          ...state,
          assingModel: action.payload,
        };
      }
    },
    approveInputChangeValue: (state, action) => {
      return {
        ...state,
        assetAssignData: {
          ...state.assetAssignData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isRejectModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          rejectModel: action.payload,
          assetAssignData: {},
        };
      } else {
        return {
          ...state,
          rejectModel: action.payload,
        };
      }
    },
    rejectInputChangeValue: (state, action) => {
      return {
        ...state,
        assetAssignData: {
          ...state.assetAssignData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getAssetRequestList,
  assetRequestListSuccessful,
  SetAssetRequestParams,
  assetRequestAdd,
  getAssetRequestByID,
  assetRequestByIDSuccessful,
  assetRequestDelete,
  InputChangeValue,
  isModelVisible,
  isDrawerVisible,
  FilterInputChangeValue,
  getAssetImageUpload,
  assetImageUploadSuccessful,
  isViewModelVisible,
  feedBackModelVisible,
  feedBackInputChangeValue,
  addFeedBack,
  feedBackSuccessful,
  clearAssetList,



  // Asset Approval API's
  getAssetApproveList,
  assetApproveListSuccessful,
  SetAssetApproveParams,
  isApproveModelVisible,
  getAssetApproveByID,
  assetApproveByIDSuccessful,
  assetApprove,
  isApproveDrawerVisible,
  ApproveFilterInputChangeValue,
  AssignModelVisible,
  approveInputChangeValue,
  assetApproveSuccessful,
  isRejectModelVisible,
  rejectInputChangeValue,
  apiError,
} = assetRequestSlice.actions;

export default assetRequestSlice.reducer;
