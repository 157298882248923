import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const newInsuranceSlice = createSlice({
  name: "newInsurance",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    model: false, // Add Insurer
    insurerAddModel: false, // Update Insurer
    viewInsuranceModel: false,
    drawer: false,
    imageLooading: false,
    insurerImgLooading: false,
    apiError: {},
    vehicleVersion: {},
    versionInsurance: {},
    insuranceData: {},
    insuranceUpdateData: {},
    agentInsurerData: {},
    newInsuranceDetails: {},
    listCount: 0,
    insurerListCount: 0,
    newInsuranceList: [],
    insurerList: [],
    insuranceparams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    insurerparams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getInsuranceList: (state, action) => {
      if (state.insuranceparams.page == 1) {
        return {
          ...state,
          loading: true,
          newInsuranceList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    insuranceListSuccessful: (state, action) => {
      var response = action.payload.response;
      var list = [];
      if (state.insuranceparams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.newInsuranceList, ...response.results];
      }

      var noofpages = Math.ceil(
        response.count / state.insuranceparams?.page_size
      );
      return {
        ...state,
        listCount: response.count,
        newInsuranceList: list,
        // newInsuranceList: action.payload.response.results,
        loading: false,
        insuranceparams: {
          ...state.insuranceparams,
          no_of_pages: noofpages,
        },
      };
    },
    clearInsuranceList: (state, action) => {
      return {
        ...state,
        loading: true,
        newInsuranceList: [],
      };
    },

    clearInsuranceParams: (state, action) => {
      return {
        ...state,
        loading: true,
        insuranceparams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
          currentSort: "code",
          sortOrder: "-",
        },
      };
    },
    setInsuranceParams: (state, action) => {
      return {
        ...state,
        insuranceparams: action.payload,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        insuranceData: {
          ...state.insuranceData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          insuranceData: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    getInsuranceImageUpload: (state, action) => {
      return {
        ...state,
        loading: true,
        imageLooading: true,
        insuranceData: {
          ...state.insuranceData,
          [action.payload.imageType]: null,
        },
      };
    },
    insuranceImageUploadSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        imageLooading: false,
        insuranceData: {
          ...state.insuranceData,
          [action.payload.ImageType]: action.payload.response.data,
          [action.payload.ImageType + "_id"]: action.payload.response.data.id,
        },
      };
    },
    addNewInsurance: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        insuranceparams: {
          ...state.insuranceparams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isInsuranceViewModelVisible: (state, action) => {
      if (action.payload == true) {
        return {
          ...state,
          viewInsuranceModel: action.payload,
        };
      } else {
        return {
          ...state,
          viewInsuranceModel: action.payload,
          insuranceData: {},
          insuranceUpdateData: {},
        };
      }
    },

    getInsuranceById: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    insuranceByIdSuccessful: (state, action) => {
      return {
        ...state,
        // model: true,
        loading: false,
        insuranceData: {
          ...action.payload,
          id: action.payload.id,
          customername: action.payload.customername,
          customermobile: action.payload.customermobile,
          aadharno: action.payload.aadharno,
          customeramount: action.payload.customeramount,
          vehicle_id: action.payload.vehicle?.id,
          // vehicleVersion:action.payload.vehicleVersion,
          counter_id: action.payload.counter?.id,
          requestedamount: action.payload.requestedamount,
          insurancecategory: action.payload.insurancecategory,
        },
        insuranceUpdateData: {
          id: action.payload.id,
          amount: action.payload.amount,
          expdate:
            action.payload?.expdate == null
              ? ""
              : moment(action.payload?.expdate, "DD-MM-YYYY"),
          number: action.payload.number,
          insurancecompany_id: action.payload.insurancecompany?.id,
          insuranceimage: action.payload.insuranceimage,
          insuranceimage_id: action.payload.insuranceimage?.id,
        },
      };
    },
    getByVehicleVersion: (state, action) => {
      return {
        ...state,
        loading: false,
        vehicleVersion: action.payload,
      };
    },
    getVersionInsuranceMaster: (state, action) => {
      return {
        ...state,
        loading: true,
        versionInsurance: action.payload,
      };
    },
    getVersionInsuranceMasterSuccessful: (state, action) => {
      return {
        ...state,
        versionInsurance: action.payload,
        loading: false,
      };
    },
    // Approve Insurance

    insuranceApprove: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    //  Insurer

    getInsurerList: (state, action) => {
      if (state.insurerparams.page == 1) {
        return {
          ...state,
          loading: true,
          insurerListCount: 0,
          insurerList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    insurerListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.insurerparams.page_size
      );
      console.log(action.payload);
      return {
        ...state,
        insurerListCount: action.payload.response.count,
        insurerList: action.payload.response.results,
        loading: false,
        insurerparams: {
          ...state.insurerparams,
          no_of_pages: noofpages,
        },
      };
    },
    clearInsuranceData: (state, action) => {
      return {
        ...state,
        insuranceData: {}
      };
    },
    clearInsurerList: (state, action) => {
      return {
        ...state,
        loading: false,
        insurerList: [],
        insuranceData: {}
      };
    },
    setInsurerParams: (state, action) => {
      return {
        ...state,
        insurerparams: action.payload,
      };
    },
    // isInsurerViewModelVisible: (state, action) => {
    //   return {
    //     ...state,
    //     insurerModel: action.payload,
    //   };
    // },
    isInsurerAddModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          insurerAddModel: action.payload,
          insuranceUpdateData: {},
        };
      } else {
        return {
          ...state,
          insurerAddModel: action.payload,
        };
      }
    },
    addInsurerData: (state, action) => {
      return {
        ...state,
        loading: true,
        // model: true,
        insuranceUpdateData: {
          id: action.payload.id,
          amount: action.payload.amount,
          expdate: moment(action.payload?.expdate, "YYYY-MM-DD"),
          number: action.payload.number,
          insurancecompany_id: action.payload.insurancecompany?.id,
        },
      };
    },

    addInsurerDataSuccessful: (state, action) => {
      console.log("insurerEditSuccessful", action.payload);
      return {
        ...state,
        loading: false,
        insuranceUpdateData: {},
      };
    },
    InsurerInputChangeValue: (state, action) => {
      return {
        ...state,
        insuranceUpdateData: {
          ...state.insuranceUpdateData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    getInsurerImageUpload: (state, action) => {
      return {
        ...state,
        loading: true,
        insurerImgLooading: true,
        insuranceUpdateData: {
          ...state.insuranceUpdateData,
          [action.payload.imageType]: null,
        },
      };
    },
    insurerImageUploadSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        insurerImgLooading: false,
        insuranceUpdateData: {
          ...state.insuranceUpdateData,
          [action.payload.ImageType]: action.payload.response.data,
          [action.payload.ImageType + "_id"]: action.payload.response.data.id,
        },
      };
    },
    getInsurerData: (state) => {
      return {
        ...state,
        loading: false,
        agentInsurerData: {},
      };
    },
    InsurerFilterInputChangeValue: (state, action) => {
      return {
        ...state,
        insurerparams: {
          ...state.insurerparams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    insuranceNumberValidate: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getInsuranceList,
  insuranceListSuccessful,
  setInsuranceParams,
  InputChangeValue,
  isModelVisible,
  isDrawerVisible,
  getInsuranceImageUpload,
  insuranceImageUploadSuccessful,
  addNewInsurance,
  FilterInputChangeValue,
  isInsuranceViewModelVisible,
  getInsuranceById,
  insuranceByIdSuccessful,
  clearInsuranceList,
  clearInsuranceParams,

  insuranceApprove,
  getByVehicleVersion,
  getVersionInsuranceMaster,
  getVersionInsuranceMasterSuccessful,

  getInsurerList,
  insurerListSuccessful,
  setInsurerParams,
  isInsurerViewModelVisible,
  addInsurerData,
  iaddInsurerDataSuccessful,
  InsurerInputChangeValue,
  getInsurerImageUpload,
  insurerImageUploadSuccessful,
  getInsurerData,
  InsurerFilterInputChangeValue,
  isInsurerAddModelVisible,
  clearInsurerList,
  clearInsuranceData,
  apiError,
  insuranceNumberValidate,
} = newInsuranceSlice.actions;

export default newInsuranceSlice.reducer;
