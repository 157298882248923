import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Select, Card, Progress, Space, Typography, Divider, Button } from "antd";
const { Text, Link } = Typography;
import { RiBarChart2Line } from "react-icons/ri";

import saleImage from "../../assets/images/app/dashboard_img.svg";

function SaleCard(props) {
  return (
    <>
      {/* <Card>
        <Row gutter={[0, 16]} align="middle">
          <Col className="hp-statistic-icon-bg hp-bg-color-primary-4 hp-bg-color-dark-primary">
            <People className="hp-text-color-primary-1 hp-text-color-dark-primary-2 remix-icon" />
          </Col>
          <Col className="hp-mt-8">
            <Text className="h6 hp-d-block" strong>
              {props.title}
            </Text>
            <Text className="h4" strong>
              {props.qty}
            </Text>
          </Col>
        </Row>
       
      </Card> */}

      {/* <div
        className="hp-overflow-hidden hp-position-relative hp-border-radius-xxl hp-bg-primary-2 hp-pt-24 hp-px-24 hp-pb-18"
        style={{ minHeight: 140 }}
      >
        <div
          className="hp-position-absolute-bottom-left hp-w-100"
          style={{ height: "90%" }}
        >
          <svg
            width="100%"
            height="90%"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M370.717 85.179 180.539 10.257l46.817 86.462L99.151 44.63l129.884 138.803L55.517 116.68l60.47 87.899-127.415-32.922"
              stroke="url(#a)"
              strokeWidth={20}
              strokeLinejoin="bevel"
            />
            <defs>
              <linearGradient
                id="a"
                x1={151.96}
                y1={17.382}
                x2={195.449}
                y2={191.807}
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#fff" />
                <stop offset={0.737} stopColor="#fff" stopOpacity={0} />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <Row gutter={[0, 16]} align="middle">
          <Col className="hp-statistic-icon-bg hp-bg-black-0 hp-bg-color-dark-primary">
            <RiBarChart2Line
              className="hp-text-color-primary-1 hp-text-color-dark remix-icon"
              size={25}
            />
          </Col>

          <Col className="hp-mt-8">
            <Text className="h4 hp-d-block hp-text-color-black-0 hp-mb-10" strong>
              {props.title}
            </Text>
            <Text className="h2 hp-text-color-black-0" strong>
              {props.qty}
            </Text>
          </Col>
        </Row>
      </div> */}


    <div className="hp-p-36 hp-border-radius-xxl hp-card-1 hp-overflow-hidden">
      <div
        className="hp-position-absolute-top-left hp-w-100 hp-h-100 hp-nft-dashboard-own-nft-bg"
        style={{ backgroundImage: "url(" + saleImage + ")", backgroundSize: "cover", backgroundPosition: "center" }}
      ></div>

      <Row align="middle" >
        <Col className="hp-statistic-icon-bg hp-bg-black-0 hp-bg-color-dark-primary">
            <RiBarChart2Line
              className="hp-text-color-primary-1 hp-text-color-dark remix-icon"
              size={25}
            />
            
          </Col>
        <Col >
          <Text className="h4 hp-d-block hp-text-color-black-0 hp-mb-6" strong>
              {props.title}
            </Text>
            <Text className="h2 hp-text-color-black-0" strong>
              {props.total ? props.total : 0}
            </Text>
        </Col>

        {/* <Col className="hp-mt-sm-24">
          <Button type="primary" className="hp-bg-black-0 hp-text-color-primary-1 hp-border-none">Learn Now</Button>
        </Col> */}
      </Row>
    </div>
    </>
  );
}
function mapStateToProps({}) {
  return {

  };
}

export default connect(mapStateToProps, {})(SaleCard);
