import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  getStockRequestList,
  stockRequestListSuccessful,
  isModelVisible,
  stockAcceptListSuccessful,
  getStockApprovaltList,
  stockApprovalListSuccessful,
  getStockAcceptList,
  isStockApprovalModelVisible,
  isStockTransferModelVisible,
  FormulaExecuterSuccessful,
  isRepairModelVisible,
  getStockRepairList,
  stockRepairListSuccessful,
  isRepairViewModelVisible,
  getStockTempraroryList,
  isTempraroryModelVisible,
  stockTempraroryListSuccessful,
  isTempraroryViewModelVisible,
  getStockTransferRepairByIdSuccessful,
  getStockTransferViewByIdSuccessful,
  getTotalReestimationAmountByPurchase,
  getTotalRepairAmountByPurchase,
  TotalReestimationAmountByPurchaseSuccessful,
  TotalRepairAmountByPurchaseSuccessful,
} from "./stockrequestSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../../helpers/Helper";
import Swal from "sweetalert2";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
import { evaluate } from "mathjs";

import {PopMessage} from "../../../../view/common/PopMessage";

//If user is login then dispatch redux action's are directly from here.
function* StockRerquestList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfer/request/",
      params
    );
    yield put(stockRequestListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

//Stock Accept List
function* StockAcceptList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfer/accepted/list/",
      params
    );
    yield put(stockAcceptListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

//  Stock Transfer Approval List

function* StockApprovalList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfer/approved/list/",
      params
    );
    yield put(stockApprovalListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddStockOut({ payload }) {
  try {
    if (payload.id == 0) {
      const response = yield call(postAdd, "/stocktransfer/request/", payload);
      if (response.status === 201) {
        PopMessage("Your Stock Transfer Added Successfully.!", "Success");
        yield put(getStockRequestList({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        PopMessage("Sorry! Unable to add Stock Transfer. Please try again!", "Error");
      }
    } else {
      const response = yield call(
        postEdit,
        "/stocktransfer/request/" + payload.id,
        payload
      );
      if (response.status === 200) {
        PopMessage("Your Stock Out Edited Successfully.!", "Success");
        yield put(getStockRequestList({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        PopMessage("Sorry! Unable to edit Stock Out. Please try again!", "Error");
      }
    }
  } catch (error) {
    PopMessage("Sorry! Your Stock Transfer has been not Reject!", "Error");
    yield put(apiError(error));
  }
}

function* StockTransferAccept({ payload }) {
  try {
    const response = yield call(
      postEdit,
      "/stocktransfer/accepted/" + payload.id,
      payload.transferStatusObj
    );
    if (response.status === 200) {
      if (payload.transferStatusObj.transferstatus == 4) {
        PopMessage("Your Stock Transfer has been Accepted successfully!", "Success");
      } else if (payload.transferStatusObj.transferstatus == 5) {
        PopMessage("Your Stock Transfer has been Rejected successfully!", "Success");
      }
      yield put(getStockAcceptList({ page: 1, search: "", page_size: 10 }));
      yield put(isStockTransferModelVisible(false));
    } else {
      if (payload.transferstatus == 4) {
        PopMessage(" Sorry! Your Stock Transfer has been not Accpet!", "Error");
      } else if (payload.transferstatus == 5) {
        PopMessage("Sorry! Your Stock Transfer has been not Reject!", "Error");
      }
    }
  } catch (error) {
    PopMessage("Sorry! Getting from server side issue!", "Error");
    yield put(apiError(error));
  }
}

function* StockTransferApprove({ payload }) {
  try {
    const response = yield call(
      postEdit,
      "/stocktransfer/approved/" + payload.id,
      payload.approveObj
    );
    if (response.status === 200) {
      if (payload.approveObj.transferstatus == 2) {
        PopMessage("Your Stock Transfer has been Approved successfully!", "Success");
      } else if (payload.approveObj.transferstatus == 3) {
        PopMessage("Your Stock Transfer has been Rejected successfully!", "Success");
      }
      yield put(getStockApprovaltList({ page: 1, search: "", page_size: 10 }));
      yield put(isStockApprovalModelVisible(false));
    } else {
      if (payload.approveObj.transferstatus == 2) {
        PopMessage("Sorry! Your Stock Transfer has been not Approved!", "Error");
        
      } else if (payload.approveObj.transferstatus == 3) {
        PopMessage("Sorry! Your Stock Transfer has been not Rejected!", "Error");
      }
    }
  } catch (error) {
    PopMessage(" Sorry! Getting from server side issue!", "Error");
    yield put(apiError(error));
  }
}
function* FormulaExecuter({ payload }) {
  console.log("/FormulaExecuter/", payload);
  let systemFormulasList = yield select(
    (state) => state.system.systemFormulasList
  );

  try {
    var formulaObj = systemFormulasList?.find((formula) => {
      return formula?.code === payload.code;
    });

    if (formulaObj) {
      var result = evaluate(formulaObj.formula, payload.variables);

      if (result !== "NaN") {
        yield put(
          FormulaExecuterSuccessful({
            response: result,
            formulaCode: payload.code,
          })
        );
      } else {
        PopMessage(" Sorry! Unable to calculator. Please try again!", "Error");
      }
    }
  } catch (error) {
    PopMessage(" Sorry! Getting from server side issue!", "Error");
    yield put(apiError(error));
  }
}

// Stock Transfer Repair

function* StockRepairList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfer/repairvechicles/",
      params
    );
    yield put(stockRepairListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddStockRepair({ payload }) {
  try {
    if ( payload.id == 0 ||  payload.id == null || payload.id == undefined ) {
      const response = yield call(postAdd, "/stocktransfer/repairvechicles/", payload);
      if (response.status === 201) {
        PopMessage("Your Stock Repair Added Successfully.!", "Success");

        yield put(getStockRepairList({ page: 1, search: "", page_size: 10 }));
        yield put(isRepairModelVisible(false));
      } else {
        PopMessage(" Sorry! Unable to added Stock Repair. Please try again!", "Error");
      }
    } else {
      const response = yield call(
        postEdit,
        "/stocktransfer/repairvechicles/" + payload.id,
        payload
      );
      if (response.status === 200) {
        PopMessage("Your Stock Repair Edited Successfully.!", "Success");
        yield put(getStockRepairList({ page: 1, search: "", page_size: 10 }));
        yield put(isRepairModelVisible(false));
      } else {
        PopMessage("Sorry! Unable to edit Stock Repair. Please try again!", "Error");
      }
    }
  } catch (error) {
    PopMessage("Sorry! Your Stock Repair has been not Edit!", "Error");
    yield put(apiError(error));
  }
}

function* StockTransferRepair({ payload }) {
  try {
    const response = yield call(
      postEdit,
      "/stocktransfer/repairvechicles/received/" + payload.id, {}
    );
    if (response.status === 200) {
      PopMessage("Your Stock Transfer Repair has been Received successfully!", "Success");
      yield put(getStockRepairList({ page: 1, search: "", page_size: 10 }));
      yield put(isRepairModelVisible(false));
      yield put(isRepairViewModelVisible(false));
    } else {
      PopMessage("Sorry! Your Stock Transfer Repair has been not Received!", "Error");
    }
  } catch (error) {
    PopMessage("Sorry! Getting from server side issue!", "Error");
    yield put(apiError(error));
  }
}

function* DeleteStockRepair({ payload }) {
  var personID = payload;
  try {
    const response = yield call(
      postDelete,
      "/stocktransfer/repairvechicles/" + personID
    );

    if (response.status === 204) {
      PopMessage("Your Stock Transfer Repair has been Deleted successfully!", "Success");
      yield put(getStockRepairList({ page: 1, search: "", page_size: 10 }));
    } else {
      PopMessage(" Sorry! Your Stock Transfer Repair has been not Deleted!", "Error");
    }
  } catch (error) {
    PopMessage(" Sorry! Getting from server side issue!", "Error");
    yield put(apiError(error));
  }
}

// Stock Transfer Temprarory

function* StockTempraroryList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfer/temprarory/",
      params
    );
    yield put(stockTempraroryListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddStockTemprarory({ payload }) {
  try {
    if (payload.id == 0 ||  payload.id == null || payload.id == undefined) {
      const response = yield call( postAdd, "/stocktransfer/temprarory/", payload);
      if (response.status === 201) {
        PopMessage("Your Stock Temporary Added Successfully.!", "Success");
        yield put(getStockTempraroryList({ page: 1, search: "", page_size: 10 }));
        yield put(isTempraroryModelVisible(false));
      } else {
        PopMessage("Sorry! Unable to added Stock Temporary. Please try again!", "Error");
      }
    } else {
      const response = yield call(
        postEdit,
        "/stocktransfer/temprarory/" + payload.id,
        payload
      );
      if (response.status === 200) {
        PopMessage("Your Stock Temporary Edited Successfully.!", "Success");
        yield put(
          getStockTempraroryList({ page: 1, search: "", page_size: 10 })
        );
        yield put(isTempraroryModelVisible(false));
      } else {
        PopMessage("Sorry! Unable to edit Stock Temporary. Please try again!", "Error");
        
      }
    }
  } catch (error) {
    PopMessage("Sorry! Your Stock Temporary has been not Edit!", "Error");
    yield put(apiError(error));
  }
}

function* StockTransferTemprarory({ payload }) {
  try {
    const response = yield call(
      postEdit,
      "/stocktransfer/temprarory/received/" + payload.id,
      {}
    );
    if (response.status === 200) {
      PopMessage("Your Stock Transfer Temporary has been Received successfully!", "Success");
      yield put(getStockTempraroryList({ page: 1, search: "", page_size: 10 }));
      yield put(isTempraroryModelVisible(false));
      yield put(isTempraroryViewModelVisible(false));
    } else {
      PopMessage("Sorry! Your Stock Transfer Temporary has been not Received!", "Error");
     
    }
  } catch (error) {
    PopMessage("Sorry! Getting from server side issue!", "Error");
    yield put(apiError(error));
  }
}

function* DeleteStockTemprarory({ payload }) {
  var personID = payload;
  try {
    const response = yield call(
      postDelete,
      "/stocktransfer/temprarory/" + personID
    );

    if (response.status === 204) {
      PopMessage("Your Stock Transfer Temporary has been Deleted successfully!", "Success");
      yield put(getStockTempraroryList({ page: 1, search: "", page_size: 10 }));
    } else {
      PopMessage("Sorry! Your Stock Transfer Temporary has been not Deleted!", "Error");
    }
  } catch (error) {
    PopMessage(" Sorry! Getting from server side issue!", "Error");
    yield put(apiError(error));
  }
}

function* StockTransferRepairById({ payload }) {
  try {
    const response = yield call(
      getList,
      "/stocktransfer/repairvechicles/" + payload,
      {}
    );
    yield put(getStockTransferRepairByIdSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}



function* StockTransferViewById({ payload }) {
  try {
    const response = yield call(
      getList,
      "/stocktransfer/stocktransfer/" + payload.value,
      {}
    );
    yield put(getStockTransferViewByIdSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* TotalReestimationAmountByPurchase({ payload }) {
  try {
    const response = yield call(
      getList,
      "/stocktransfer/reestimationamount/total/bypurchase/" + payload,
      {}
    );
    yield put(TotalReestimationAmountByPurchaseSuccessful(response));
  } catch (error) {
    PopMessage("  Sorry! Getting from server side issue!", "Error");
    yield put(apiError(error));
  }
}
function* TotalRepairAmountByPurchase({ payload }) {
  try {
    const response = yield call(
      getList,
      "/stocktransfer/repairamount/total/bypurchase/" + payload,
      {}
    );
    if (response.pendingcount !== 0) {
      PopMessage("Some Repair amounts are in still Pending", "Error");
      yield put(TotalRepairAmountByPurchaseSuccessful(response));
    } else {
      yield put(TotalRepairAmountByPurchaseSuccessful(response));
    }
  } catch (error) {
    PopMessage("Sorry! Getting from server side issue!", "Error");
    yield put(apiError(error));
  }
}



function* UpdateFsyncStatus({ payload }) {
  let stockAcceptData = yield select(
    (state) => state.stockRequest.stockRequestparams
  );
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfer/stocktransferrepost/" + payload.id,
      {}
    );
    if (response.post_status == true) {
      PopMessage(" Selected Stock Transfer is successfully synchronised into focus", "Success");
      
    } else {
      PopMessage(" Sorry! selected Stock Transfer is not posted on focus. please try again!", "Error");
      
    }
    yield put(getStockAcceptList(stockAcceptData));
  } catch (error) {
    PopMessage(" Sorry! Getting from server side issue!", "Error");
   
    yield put(apiError(error));
  }
}

export function* watchGetStockRerquestList() {
  yield takeEvery("stockRequest/getStockRequestList", StockRerquestList);
}
export function* watchGetStockOutAdd() {
  yield takeEvery("stockRequest/stockOutAdd", AddStockOut);
}

export function* watchGetStockAcceptList() {
  yield takeEvery("stockRequest/getStockAcceptList", StockAcceptList);
}

export function* watchGetStockApprovalList() {
  yield takeEvery("stockRequest/getStockApprovaltList", StockApprovalList);
}

export function* watchGetStockTransferAccept() {
  yield takeEvery("stockRequest/stockTransferAccept", StockTransferAccept);
}
export function* watchGetStockTransferApprove() {
  yield takeEvery("stockRequest/stockTransferApprove", StockTransferApprove);
}
export function* watchGetFormulaExecuter() {
  yield takeEvery("stockRequest/getFormulaExecuter", FormulaExecuter);
}

export function* watchGetStockRepairList() {
  yield takeEvery("stockRequest/getStockRepairList", StockRepairList);
}

export function* watchGetStockRepairAdd() {
  yield takeEvery("stockRequest/stockRepairAdd", AddStockRepair);
}
export function* watchGetStockTransferRepair() {
  yield takeEvery("stockRequest/stockTransferRepair", StockTransferRepair);
}

export function* watchGetStockRepairDelete() {
  yield takeEvery("stockRequest/stockRepairDelete", DeleteStockRepair);
}

export function* watchGetStockTempraroryAdd() {
  yield takeEvery("stockRequest/stockTempraroryAdd", AddStockTemprarory);
}

export function* watchGetStockTempraroryList() {
  yield takeEvery("stockRequest/getStockTempraroryList", StockTempraroryList);
}

export function* watchGetStockTransferTemprarory() {
  yield takeEvery(
    "stockRequest/stockTransferTemprarory",
    StockTransferTemprarory
  );
}
export function* watchGetStockTempraroryDelete() {
  yield takeEvery("stockRequest/stockTempraroryDelete", DeleteStockTemprarory);
}

export function* watchGetStockTransferRepairById() {
  yield takeEvery(
    "stockRequest/getStockTransferRepairById",
    StockTransferRepairById
  );
}

export function* watchGetStockTransferViewById() {
  yield takeEvery(
    "stockRequest/getStockTransferViewById",
    StockTransferViewById
  );
}

export function* watchGetTotalReestimationAmountByPurchase() {
  yield takeEvery(
    "stockRequest/getTotalReestimationAmountByPurchase",
    TotalReestimationAmountByPurchase
  );
}
export function* watchGetTotalRepairAmountByPurchase() {
  yield takeEvery(
    "stockRequest/getTotalRepairAmountByPurchase",
    TotalRepairAmountByPurchase
  );
}
export function* watchFsyncStatusUpdate() {
  yield takeEvery("stockRequest/fsyncStatusUpdate", UpdateFsyncStatus);
}
function* stockRequestSaga() {
  yield all([
    fork(watchGetStockRerquestList),
    fork(watchGetStockAcceptList),
    fork(watchGetStockApprovalList),
    fork(watchGetStockOutAdd),
    fork(watchGetStockTransferAccept),
    fork(watchGetStockTransferApprove),
    fork(watchGetFormulaExecuter),
    fork(watchGetStockRepairAdd),
    fork(watchGetStockRepairList),
    fork(watchGetStockTransferRepair),
    fork(watchGetStockRepairDelete),
    fork(watchGetStockTempraroryList),
    fork(watchGetStockTempraroryAdd),
    fork(watchGetStockTransferTemprarory),
    fork(watchGetStockTempraroryDelete),
    fork(watchGetStockTransferRepairById),
    fork(watchGetStockTransferViewById),
    fork(watchGetTotalReestimationAmountByPurchase),
    fork(watchGetTotalRepairAmountByPurchase),
    fork(watchFsyncStatusUpdate),
  ]);
}

export default stockRequestSaga;
