import { createSlice } from "@reduxjs/toolkit";

export const serviceMechSlice = createSlice({
  name: "serviceMech",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    ServiceMechList: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    serviceMechParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
  },
  reducers: {
    getServiceMechanicList: (state) => {
      if (state.serviceMechParams?.page == 1) {
        return {
          ...state,
          loading: true,
          ServiceMechList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    serviceMechanicListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.serviceMechParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.serviceMechParams?.page_size
      );
        return {
          ...state,
          ServiceMechList: list,
          listCount: action.payload.count,
          loading: false,
          serviceMechParams: {
            ...state.serviceMechParams,
            no_of_pages: noofpages,
          },
        };
    },
    serviceMechanicAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    serviceMechanicAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    ServiceMechDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    setServiceMechParams: (state, action) => {
      return {
        ...state,
        serviceMechParams: action.payload,
      };
    },
    setSelectData: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: {
          ...action.payload,
          id: action.payload.id,
          name: action.payload.name,
        },
       
      }
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getServiceMechanicList,
  serviceMechanicListSuccessful,
  serviceMechanicAdd,
  serviceMechanicAddSuccessful,
  ServiceMechDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  setServiceMechParams,
  setSelectData
} = serviceMechSlice.actions;

export default serviceMechSlice.reducer;
