import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const rtoSlice = createSlice({
  name: "rto",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    model: false,
    drawer: false,
    apiError: {},
    rtoData: {},
    rtoUpdateData: {},
    listCount: 0,
    newRtoList: [],
    rtoparams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getRtoList: (state, action) => {
      if (state.rtoparams?.page == 1) {
        return {
          ...state,
          loading: true,
          listCount: 0,
          newRtoList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    rtoListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.rtoparams?.page_size
      );
      console.log(action.payload);
      return {
        ...state,
        listCount: action.payload.response.count,
        newRtoList: action.payload.response.results,
        loading: false,
        rtoparams: {
          ...state.rtoparams,
          no_of_pages: noofpages,
        },
      };
    },
    setRtoParams: (state, action) => {
      return {
        ...state,
        rtoparams: action.payload,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rtoUpdateData: {
          ...state.rtoUpdateData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rtoUpdateData: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },

    rtoAdd: (state, action) => {
      return {
        ...state,
        loading: true,
        rtoUpdateData: {
          id: action.payload.id,
          amount: action.payload.amount,
        },
      };
    },

    rtoAddSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        rtoUpdateData: {},
      };
    },

    getRtoById: (state) => {
      return {
        ...state,
        loading: true,
        // rtoData: action.payload,
      };
    },
    rtoByIdSuccessful: (state, action) => {
      console.log("rtoData", action.payload);
      return {
        ...state,
        loading: false,
        rtoData: action.payload,
      };
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        rtoparams: {
          ...state.rtoparams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getRtoList,
  rtoListSuccessful,
  setRtoParams,
  InputChangeValue,
  isModelVisible,
  isDrawerVisible,
  rtoAdd,
  rtoAddSuccessful,
  getRtoById,
  rtoByIdSuccessful,
  FilterInputChangeValue,
  apiError,
} = rtoSlice.actions;

export default rtoSlice.reducer;
