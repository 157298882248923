import { createSlice } from "@reduxjs/toolkit";

export const divisionsSlice = createSlice({
  name: "divisions",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    divisionslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    divisionsParams: {
      no_of_pages: 0,
      listCount: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getDivisions: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    divisionsListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.divisionsParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        divisionslist: action.payload.response.results,
        loading: false,
        divisionsParams: {
          ...state.divisionsParams,
          no_of_pages: noofpages,
        },
      };
    },
    divisionAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    divisionsEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: {
          id: action.payload.id,
          name: action.payload.name,
          zone_id: action.payload.zone?.id,
        },
      };
    },
    divisionDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetDivisionsParams: (state, action) => {
      return {
        ...state,
        divisionsParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getDivisions,
  divisionsListSuccessful,
  divisionAdd,
  divisionsEdit,
  divisionDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetDivisionsParams
} = divisionsSlice.actions;

export default divisionsSlice.reducer;
