import { createSlice } from "@reduxjs/toolkit";

export const mechanicsSlice = createSlice({
  name: "mechanics",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    mechanicslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    mechanicParams: {
      no_of_pages: 0,
      listCount: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getMechanics: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    mechanicsListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.mechanicParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        mechanicslist: action.payload.response.results,
        loading: false,
        mechanicParams: {
          ...state.mechanicParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMechanicParams: (state, action) => {
      return {
        ...state,
        mechanicParams: action.payload,
      };
    },
    mechanicAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    getMechanicByID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    mechanicByIDSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        rowdata: {
          // id: action.payload.id,
          ...action.payload.response,
          name: action.payload.response.name,
          // mobile: action.payload.response.mobile,
          // aadharno: action.payload.response.aadharno,
          // address: action.payload.response.address,
          // ifsccode: action.payload.response.ifsccode,
          // accono: action.payload.response.accono,
          // bank_id: action.payload.response.bank?.id,
        },
        model: action.payload.type == "update" ? true : false,
        // viewModel: action.payload.type == "view" ? true : false,
        // customerViewData: action.payload.response,
      };
    },


    mechanicDelete: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },

    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getMechanics,
  mechanicsListSuccessful,
  mechanicAdd,
  getMechanicByID,
  mechanicByIDSuccessful,
  setMechanicParams,
  mechanicDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
} = mechanicsSlice.actions;

export default mechanicsSlice.reducer;
