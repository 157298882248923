import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const customerDefaulterSlice = createSlice({
  name: "customerDefaulter",
  initialState: {
    loginError: "aaa",
    drawer: false,
    loading: false,
    model: false,
    apiError: {},
    listCount: 0,
    defaulterData: {},
    defaulterAddData: {},
  },
  reducers: {
    InputChangeValue: (state, action) => {
      return {
        ...state,
        defaulterData: {
          ...state.defaulterData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    DefaulterInputChangeValue: (state, action) => {
      return {
        ...state,
        defaulterAddData: {
          ...state.defaulterAddData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    markAsDefaulter: (state, action) => {
      return{
        ...state,
        loading: true,
      }
    },
    markAsDefaulterSuccessful: (state, action) =>{
      return{
        ...state,
        loading: false,
        defaulterData: {},
        defaulterAddData: {},
        model: false,
      }
    },
    clearCustomerList: (state, action) => {
      return {
        ...state,
        loading: false,
        defaulterData: {},
      }
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          defaulterAddData: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  InputChangeValue,
  markAsDefaulter,
  markAsDefaulterSuccessful,
  clearCustomerList,
  isModelVisible,
  DefaulterInputChangeValue,
  apiError,
} = customerDefaulterSlice.actions;

export default customerDefaulterSlice.reducer;
