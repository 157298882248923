import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Select, Card, Progress, Space, Typography } from "antd";
const { Text, Link } = Typography;

function TargetsCard(props) {
  return (
    <>
      <Card>
        <Row gutter={[0, 16]} align="middle" justify="space-between">
          <Col sm={12} span={24}>
            <Text className="h5" strong>
              {props.title}
            </Text>
          </Col>
        </Row>

        {/* {props.targetsCount && (
          <>

          </>
        )} */}
        <Row gutter={[0, 12]} className="hp-mt-12 hp-p-0">
          {props.data.map((item) => (
            <Col
              span={24}
              className="hp-bg-info-4  hp-border-radius-xl hp-border-1 hp-border-color-info-4 hp-border-color-info-4 hp-p-12"
            >
              <Row align="middle" justify="space-between">
                <Col
                  sm={10}
                  span={12}
                  className="hp-text-sm-left "
                  style={{ minHeight: 40 }}
                >
                  <Text className="hp-d-block h6 hp-font-weight-500">
                    {item.title}
                  </Text>

                  <span className="hp-d-block h6 hp-font-weight-600">
                    {item.total}
                  </span>
                </Col>

                <Col
                  sm={5}
                  span={11}
                  className="hp-text-right"
                  style={{ maxHeight: 40 }}
                >
                  {/* <Progress
                   type="circle"
                   percent={50}
                   width={40}
                   // strokeWidth={1}
                   format={(percent) => `${percent}`}
                 /> */}
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Card>
    </>
  );
}
function mapStateToProps({}) {
  return {};
}

export default connect(mapStateToProps, {})(TargetsCard);
