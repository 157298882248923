import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import { apiError, markAsDefaulterSuccessful } from "./customerDefaulterSlice";
import { getPerson, clearPersonData } from "../../Masters/Person/personSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

//If user is login then dispatch redux action's are directly from here.

function* AddMarkAsDefaulter({ payload }) {
  try {
    const response = yield call(
      postEdit,
      "/masters/defaultor/update/" + payload.id,
      {
        is_defaultor: payload.is_defaultor,
        defaultorremarks: payload.defaultorremarks,
      }
    );

    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              This user is marked as Defaulter.!
            </p>
          </div>
        ),
      });
      yield put(getPerson({ page: 1, search: "", page_size: 10 }));
      yield put(clearPersonData());
      yield put(markAsDefaulterSuccessful());
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to update this user as defaulter. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (errors) {
    yield put(apiError(errors));
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
  }
}

export function* watchMarkAsDefaulter() {
  yield takeEvery("customerDefaulter/markAsDefaulter", AddMarkAsDefaulter);
}

function* customerDefaulterSaga() {
  yield all([fork(watchMarkAsDefaulter)]);
}

export default customerDefaulterSaga;
