import { createSlice } from "@reduxjs/toolkit";

export const tenuresSlice = createSlice({
  name: "tenures",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    tenureslist: [],
    model: false,
    error_msg: null,
    listCount: 0,
    rowdata: {},
    tenuresParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getTenures: (state, action) => {
      if (action.payload?.page == 1) {
        return {
          ...state,
          loading: true,
          tenureslist: [],
          rowdata: {},
          tenuresParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
          },

        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    tenuresListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.tenuresParams.page_size
      );
      action.payload.response.results = action.payload.response.results.sort((a, b) =>
      a.sequence > b.sequence ? 1 : -1
    );
      return {
        ...state,
        listCount: action.payload.response.count,
        tenureslist: action.payload.response.results,
        loading: false,
        tenuresParams: {
          ...state.tenuresParams,
          no_of_pages: noofpages,
        },
      };
    },
    tenuresAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    tenuresEdit: (state, action) => {
      var interestchoice = [];
      interestchoice = action.payload.interestchoice.map((interestchoice) => {
        return interestchoice.id;
      });
      return {
        ...state,
        model: true,
        rowdata: {
          id: action.payload.id,
          name: action.payload.name,
          sequence: action.payload.sequence,
          interestfixed_id: action.payload.interestfixed?.id,
          interestchoice_ids: interestchoice,
        },
      };
     
    },
    tenuresEditSuccessful: (state) => {
      return {
        ...state,
        loading: false,
        model: true,
      };
    },
    tenuresDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetTenuresParams: (state, action) => {
      return {
        ...state,
        tenuresParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getTenures,
  tenuresListSuccessful,
  tenuresAdd,
  tenuresAddSuccessful,
  tenuresEdit,
  tenuresEditSuccessful,
  tenuresDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetTenuresParams
} = tenuresSlice.actions;

export default tenuresSlice.reducer;
