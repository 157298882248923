import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Select, Card, Progress, Space, Typography, Avatar } from "antd";
const { Text, Link } = Typography;
import { People } from "react-iconly";

function VisitCard(props) {
  const {data} = props;


 



  return (
    <>
      <Card
        actions={[
          
          <>
            <Text type="success" className="hp-d-block hp-caption hp-letter-space-1">
              Done
            </Text>
            <Text strong className="h6">
              {data.done ? data.done : 0}
            </Text>
          </>,
          <>
            <Text type="danger" className="hp-d-block hp-caption hp-letter-space-1">
              Not Done
            </Text>
            <Text strong className="h6">
              {data.not_done ? data.not_done : 0}
            </Text>
          </>,
        ]}
        bodyStyle={{ padding: "7px",}}
        className="hp-visit-card hp-border-color-black-40 hp-transition hp-hover-border-color-primary-4
        hp-card-6 hp-eCommerceCardOne hp-eCommerceCardOne-wishlist hp-icon-card list"
      >
        <Row align="middle">
          <Col
            span={6}>
            <Avatar size={45} icon={props.icon}  className="hp-bg-primary-1 hp-text-color-black-0" />
          </Col>
          <Col span={16}>
            <Text className="h6 hp-d-block" strong>
              {props.title}
            </Text>
            <Text className="h4" strong>
              {data.total ? data.total : 0}
            </Text>
          </Col>
        </Row>
      </Card>
    </>
  );
}
function mapStateToProps({}) {
  return {
  };
}

export default connect(mapStateToProps, {})(VisitCard);
