import React from "react";
import { Card, Row, Col, Input, Button, DatePicker, Spin } from "antd";
import PageTitle from "../../layout/components/content/page-title";
import { isMobile } from "react-device-detect";
import moment, { min } from "moment";
import { connect } from "react-redux";
import TargetsCard from "./targetsCard";
import AnalyticsCard from "./analyticsCard";
import TransactionCard from "./transactionCard";
import EnquiryCard from "./enquiryCard";
import SaleCard from "./saleCard";
import {
  BarChartOutlined,
  FilterOutlined,
  PieChartOutlined,
  ToolOutlined,
  SyncOutlined,
  DollarOutlined,
  TransactionOutlined,
  ReloadOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import {
  clearData,
  getPurchaseCount,
  getSaleCount,
  getRepairCount,
  getStockTransferCount,
  getEnquiryCount,
  getQuotationCount,
  getTargetsCount,
  getPurchaseAnalytics,
  isDrawerVisible,
  FilterInputChangeValue,
  getTotalStockTransferCount,
  getVisitCount,
  getSaleAnalytics,
  getFinanceAmount,
} from "../../store/dashboard/dashboardSlice";
import { useEffect } from "react";
import ListCard from "./listCard";
import Filter from "./Filters/Filter";
import StockTransferCard from "./stockTransferCard";
import { sidebarEnable } from "../../store/customise/customiseSlice";
import TotalStockTransferCard from "./totalStockTransferCard";
import VisitCard from "./visitCard";
import FinanceCard from "./FinanceCard";

function Dashboard(props) {
  const { Search } = Input;

  useEffect(() => {
    props.clearData();
    props.getPurchaseCount({
      start_date: moment(),
      end_date: moment(),
    });
    props.getSaleCount({
      start_date: moment(),
      end_date: moment(),
    });
    props.getRepairCount({
      start_date: moment(),
      end_date: moment(),
    });
    props.getStockTransferCount({
      start_date: moment(),
      end_date: moment(),
    });
    props.getEnquiryCount({
      start_date: moment(),
      end_date: moment(),
    });
    props.getQuotationCount({
      start_date: moment(),
      end_date: moment(),
    });
    props.getVisitCount({
      start_date: moment(),
      end_date: moment(),
    })
    props.getTotalStockTransferCount()
    props.getTargetsCount();
    props.getPurchaseAnalytics();
    props.getSaleAnalytics();
    props.getFinanceAmount({
      start_date: moment(),
      end_date: moment(),
    })
    inactivityTime();
    if (!isMobile) {
      props.sidebarEnable(true);
    }
    return () => {
      props.sidebarEnable(false);
    };
  }, []);

  var inactivityTime = function () {
    var time;
    // window.onload = resetTimer;
    // **************** DOM Events ********************* //
    // document.onmousemove = resetTimer;
    // document.onmousedown = resetTimer; // touchscreen presses
    // document.ontouchstart = resetTimer;
    // document.onclick = resetTimer; // touchpad clicks
    // document.onkeydown = resetTimer; // onkeypress is deprectaed
    // document.addEventListener("scroll", resetTimer, true);

    // **************** OR DOM Events Using array  ********************* //
    window.addEventListener("load", resetTimer, true);
    var events = ["mousedown", "mousemove", "keypress", "scroll", "touchstart"];
    events.forEach(function (name) {
      document.addEventListener(name, resetTimer, true);
    });
    function reload() {
      props.clearData();
      props.getPurchaseCount({
        start_date: moment(),
        end_date: moment(),
      });
      props.getSaleCount({
        start_date: moment(),
        end_date: moment(),
      });
      props.getRepairCount({
        start_date: moment(),
        end_date: moment(),
      });
      props.getStockTransferCount({
        start_date: moment(),
        end_date: moment(),
      });
      props.getEnquiryCount({
        start_date: moment(),
        end_date: moment(),
      });
      props.getQuotationCount({
        start_date: moment(),
        end_date: moment(),
      });
      props.getVisitCount({
        start_date: moment(),
        end_date: moment(),
      });
      props.getTotalStockTransferCount();
      props.getTargetsCount();
      // props.getPurchaseAnalytics();
      props.getSaleAnalytics()

      resetTimer();
    }

    function resetTimer() {
      // clearTimeout(time);
      // time = setTimeout(reload, 30000);
      // reload()
      // 1000 milliseconds = 1 second
    }
  };

  // function disableDateRanges(range = { startDate: false, endDate: false }) {
  //   const { startDate, endDate } = range;
  //   return function disabledDate(current) {
  //     let startCheck = true;
  //     let endCheck = true;
  //     if (startDate) {
  //       startCheck =
  //         current &&
  //         current < moment(startDate + " 00:00:01", "YYYY-MM-DD HH:mm:ss");
  //     }
  //     if (endDate) {
  //       endCheck =
  //         current &&
  //         current >
  //           moment(
  //             moment(endDate).format("YYYY-MM-DD") + " 23:59:59",
  //             "YYYY-MM-DD HH:mm:ss"
  //           );
  //     }

  //     return (startDate && startCheck) || (endDate && endCheck);
  //   };
  // }

  // function autoLoadPage(){
  //   var reload = 0;
  //   // console.log("load page....", );
  //   // location.reload();
  //   setInterval(() => {
  //     reload ++;
  //     if(reload > 20){

  //     }else{

  //     }
  //   }, 1000)
  // }

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Spin tip="Loading..." spinning={props.loading} delay={500}>
          <Row gutter={[12, 12]} className="hp-ecommerce-app-header hp-mb-10">
            <Col span={24} xs={18} sm={18} md={18}>
              <PageTitle pageTitle="Dashboard" goBack={false} />
            </Col>
            <Col span={24} xs={4} sm={4} md={3} className="hp-mt-10">
              <Button
                onClick={() => {
                  props.isDrawerVisible(true);
                }}
                className="hp-w-100 hp-p-10 "
                icon={<FilterOutlined className="hp-mr-10" />}
              >
                {!isMobile && "Filter"}
              </Button>
            </Col>

            {/* <Col span={24} xs={2} sm={2} md={2} className="hp-mt-10">
            <Button
                onClick={() => {
                  autoLoadPage()
                }}
                shape="circle"
                icon={<ReloadOutlined  />}
              >
              </Button>
            </Col> */}

            {/* <Col span={24} xs={4} sm={4} md={3} className="hp-mt-10">
            <DatePicker
              style={{ width: "100%" }}
              placeholder=" Month"
              format="YYYY-MM"
              picker="month"
              name="month"
              // disabledDate={disableDateRanges({
              //   endDate: moment().format("YYYY-MM-DD"),
              //   startDate: moment().format("YYYY-MM-DD"),
              // })}
              // disabled={props.targetData?.id == null ? false : true}
              onChange={(month) => {
                console.log("moneht", month.format("YYYY-MM"));
                props.getPurchaseCount({...props.dashboardParams,month: month})
                props.FilterInputChangeValue({
                  key: "month",
                  // value: month
                  value: month == null ? "" : month,
                });
              }}
            />
          </Col> */}
          </Row>

          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col span={24} xs={24} sm={24} md={24} xl={18}>
                  <Row gutter={[12, 12]}>
                    <Col span={16} xs={24} sm={12} md={8} xl={8}>
                      <TransactionCard
                        title=" Direct Sale"
                        data={props.directSaleCount}
                        icon={<PieChartOutlined />}
                      />
                    </Col>

                    <Col span={16} xs={24} sm={12} md={8} xl={8}>
                      <TransactionCard
                        title=" Finance Sale"
                        data={props.financeSaleCount}
                        icon={<DollarOutlined />}
                      />
                    </Col>

                    <Col span={16} xs={24} sm={12} md={8} xl={8}>
                      <TransactionCard
                        title=" OVF Sale"
                        data={props.ovfSaleCount}
                        icon={<TransactionOutlined />}
                      />
                    </Col>
                    <Col span={16} xs={24} sm={12} md={8} xl={8}>
                      <TransactionCard
                        title=" Purchases"
                        data={props.purchaseCount}
                        icon={<BarChartOutlined />}
                      />
                    </Col>
                    <Col span={16} xs={24} sm={12} md={8} xl={8}>
                      <TransactionCard
                        title=" Repair Amount"
                        data={props.reairCount}
                        icon={<ToolOutlined />}
                      />
                    </Col>

                    <Col span={16} xs={24} sm={12} md={8} xl={8}>
                      <StockTransferCard
                        title=" Stock Transfer"
                        data={props.stockTransferCount}
                        icon={<SyncOutlined />}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col span={24} xs={24} sm={24} md={24} xl={6}>
                  <Row gutter={[12, 12]}>
                    <Col span={24} xs={24} sm={24} md={24} xl={24}>
                      <SaleCard title="Transaction" total={props.totalSales} />
                    </Col>

                    <Col span={24} xs={24} sm={24} md={24} xl={24}>
                      <TotalStockTransferCard
                        title="Total Stock"
                        data={props.totalStockTransferCount}
                        icon={<ProjectOutlined />}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col span={24} xs={24} sm={24} md={24} xl={18}>
                  <Row gutter={[12, 12]}>
                    <Col span={16} xs={24} sm={24} md={24} xl={12}>
                      <EnquiryCard title="Enquires" data={props.enquiryCount} />
                    </Col>

                    <Col span={16} xs={24} sm={24} md={24} xl={12}>
                      <EnquiryCard
                        title="Quotations"
                        data={props.QuotationCount}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col span={24} xs={24} sm={24} md={24} xl={6}>
                  <Row gutter={[12, 12]}>
                    <Col span={24} xs={24} sm={24} md={24} xl={24}>
                      <VisitCard
                        title="Collection"
                        data={props.collectionCount}
                        icon={<ProjectOutlined />}
                      />
                    </Col>

                    {/* <Col span={24} xs={24} sm={24} md={24} xl={24}>
                      <VisitCard
                        title="Seizing"
                        data={props.recoveryCount}
                        icon={<ProjectOutlined />}
                      />
                    </Col> */}
                    <Col span={24} xs={24} sm={24} md={24} xl={24}>
                      <FinanceCard
                        title="Finance"
                        data={props.financeAmount}
                        icon={<ProjectOutlined />}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col span={16} xs={24} sm={24} md={24} xl={24}>
              <AnalyticsCard
                title="Analytics"
                categoryData={props.purchaseCategoty}
                data={props.purchaseAnalyticsData}
              />
            </Col>
          </Row>

          {/* {props.userAccessList?.indexOf("Purchase.view_purchase") !== -1 && (
            <Row gutter={[12, 12]} className="hp-mb-10 hp-mt-10">
              <Col span={24}>
                <ListCard />
              </Col>
            </Row>
          )} */}
        </Spin>
      </Col>
      <Filter />
    </Row>
  );
}

function mapStateToProps({ dashboard, system }) {
  return {
    purchaseCount: dashboard.purchaseCount,
    directSaleCount: dashboard.directSaleCount,
    financeSaleCount: dashboard.financeSaleCount,
    ovfSaleCount: dashboard.ovfSaleCount,
    reairCount: dashboard.reairCount,
    stockTransferCount: dashboard.stockTransferCount,
    userAccessList: system.userAccessList,
    enquiryCount: dashboard.enquiryCount,
    QuotationCount: dashboard.QuotationCount,
    targetsCount: dashboard.targetsCount,
    purchaseAnalyticsData: dashboard.purchaseAnalyticsData,
    purchaseCategoty: dashboard.purchaseCategoty,
    totalSales: dashboard.totalSales,
    dashboardParams: dashboard.dashboardParams,
    loading: dashboard.loading,
    totalStockTransferCount: dashboard.totalStockTransferCount,
    collectionCount: dashboard.collectionCount,
    recoveryCount: dashboard.recoveryCount,
    financeAmount: dashboard.financeAmount,
  };
}

export default connect(mapStateToProps, {
  clearData,
  getPurchaseCount,
  getSaleCount,
  getRepairCount,
  getStockTransferCount,
  getEnquiryCount,
  getQuotationCount,
  getTargetsCount,
  getPurchaseAnalytics,
  isDrawerVisible,
  FilterInputChangeValue,
  sidebarEnable,
  getTotalStockTransferCount,
  getVisitCount,
  getSaleAnalytics,
  getFinanceAmount
})(Dashboard);
