import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  apiError,
  getCategoryList,
  categoryListSuccessful,
  categoryAddSuccessful,
  isModelVisible,
} from "./categorySlice";

// AUTH related methods
import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* getCategory({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {

        params.ordering = data.sortOrder + data.currentSort;
      } else {

        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/itemcategory/", params);
    yield put(categoryListSuccessful( response ));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* AddCategory({ payload }) {
  try {
    if (payload.id == 0) {
      const response = yield call(postAdd, "/masters/itemcategory/", payload);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Category Add Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getCategoryList({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Category. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(categoryAddSuccessful(null));
    } else {
      const response = yield call(
        postEdit,
        "/masters/itemcategory/" + payload.id,
        payload
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Category Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getCategoryList({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Category. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(categoryAddSuccessful(null));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* DeleteCategory({ payload }) {
  var countryID = payload;
  try {
    const response = yield call(postDelete, "/masters/itemcategory/" + countryID);
    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Category has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getCategoryList({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Category has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

export function* watchGetCategoryList() {
  yield takeEvery("category/getCategoryList", getCategory);
}
export function* watchCategoryAdd() {
  yield takeEvery("category/categoryAdd", AddCategory);
}
export function* watchcategoryDelete() {
  yield takeEvery("category/categoryDelete", DeleteCategory);
}

function* CategorySaga() {
  yield all([
    fork(watchGetCategoryList),
    fork(watchCategoryAdd),
    fork(watchcategoryDelete),
  ]);
}

export default CategorySaga;
