import { createSlice } from "@reduxjs/toolkit";

export const showroomsSlice = createSlice({
  name: "showrooms",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    showroomslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    showroomsParams: {
      no_of_pages: 0,
      listCount: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getShowrooms: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    showroomsListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.showroomsParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        showroomslist: action.payload.response.results,
        loading: false,
        showroomsParams: {
          ...state.showroomsParams,
          no_of_pages: noofpages,
        },
      };
    },
    showroomsAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    showroomsAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    showroomsEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: {
          id: action.payload.id,
          bank_id: action.payload.bank?.id,
          name: action.payload.name,
          contact_number: action.payload.contact_number,
          location: action.payload.location,
          address: action.payload.address,
          accono: action.payload.accono,
          ifsccode: action.payload.ifsccode,
        }
      };
    },
    showroomsEditSuccessful: (state) => {
      return { ...state, loading: false, rowdata: {} };
    },
    showroomsDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetShowroomsParams: (state, action) => {
      return {
        ...state,
        showroomsParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getShowrooms,
  showroomsListSuccessful,
  showroomsAdd,
  showroomsAddSuccessful,
  showroomsEdit,
  showroomsEditSuccessful,
  showroomsDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetShowroomsParams
} = showroomsSlice.actions;

export default showroomsSlice.reducer;
