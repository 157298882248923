import { createSlice } from "@reduxjs/toolkit";

export const colorsSlice = createSlice({
  name: "colors",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    colorslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    colorsParams: {
      no_of_pages: 0,
      listCount: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getColors: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    colorsListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.colorsParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        colorslist: action.payload.response.results,
        loading: false,
        colorsParams: {
          ...state.colorsParams,
          no_of_pages: noofpages,
        },
      };
    },
    colorsAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    colorsAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    colorsEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: action.payload,
      };
    },
    colorsEditSuccessful: (state) => {
      return { ...state, loading: false, rowdata: {} };
    },
    colorsDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetColorsParams: (state, action) => {
      return {
        ...state,
        colorsParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getColors,
  colorsListSuccessful,
  colorsAdd,
  colorsAddSuccessful,
  colorsEdit,
  colorsEditSuccessful,
  colorsDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetColorsParams
} = colorsSlice.actions;

export default colorsSlice.reducer;
