import { takeEvery, fork, put, all, call } from "redux-saga/effects";
// Login Redux States
import {
  purchaseCountSuccessful,
  saleCountSuccessful,
  repairCountSuccessful,
  stockTransferCountSuccessful,
  enquiryCountSuccessful,
  QuotationCountSuccessful,
  targetsCountSuccessful,
  purchaseAnalyticSuccessful,
  saleAnalyticSuccessful,
  totalStockTransferCountSuccessful,
  visitCountSuccessful,
  apiError,
  loadingStatus,
  financeAmountSuccessful
} from "./dashboardSlice";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
import moment from "moment";
// AUTH related methods
import {
  getList,
  postAdd,
  postDownloadFile,
  getParamsList,
} from "../../helpers/Helper";

function* PurchaseCount({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        // (data[k] !== "" && k !== null && k !== "no_of_pages")
        if (data[k] !== "" && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "month") {
            params[k] = moment(data[k]).format("MM-YYYY");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/purchase/purchases_count",
      params
    );
    yield put(purchaseCountSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* SaleCount({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "month") {
            params[k] = moment(data[k]).format("MM-YYYY");
          }
          else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/sale/salestypes_count",
      params
    );
    yield put(saleCountSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* RepairCount({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else if (k == "month") {
            params[k] = moment(data[k]).format("MM-YYYY");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfer/repairamount_count/",
      params
    );
    yield put(repairCountSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* StockTransferCount({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else if (k == "month") {
            params[k] = moment(data[k]).format("MM-YYYY");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfer/stockTransfer_Count/",
      params
    );
    yield put(stockTransferCountSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* EnquiryCount({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "month") {
            params[k] = moment(data[k]).format("MM-YYYY");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/purchase/enquiry_count",
      params
    );
    yield put(enquiryCountSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* QuotationCount({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "month") {
            params[k] = moment(data[k]).format("MM-YYYY");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/sale/salesquotations_count",
      params
    );
    yield put(QuotationCountSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* TargetsCount({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "month") {
            params[k] = moment(data[k]).format("MM-YYYY");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/employeetarget_count/",
      params
    );
    yield put(targetsCountSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* PurchaseAnalytics({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "month") {
            params[k] = moment(data[k]).format("MM-YYYY");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/purchase/purchaseanalytics_count",
      params
    );
    yield put(purchaseAnalyticSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* SaleAnalytics({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "month") {
            params[k] = moment(data[k]).format("MM-YYYY");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/sale/saleanalytics_count",
      params
    );
    yield put(saleAnalyticSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* TotalStockTransferCount({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else if (k == "month") {
            params[k] = moment(data[k]).format("MM-YYYY");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfer/stockcard_Count/",
      params
    );
    yield put(totalStockTransferCountSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* VisitCount({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else if (k == "month") {
            params[k] = moment(data[k]).format("MM-YYYY");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/sale/visitentry/count/",
      params
    );
    yield put(visitCountSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* FinanceAmount({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          if (k === "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k === "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else if (k == "month") {
            params[k] = moment(data[k]).format("MM-YYYY");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/sale/finance_amount",
      params
    );
    yield put(financeAmountSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetPurchaseCount() {
  yield takeEvery("dashboard/getPurchaseCount", PurchaseCount);
}
export function* watchGetSaleCount() {
  yield takeEvery("dashboard/getSaleCount", SaleCount);
}
export function* watchGetRepairCount() {
  yield takeEvery("dashboard/getRepairCount", RepairCount);
}
export function* watchGetStockTransferCount() {
  yield takeEvery("dashboard/getStockTransferCount", StockTransferCount);
}
export function* watchGetEnquiryCount() {
  yield takeEvery("dashboard/getEnquiryCount", EnquiryCount);
}
export function* watchGetQuotationCount() {
  yield takeEvery("dashboard/getQuotationCount", QuotationCount);
}
export function* watchGetTargetsCount() {
  yield takeEvery("dashboard/getTargetsCount", TargetsCount);
}
export function* watchGetPurchaseAnalytics() {
  yield takeEvery("dashboard/getPurchaseAnalytics", PurchaseAnalytics);
}
export function* watchGetSaleAnalytics() {
  yield takeEvery("dashboard/getSaleAnalytics", SaleAnalytics);
}
export function* watchGetTotalStockTransferCount() {
  yield takeEvery("dashboard/getTotalStockTransferCount", TotalStockTransferCount);
}
export function* watchGetVisitCount() {
  yield takeEvery("dashboard/getVisitCount", VisitCount);
}
export function* watchGetFinanceAmount() {
  yield takeEvery("dashboard/getFinanceAmount", FinanceAmount);
}
function* dashboardSaga() {
  yield all([
    fork(watchGetPurchaseCount),
    fork(watchGetSaleCount),
    fork(watchGetRepairCount),
    fork(watchGetStockTransferCount),
    fork(watchGetEnquiryCount),
    fork(watchGetQuotationCount),
    fork(watchGetTargetsCount),
    fork(watchGetPurchaseAnalytics),
    fork(watchGetSaleAnalytics),
    fork(watchGetTotalStockTransferCount),
    fork(watchGetVisitCount),
    fork(watchGetFinanceAmount),
  ]);
}

export default dashboardSaga;
