import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  getQRList,
  QRListSuccessful,
  isModelVisible,
  getQRCodeParams,
  getQRCodes
} from "./qrSlice";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
// Auth Related Methods
import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList,
  postFormData,
} from "../../../helpers/Helper";
import QRCode from 'qrcode';
import jsPDF from 'jspdf';
import React from 'react';
import { array } from "prop-types";



function* QRList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/attendance/requestqrcode/listcreate", params);
    yield put(QRListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddQR({ payload }) {
  try {
    const response = yield call(postAdd, "/attendance/requestqrcode/listcreate", payload);
    if (response.status === 201) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your QR Value Added Successfully.!
            </p>
          </div>
        ),
      });
      yield put(getQRList({ page: 1, search: "", page_size: 10 }));
      yield put(isModelVisible(false));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to added QR Value. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}




function* QRCodesList({ payload }) {
  var params = {
    no_of_pages: 0,
    page_size: 100,
    page: 1,
  }
  try {
    const response = yield call(getParamsList, "/attendance/qrcodelist/", { requestqrcode: payload.requestqrcode, ...params }, {});
    if (response) {
      var res = [];
      res.push(response.results)
      if (res.length < response.count) {
        params.page = params.page + 1;
        // const response = yield call(getParamsList, "/attendance/qrcode/" ,  {requestqrcode : payload.requestqrcode, ...params}, {});
        yield put(getQRCodeParams({ ...params, requestqrcode: payload.requestqrcode }));
      }
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your QR Code Generated Successfully.!
            </p>
          </div>
        ),
        okButtonProps: { className: "print no-print" },
        okCancel: true,
        okText: <span className="print no-print hp-text-center">Download PDF</span>,
        onOk: () => {
          generatePDF(response.results)
        },

      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to generate QR Code. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    yield put(apiError(error));
  }
}


function generatePDF(data) {
  console.log("data", data)
  const generateQRCodePromises = data.map((text) => {
    return new Promise((resolve) => {
      // Create a QR code for each string
      const qrCodeDataUrl = QRCode.toDataURL(text.qrvalue);
      resolve(qrCodeDataUrl);
    });
  });
  Promise.all(generateQRCodePromises)
    .then((qrCodeDataUrls) => {
      // Initialize jsPDF
      const doc = new jsPDF();
      let yPos = 10;

      // Add QR code images to the PDF
      qrCodeDataUrls.forEach((qrCodeDataUrl, index) => {
        console.log("qrCodeDataUrl------", qrCodeDataUrl, index)
        if (index > 0) {
          doc.addPage();
        }
        doc.addImage(qrCodeDataUrl, 'PNG', 0, 0, 210, 210);
        // yPos += 100;
      });

      // Save or display the PDF as needed
      doc.save('qrcode_' + data[0].requestqrcode + '.pdf');
    })
    .catch((error) => {
      console.error('Error generating QR codes:', error);
    });
}





function* watchGetQRList() {
  yield takeEvery("qr/getQRList", QRList);
}
export function* watchGetQRCodes() {
  yield takeEvery("qr/getQRCodes", QRCodesList);
}

export function* watchQRAdd() {
  yield takeEvery("qr/qrAdd", AddQR);
}
export function* watchGetGeneratePDF() {
  yield takeEvery("qr/getGeneratePDF", generatePDF)
}
function* qrSaga() {
  yield all([
    fork(watchGetQRList),
    fork(watchGetQRCodes),
    fork(watchQRAdd),
    fork(watchGetGeneratePDF),
  ]);
}

export default qrSaga;
