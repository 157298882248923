import { createSlice } from "@reduxjs/toolkit";

export const zonesSlice = createSlice({
  name: "zones",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    zoneslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    zoneParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getZones: (state, action) => {
      if (state.zoneParams?.page == 1) {
        return {
          ...state,
          loading: true,
          zoneslist: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    zonesListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.count / state.zoneParams.page_size
      );
      console.log(action.payload.response.results);
      return {
        ...state,
        listCount: action.payload.response.count,
        zoneslist: action.payload.response.results,
        loading: false,
        zoneParams: {
          ...state.zoneParams,
          no_of_pages: noofpages,
        },
      };
    },
    zoneAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    zonesEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: {
          id: action.payload.id,
          name: action.payload.name,
          state_id: action.payload.state?.id,
          firm_id: action.payload.firm?.id,
        },
      };
    },
    zoneDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetZoneParams: (state, action) => {
      return {
        ...state,
        zoneParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getZones,
  zonesListSuccessful,
  zoneAdd,
  zonesEdit,
  zoneDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetZoneParams,
} = zonesSlice.actions;

export default zonesSlice.reducer;
