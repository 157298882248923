import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Dropdown, Card, Typography } from "antd";
import { Link } from "react-router-dom";
import {
  getQuickMenuItems,
  quickMenuActive,
} from "../../../store/system/systemSlice";
import * as Icons from "@ant-design/icons";
import { UnorderedListOutlined } from "@ant-design/icons";

function HeaderMenu(props) {
  const { Text } = Typography;

  useEffect(() => {
    props.getQuickMenuItems();
  }, []);

  const headerMenu = (
    <div
      className="hp-quick-menu hp-border-radius hp-border-1 
      hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-p-10 hp-mt-24"
      style={{ maxWidth: 320 }}>
      <Row gutter={[12, 12]}>
        {props.quickMenuItemsList?.menuitems &&
          props.quickMenuItemsList?.menuitems.map((quick, index) => {
            let Icon = Icons[quick.icon];
            return (
              <Col key={index}>
                <Card
                  hoverable
                  className="hp-border-color-black-40 hp-text-center"
                  style={{ width: 90, height: "100%" }}>
                  <Row gutter={[12, 12]} justify="center">
                    <Link
                      to={quick.link}
                      onClick={() => {
                        props.quickMenuActive(false);
                      }}>
                      <Col span={24}>
                        <Icon className="hp-text-color-primary-1 hp-avatar" />
                      </Col>

                      <Col span={24}>
                        <Text className="hp-d-block">{quick.title}</Text>
                      </Col>
                    </Link>
                  </Row>
                </Card>
              </Col>
            );
          })}
      </Row>
    </div>
  );

  return (
    <Col className="hp-d-flex-center hp-mr-sm-12 hp-mr-16">
      <Button
        type="text"
        icon={
          <Dropdown
            dropdownRender={() => headerMenu}
            placement="bottomRight"
            onOpenChange={(value) => props.quickMenuActive(value)}
            open={props.quickMenuactive}
            trigger={["click"]}>
            <div className="hp-position-relative">
              <UnorderedListOutlined
                className="hp-text-color-black-60 hp-text-color-white"
                style={{ fontSize: "20px" }}
              />
            </div>
          </Dropdown>
        }
      />
    </Col>
  );
}

function mapStateToProps({ system }) {
  return {
    quickMenuItemsList: system.quickMenuItemsList,
    quickMenuactive: system.quickMenuactive,
  };
}

export default connect(mapStateToProps, {
  getQuickMenuItems,
  quickMenuActive,
})(HeaderMenu);
